import { Image } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Assets from "../../../../assets/Assets";
import Style from "./searchCard.module.scss";

const SearchCard = ({ data, lastElement, handleProfileRedirect }) => {
  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  
  return (
    <a
      className={`pro-d-flex ${Style.root}`}
      ref={lastElement || null}
      // href={
      //   data?.module === "podcast"
      //     ? `/media?title=${data?.title.replace(/\s+/g, "_")}`
      //     : `/media/${data?.module}?title=${data?.title.replace(/\s+/g, "_")}`
      // }
      href={
        data?.module === "podcast"
          ? `/media?title=${data?.title}`
          : `/media/${data?.module}?title=${data?.title}`
      }
    >
      <div className={Style.img_wrap}>
        <Image
          src={data?.list_thumbnail}
          width={60}
          height={60}
          alt={`img- ${data?.title ?? ""}`}
          propStyle={{ root: Style.img }}
        />
      </div>
      <div className={Style.content}>
        <h6 className="pro-mb-1">Title : {`${data?.title ?? ""}`}</h6>
        <h6 className="pro-mb-0">Module : {`${data?.module ? capitalizeFirstLetter(data.module) : ""}`}</h6>
        {/* <ul className={`pro-d-flex ${Style.id_wrap}`}>
          <li>
            <span className="material-symbols-outlined">account_box</span>
            <span>{data?.profile_id ?? ""}</span>
          </li>
          <li>
            <span className="material-symbols-outlined">call</span>
            <span>{data?.phone_number ?? ""}</span>
          </li>
        </ul> */}
      </div>
    </a>
  );
};

export default SearchCard;
