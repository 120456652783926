import { Image } from "@wac-ui-dashboard/wac_component_library";
import React, { forwardRef } from "react";
import { Offcanvas } from "react-bootstrap";
import Assets from "../../../../assets/Assets";
import Style from "../topPrograms.module.scss";

export const OffCanvasLayout = forwardRef(
  ({ show, className, handleClose, title, body, children, showContent, ...props }, ref) => {
    return (
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        className={`pro-rounded-start ${className} dashboard-offcanavas`}
        {...props}
      >
        <Offcanvas.Header closeButton >
          <Offcanvas.Title as="h5">
            
              <div className={`pro-d-flex pro-items-center pro-gap-2 ${Style.thumbnail_wrap}`}>
                <div className={Style.thumbnail_img}>
                  <Image
                    src={
                      showContent?.thumbnail !== "" && showContent?.thumbnail
                        ? showContent?.thumbnail
                        : Assets.GENDER_IMAGE
                    }
                    width={40}
                    height={40}
                    alt={showContent?.name}
                  />
                </div>
                <div className={`pro-d-flex pro-flex-column pro-justify-center  ${Style.thumbnail_title}`}>
                  <strong>{title}</strong>
                  <span>{showContent?.type}</span>
                </div>
              </div>
            </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body ref={ref}>{children || body}</Offcanvas.Body>
      </Offcanvas>
    );
  }
);
