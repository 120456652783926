import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import MediaNewsFilterForm from "./MediaNewsFilterForm";
import {
  resetExtraFilters,
  setStoreFormValues,
} from "../../../store/slices/MediaNews/medianewsSlice";
import { useCategoryDataListQuery } from "../../../store/queries/medianews";

const useFilter = ({setShowform,refetch}) => {
  const dispatch = useDispatch();
  const { data: basicData = {} } = useCategoryDataListQuery();
  const state = useSelector((state) => state.medianews);

  const validationSchema = Yup.object({
    status: Yup.string().nullable(),
    category_id: Yup.array().nullable(),
    from_date: Yup.date().nullable(),
    to_date: Yup.date().nullable(),
  });

  const initialValues = {
    status: state.status,
    category_id: state.category_id,
    from_date: state.from_date,
    to_date: state.to_date,
  };
 
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const { status, category_id, from_date, to_date } = values;
      if (
        status === "" &&
        category_id?.length === 0 &&
        from_date === "" &&
        to_date === ""
      ) {
        toast.error("All fields are empty!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        setShowform(false);
      }
      let data = {
        status,
        category_id,
        from_date,
        to_date,
      };
      if (values) {
        dispatch(setStoreFormValues(values))
      }
    },
  });

  useEffect(() => {
    // Check if status is not empty before refetching
    if (formik.values.status !== '') {
      refetch();
    }
  }, [formik.values.status, refetch]);

  const renderTabContent = () => {
    return (
      <MediaNewsFilterForm
        formik={formik}
        handleReset={handleReset}
        categoryList={basicData?.data?.category}
      />
    );
  };

  const handleReset = () => {
    dispatch(resetExtraFilters());
    formik.resetForm();
    setShowform(false);
  };

  return {
    renderTabContent,
  };
};

export default useFilter;
