import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 10,
  from_date: "",
  to_date: "",
  sortBy: "",
  sortOrder: "",
  search: "",
  showCreateModal: false,
  activeId: null,
  currentFormData: [null],
  activeTab: "Basic",
  primary_radio_file: "", 
  completedTabs: {
    Basic: false,
  },
  CompletedPercentage: {
    Basic: 0,
  },
  isEditData: false, 
  status : "",
  clearSelection: false,
  selectedUpdateRows : [null],
};

const topProgramsSlice = createSlice({
  name: "topPrograms",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    setStoreFormValues: (state, action) => {
      state.status= action.payload.status;
      state.from_date= action.payload.from_date;
      state.to_date= action.payload.to_date;
    },
    resetExtraFilters: (state, action) => {
      state.status= '';
      state.from_date= '';
      state.to_date= '';
    },
  },
});

export const { updateConfig, setStoreFormValues,resetExtraFilters } = topProgramsSlice.actions;

export default topProgramsSlice.reducer;
