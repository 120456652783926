import Charts from "../../Global/Charts";
import Style from "../DashboardList/dashboardListing.module.scss";
import useBarChart from "./useBarChart";
import { ProgressBar } from "react-bootstrap";

const BarChart = ({ dashboardData }) => {
  const {
    performanceOptions,
    mainData,
    isSelectedCountry,
    setIsSelectedCountry,
    isSelectedState,
    setIsSelectedState,
    isSelectedCity,
    setIsSelectedCity,
  } = useBarChart({ dashboardData });

  return (
    <div>
      <div className="pro-d-flex pro-flex-wrap pro-gap-2 pro-justify-between">
        <h6 className="pro-ttl h6">Location</h6>
        <ul className="pro-d-flex pro-gap-5 pro-mb-3">
          <li
            className={`${Style.tab_btn} ${isSelectedCountry && Style.active}`}
            onClick={() => {
              setIsSelectedCountry(true);
              setIsSelectedState(false);
              setIsSelectedCity(false);
            }}
          >
            Country
          </li>
          <li
            className={`${Style.tab_btn} ${isSelectedState && Style.active}`}
            onClick={() => {
              setIsSelectedState(true);
              setIsSelectedCountry(false);
              setIsSelectedCity(false);
            }}
          >
            State
          </li>
          <li
            className={`${Style.tab_btn} ${isSelectedCity && Style.active}`}
            onClick={() => {
              setIsSelectedCity(true);
              setIsSelectedState(false);
              setIsSelectedCountry(false);
            }}
          >
            City
          </li>
        </ul>
      </div>

      {isSelectedCountry && (
        <>
          {/* <Charts
            type="bar"
            labels={mainData?.labels}
            datasets={mainData?.datasets}
            options={performanceOptions}
          /> */}
          <div className={Style.status_wrap}>
            {dashboardData?.data?.topCountries?.map((item) => (
              <div key={item?.label} className={Style.progress_bar}>
                <div className={Style.status_info}>
                  <span className={Style.title}>{item?.label}</span>
                  <span className={Style.percentage}>{item?.value}%</span>
                </div>
                <ProgressBar now={item?.value} />
              </div>
            ))}
          </div>
        </>
      )}
      {isSelectedState && (
        // <Charts
        //   type="bar"
        //   labels={mainData?.labels}
        //   datasets={mainData?.datasets}
        //   options={performanceOptions}
        // />
        <>
          <div className={Style.status_wrap}>
            {dashboardData?.data?.topStates?.map((item) => (
              <div key={item?.label} className={Style.progress_bar}>
                <div className={Style.status_info}>
                  <span className={Style.title}>{item?.label}</span>
                  <span className={Style.percentage}>{item?.value}%</span>
                </div>
                <ProgressBar now={item?.value} />
              </div>
            ))}
          </div>
        </>
      )}
      {isSelectedCity && (
        // <Charts
        //   type="bar"
        //   labels={mainData?.labels}
        //   datasets={mainData?.datasets}
        //   options={performanceOptions}
        // />

        <>
          <div className={Style.status_wrap}>
            {dashboardData?.data?.topCities?.map((item) => (
              <div key={item?.label} className={Style.progress_bar}>
                <div className={Style.status_info}>
                  <span className={Style.title}>{item?.label}</span>
                  <span className={Style.percentage}>{item?.value}%</span>
                </div>
                <ProgressBar now={item?.value} />
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default BarChart;
