import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useDeletePodcastDataMutation,
  useGetPodcastListDataQuery,
  useStatusChangeMutation,
} from "../../../store/queries/podcast";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateConfig } from "../../../store/slices/Podcast/podcastSlice";
import { toast } from "react-toastify";
import { IconText } from "@wac-ui-dashboard/wac_component_library";
import { current } from "@reduxjs/toolkit";
import { podcast as podcastQuery } from "../../../store/queries/podcast";

const usePodcastList = () => {
  const dispatch = useDispatch();
  const podcastState = useSelector((state) => state.podcast);
  const {
    showCreateModal,
    isEditData,
    activeId,
    selectedUpdateRows,
    audioData,
    videoData,
    typeData,
    dataTitle,
    dataId,
    imageData,
    titleImageData,
    verticalImageData,
    basicThumbnailImageData,
  } = useSelector((state) => state.podcast);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const [podcastDelete] = useDeletePodcastDataMutation();
  const [statusChange] = useStatusChangeMutation();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAudioModal, setShowAudioModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [filterShow, setFilterShow] = useState(false);
  const [dataFileIndex, setDataFileIndex] = useState("");
  const [showImageModal, setShowImageModal] = useState(false);
  const [showTitleImageModal, setShowTitleImageModal] = useState(false);
  const [showBasicThumbnailImageModal, setShowBasicThumbnailImageModal] =
    useState(false);
  const [loadingViewPermission, setLoadingViewPermission] = useState(true);
  const [showVerticalImageModal, setShowVerticalImageModal] = useState(false);

  const [dots, setDots] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((dots) => {
        const newDotsCount = (dots.length + 1) % 4; // Adjust number of dots here
        return ".".repeat(newDotsCount);
      });
    }, 2000); // Adjust interval duration (milliseconds) here

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const title = searchParams.get("title");
    if (title) {
      const searchTitle = title.replace(/%/g, " ");
      dispatch(
        updateConfig((state) => {
          state.search = searchTitle;
        })
      );
      handleSearch(searchTitle);
    } else {
      dispatch(
        updateConfig((state) => {
          state.search = "";
        })
      );
    }
  }, []);

  let paginationOptions = [
    { label: "10 per page", value: 10 },
    { label: "20 per page", value: 20 },
    { label: "30 per page", value: 30 },
  ];

  const {
    data: podcast = {},
    isFetching,
    isLoading,
    isSuccess,
    refetch,
  } = useGetPodcastListDataQuery({
    length: podcastState.currentPageSize,
    page: podcastState.currentPage,
    search: podcastState.search,
    sort_by: podcastState.sortBy,
    sort_order: podcastState.sortOrder,
    status: podcastState.status,
    category_id: podcastState.category_id,
    rj_id: podcastState.rj_id,
    type: podcastState.type,
    programe_id: podcastState.programe_id,
    from_date: podcastState.from_date,
    to_date: podcastState.to_date,
  });

  // const { data: formData, isSuccess: isUpdateDataSuccess } =
  //   useGetPodcastListDataQuery({ skip: isEditData === false });

  const hasCreatePermission = useMemo(() => {
    let permission = podcast?.data?.original?.permissions?.create_permission;
    return permission ?? false;
  }, [podcast]);

  const hasEditPermission = useMemo(() => {
    let permission = podcast?.data?.original?.permissions?.edit_permission;
    return permission ?? false;
  }, [podcast]);
  const hasViewPermission = useMemo(() => {
    let permission = podcast?.data?.original?.permissions?.list_permission;
    return permission ?? false;
  }, [podcast]);
  const hasDeletePermission = useMemo(() => {
    let permission = podcast?.data?.original?.permissions?.delete_permission;
    return permission ?? false;
  }, [podcast]);

  const tableHeading = {
    title: { label: "Title", sortable: true },
    description: { label: "Description", sortable: true },
    program_name: { label: "Program", sortable: true },
    type: { label: "Type", sortable: true },
    episode_number: {label: "Episode", sortable: true},
    category: { label: "Category", sortable: true },
    rj: { label: "Rj", sortable: true },
    title_image: { label: "Title Image", sortable: true },
    banner_image: { label: "Banner Image", sortable: true },
    basic_thumbnail: { label: "Basic Thumbnail", sortable: true },
    vertical_thumbnail: { label: "Vertical Thumbnail", sortable: true },
    audio_response: { label: "Audio", sortable: true },
    video_response: { label: "Video", sortable: true },
    duration: { label: "Duration", sortable: true },
    created_date: { label: "Created Date", sortable: true },
    updated_date: { label: "Updated Date", sortable: true },
    status_label: { label: "Status", sortable: true },
  };

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = tableHeading;
      })
    );
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   refetch();
  // }, [
  //   podcastState.status,
  //   // podcastState.category_id,
  //   // podcastState.rj_id,
  //   // podcastState.type,
  //   // podcastState.programe_id,
  //   // podcastState.from_date,
  //   // podcastState.to_date,
  // ]);

  useEffect(() => {
    // Simulate loading delay with setTimeout
    setTimeout(() => {
      setLoadingViewPermission(false);
    }, 1000); // Adjust the delay time as needed
  }, [podcast]);

  const deletePodcastDataClick = (item) => {
    setShowDeleteModal(true);
    dispatch(
      updateConfig((state) => {
        state.selectedUpdateRows = item;
      })
    );
  };

  useEffect(() => {
    if (podcastState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [podcastState.clearSelection]);

  const deletePodcast = () => {
    podcastDelete({
      _id: selectedUpdateRows,
    }).then((response) => {
      if (response?.data?.status_code === 200) {
        refetch();
        dispatch(
          updateConfig((state) => {
            state.clearSelection = true;
          })
        );
        setShowDeleteModal(false);
        toast.success("Successfully Deleted");
      } else if (response?.error?.status === 422) {
        toast.success("Something went wrong");
      }
    });
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  // useEffect(() => {
  //   if (isEditData && isUpdateDataSuccess) {
  //     dispatch(
  //       updateConfig((state) => {
  //         state.currentFormData = {
  //           ...formData?.data?.data,
  //         };
  //       })
  //     );
  //   }
  //   // eslint-disable-next-line
  // }, [isEditData, isUpdateDataSuccess, formData]);

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentFilter = null;
        state.currentPageSize = 10;
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
        state.showCreateModal = false;
      })
    );
  };

  const handleSort = (label) => {
    if (podcastState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = podcastState.sortOrder === "0" ? "1" : "0";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "0";
        })
      );
    }
  };

  // const handleSearch = (e) => {
  //   dispatch(
  //     updateConfig((state) => {
  //       state.search = e.target.value;
  //       state.currentPage = 1;
  //     })
  //   );
  // };
  const handleSearch = (e) => {
    if (typeof e === "string") {
      // If 'e' is a string (title from URL), update search directly
      dispatch(
        updateConfig((state) => {
          state.search = e;
          state.currentPage = 1;
        })
      );
    } else {
      // If 'e' is an event (input change), update search from input value
      dispatch(
        updateConfig((state) => {
          state.search = e.target.value;
          state.currentPage = 1;
        })
      );
    }
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.activeTab = "Details";
        state.activeId = "";
      })
    );
    if (isEditData) {
      dispatch(
        updateConfig((state) => {
          state.currentFormData = {};
          state.isEditData = false;
        })
      );
    }
  };
  const handleEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const handlePodcastDataEditClick = (id) => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.activeId = id[0];
      })
    );
    dispatch(
      updateConfig((state) => {
        state.currentFormData = podcast?.data?.original?.data?.id;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.isEditData = true;
        state.activeId = id?.[0];
      })
    );
    dispatch(
      updateConfig((state) => {
        state.activeTab = "Details";
      })
    );
  };

  const handleViewAudio = (data, title, id, type) => {
    let dataList = podcast?.data?.original?.data;
    let index = dataList.findIndex((item, index) => item?._id === id);
    setDataFileIndex(index);
    setShowAudioModal(() => true);
    dispatch(
      updateConfig((state) => {
        state.audioData = data;
        state.typeData = type;
        state.dataTitle = title;
        state.dataId = id;
      })
    );
  };

  const handleViewVideo = (data, title, id) => {
    let dataList = podcast?.data?.original?.data;
    let index = dataList.findIndex((item, index) => item?._id === id);
    setDataFileIndex(index);
    setShowVideoModal(() => true);
    dispatch(
      updateConfig((state) => {
        state.videoData = data;
        state.dataTitle = title;
        state.dataId = id;
      })
    );
  };

  const handleViewImage = (data) => {
    setShowImageModal(() => true);
    dispatch(
      updateConfig((state) => {
        state.imageData = data;
      })
    );
  };

  const handleViewTitleImage = (data) => {
    setShowTitleImageModal(() => true);
    dispatch(
      updateConfig((state) => {
        state.titleImageData = data;
      })
    );
  };

  const handleVerticalImage = (data) => {
    setShowVerticalImageModal(() => true);
    dispatch(
      updateConfig((state) => {
        state.verticalImageData = data;
      })
    );
  };

  const handleViewBasicThumbnailImage = (data) => {
    setShowBasicThumbnailImageModal(() => true);
    dispatch(
      updateConfig((state) => {
        state.basicThumbnailImageData = data;
      })
    );
  };

  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
    if (isEditData) {
      dispatch(
        updateConfig((state) => {
          state.currentFormData = {};
          state.isEditData = false;
        })
      );
    }
    setShowAudioModal(() => false);
    dispatch(
      updateConfig((state) => {
        state.audioData = "";
        state.videoData = "";
        state.imageData = "";
        state.titleImageData = "";
        state.verticalImageData = "";
        state.basicThumbnailImageData = "";
      })
    );
    setShowVideoModal(() => false);
    setShowImageModal(() => false);
    setShowTitleImageModal(() => false);
    setShowVerticalImageModal(() => false);
    setShowBasicThumbnailImageModal(() => false);
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };

  const handleToggle = (e, data) => {
    let params = {
      _id: data?._id,
    };
    statusChange(params).then((response) => {
      if (response?.data?.status_code == 400) {
        toast.error(response?.data?.message);
      }
    });
    dispatch(
      podcastQuery.util.updateQueryData(
        "getPodcastListData",
        {
          length: podcastState.currentPageSize,
          page: podcastState.currentPage,
          search: podcastState.search,
          sort_by: podcastState.sortBy,
          sort_order: podcastState.sortOrder,
          status: podcastState.status,
          category_id: podcastState.category_id,
          rj_id: podcastState.rj_id,
          type: podcastState.type,
          programe_id: podcastState.programe_id,
          from_date: podcastState.from_date,
          to_date: podcastState.to_date,
        },
        (cachedData) => {
          let updatedCachedData = { ...current(cachedData) };
          let newResult = updatedCachedData?.data?.original?.data?.map((res) =>
            res?._id === data?._id
              ? {
                  ...res,
                  status: data?.status === 0 ? 1 : 0,
                }
              : res
          );
          updatedCachedData = {
            ...updatedCachedData,
            data: {
              ...updatedCachedData.data,
              original: {
                ...updatedCachedData.data.original,
                data: newResult,
              },
            },
          };
          return updatedCachedData;
        }
      )
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => (
        <p className="pro-mb-0">{data[feild] ? `${data[feild]}` : "-"}</p>
      ),
      audio_response: (feild, data) =>
        data?.[feild] ? (
          <div className="pro-nowrap">
            <IconText
            icon={<span className="material-symbols-outlined">audio_file</span>}
            title={`${"Play Audio"}`}
            onClick={() =>
              handleViewAudio(data?.[feild], data?.title, data?._id, "audio")
            }
            />  
          </div>
        ) : (
          <>-</>
        ),
      video_response: (feild, data) =>
        data?.[feild] ? (
          <div className="pro-nowrap">
          <IconText
            icon={
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="11.8544" cy="12.0001" r="11.5372" fill="#FA6B07"/>
              <path d="M16.9648 11.1905C17.6315 11.5754 17.6315 12.5377 16.9648 12.9226L9.66422 17.1376C8.99755 17.5225 8.16421 17.0414 8.16421 16.2716L8.16421 7.84152C8.16422 7.07172 8.99755 6.59059 9.66422 6.97549L16.9648 11.1905Z" fill="white"/>
              </svg>
            }
            title={`${"Play Video"}`}
            onClick={() =>
              handleViewVideo(data?.[feild], data?.title, data?._id)
            }
          />
          </div>
        ) : (
          <>-</>
        ),
      title_image: (feild, data) =>
        data?.[feild] ? (
          <div className="pro-nowrap">
          <IconText
            icon={
              <span className="material-symbols-outlined x4">imagesmode</span>
            }
            title={`${"View Image"}`}
            onClick={() => handleViewTitleImage(data?.[feild])}
          />
          </div>
        ) : (
          <>-</>
        ),
      basic_thumbnail: (feild, data) =>
        data?.[feild] ? (
          <div className="pro-nowrap">
          <IconText
            icon={
              <span className="material-symbols-outlined x4">imagesmode</span>
            }
            title={`${"View Image"}`}
            onClick={() => handleViewBasicThumbnailImage(data?.[feild])}
          />
          </div>
        ) : (
          <>-</>
        ),
      banner_image: (feild, data) =>
        data?.[feild] ? (
          <div className="pro-nowrap">
          <IconText
            icon={
              <span className="material-symbols-outlined x4">imagesmode</span>
            }
            title={`${"View Image"}`}
            onClick={() => handleViewImage(data?.[feild])}
          />
          </div>
        ) : (
          <>-</>
        ),
        vertical_thumbnail: (feild, data) =>
          data?.[feild] ? (
            <div className="pro-nowrap">
            <IconText
              icon={
                <span className="material-symbols-outlined x4">imagesmode</span>
              }
              title={`${"View Image"}`}
              onClick={() => handleVerticalImage(data?.[feild])}
            />
            </div>
          ) : (
            <>-</>
          ),
      type: (feild, data) => (
        <p className="pro-mb-0 pro-nowrap">
          {data[feild]?.name ? `${data[feild]?.name}` : "-"}
        </p>
      ),
      rj: (feild, data) => (
        <p className="pro-mb-0 pro-nowrap">
          {data[feild]?.name ? `${data[feild]?.name}` : "-"}
        </p>
      ),
      title: (feild, data) => (
        <p className="pro-mb-0 pro-min-sm">
          {data[feild] ? `${data[feild]}` : "-"}
        </p>
      ),
      program_name: (feild, data) => (
        <p className="pro-mb-0 pro-min-xs">
          {data[feild] ? `${data[feild]}` : "-"}
        </p>
      ),
      description: (feild, data) => (
        <p className="pro-mb-0 pro-min-lg">
          {data[feild] ? `${data[feild]}` : "-"}
        </p>
      ),
      created_date: (feild, data) => (
        <p className="pro-mb-0">
          {data[feild] ? `${data[feild]}` : "-"}
        </p>
      ),
      updated_date: (feild, data) => (
        <p className="pro-mb-0">
          {data[feild] ? `${data[feild]}` : "-"}
        </p>
      ),
      status_label: (field, data) => {
        const isChecked = data?.status === 1;
        return (
          <>
            {hasEditPermission ? (
              <div className="form-check form-switch ">
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckDefault"
                  checked={isChecked}
                  onChange={(e) => handleToggle(e, data)}
                />
              </div>
            ) : (
              data?.status_label?.name
            )}
          </>
        );
      },
    };
    if (feild === "duration") {
      return data.is_converted === 1 ? (
        rows?.[feild]?.(feild, data) ?? rows["common"](feild, data)
      ) : (
        <div className="loader" title="Processing">
          <div className="loader-inner ball-pulse">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      );
    }
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  return {
    podcast,
    activeId,
    podcastState,
    currentPage: podcastState.currentPage,
    activeFilter: podcastState.currentFilter,
    showCreateModal,
    showEditModal,
    paginationOptions,
    isFetching,
    isLoading,
    isSuccess,
    totalRecords: podcastState.recordsTotal,
    showDeleteModal,
    audioData,
    videoData,
    showVideoModal,
    typeData,
    dataTitle,
    dataId,
    showAudioModal,
    // isUpdateDataSuccess,
    isEditData,
    filterShow,
    dataFileIndex,
    imageData,
    titleImageData,
    verticalImageData,
    basicThumbnailImageData,
    showImageModal,
    showTitleImageModal,
    showVerticalImageModal,
    showBasicThumbnailImageModal,
    setDataFileIndex,
    setFilterShow,
    handleClearClick,
    handleEditModal,
    handleCreateClick,
    setShowAudioModal,
    deletePodcastDataClick,
    closeDeleteModal,
    handlePagination,
    handlePageSize,
    handleSort,
    handleSearch,
    handlePodcastDataEditClick,
    closeEditModal,
    closeModal,
    getRow,
    deletePodcast,
    refetch,
    tableFields,
    hasCreatePermission,
    hasEditPermission,
    hasViewPermission,
    hasDeletePermission,
    loadingViewPermission,
  };
};

export default usePodcastList;
