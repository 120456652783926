import { useFormik } from "formik";
import { exportAllData } from "../../Dashboard/DashboardList/api";
import { useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import moment from "moment";

const useMyVerticallyCenteredModal = ({adCampaign,activeTab,onHide,from_date,to_date}) => {
  const [errors, setErrors] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  let validationSchema = Yup.object({
    from_date: Yup.string().required("*Required"),
    to_date: Yup.string().required("*Required"),
  });

  const formik = useFormik({
    initialValues: {
      from_date: from_date,
      to_date: to_date,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      setIsLoading(true);
      try {
        let obj = {
          from_date: values.from_date,
          to_date: values.to_date,
          ad_campaign_id: adCampaign,
          type: activeTab,
        };
        // let formData = new FormData();

        // Object.keys(obj).forEach((key) => {
        //   return formData.append(key, obj[key]);
        // });

        exportAllData(obj).then((response) => {
          formik.setErrors("");
          setErrors("");
          if (response?.status === 200) {
            setIsLoading(false);
            formik.resetForm();
            toast.success("Success");
            onHide();
            // let dataType = response?.data?.type;
            // let binaryData = [];
            // binaryData.push(response?.data);
            // let downloadLink = document.createElement("a");
            // downloadLink.href = window.URL.createObjectURL(
            //   new Blob(binaryData, { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
            // );
            // downloadLink.setAttribute(
            //   "download",
            //   `${moment(Date.now()).format("MM_DD_YYYY_")}${`excel`}.xlsx`
            // );
            // document.body.appendChild(downloadLink);
            // downloadLink.click();

            let downloadLink = document.createElement("a");
            let blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

            downloadLink.href = window.URL.createObjectURL(blob);
            downloadLink.setAttribute(
              "download",
              `${moment(Date.now()).format("MM_DD_YYYY_")}${`excel`}.xlsx`
            );

            document.body.appendChild(downloadLink);
            downloadLink.click();
            
          } else if (response?.data?.error_code === 403) {
            setIsLoading(false);
            formik.setErrors(response?.data?.errors);
          } else if (response?.status_code === 500) {
            setIsLoading(false);
            toast.error("Something Went Wrong");
          } else {
            setIsLoading(false);
          }
        });
      } catch (error) {
        setErrors("Something went wrong");
      }
    },
  });
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  return {
    formik,
    errors,
    isLoading,
    getFieldError,
  };
};
export default useMyVerticallyCenteredModal;
