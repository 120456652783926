import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key] === "status") {
        return `${key}=${parseInt(params[key])}&`;
      } else if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };
    try {
      const response = await api[method](
        `${endpoint}?${getParams("_id")}${getParams("status")}${getParams(
          "type"
        )}${getParams("category_id")}${getParams("sort_by")}sort_order=${
          params?.sort_order || ""
        }&page_size=${params?.page_size || "10"}&${getParams("search")}&page=${
          params?.page || 1
        }&${getParams("length")}`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        data: err.response?.data || err.message,
      };
    }
  };

export const advertisement = createApi({
  reducerPath: "advertisementApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["Advertisement"],
  endpoints: (builder) => ({
    getAdvertisementListData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/advertisement/list`,
      }),
      provideTags: ["Advertisement"],
    }),
    getAdvertisementFormDataById: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/advertisement/edit`,
      }),
      // provideTags: ["Podcast"],
    }),
    updateAdvertisementListData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/advertisement/update`,
      }),

      invalidatesTags: ["Advertisement"],
    }),
    deleteAdvertisementData: builder.mutation({
      query: (values) => ({
        method: "delete",
        params: values,
        endpoint: `/admin/advertisement/delete`,
      }),

      invalidatesTags: ["Advertisement"],
    }),
    getFilterListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/filters/common-search-filters`,
      }),
    }),
    statusChange: builder.mutation({
      query: (values) => ({
        method: "post",
        params: values,
        endpoint: `/admin/advertisement/status-change`,
      }),
    }),
  }),
});

export const {
  useGetAdvertisementListDataQuery,
  useGetAdvertisementFormDataByIdQuery,
  useUpdateAdvertisementListDataMutation,
  useDeleteAdvertisementDataMutation,
  useGetFilterListDataQuery,
  useStatusChangeMutation,
} = advertisement;
