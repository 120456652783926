import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import CategoryFilterForm from "./CategoryFilterForm";
import {
  resetExtraFilters,
  setStoreFormValues,
} from "../../../store/slices/Category/categorySlice";

const useFilter = ({setShowform,refetch}) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.category);

  const validationSchema = Yup.object({
    status: Yup.string().nullable(),
  });

  const initialValues = {
    status: state.status,
  };

  const formik = useFormik({
    initialValues, 
    validationSchema,
    onSubmit: (values) => {
      if (values) {
        dispatch(setStoreFormValues(values));
      }
      const { status } = values;
      if (status === "") {
        toast.error("All fields are empty!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        setShowform(false);
      }
      let data = {
        status,
      };
      
    },
  });

  useEffect(() => {
    // Check if status is not empty before refetching
    if (formik.values.status !== '') {
      refetch();
    }
  }, [formik.values.status, refetch]);

  const renderTabContent = () => {
    return <CategoryFilterForm formik={formik} handleReset={handleReset} />;
  };

  const handleReset = () => {
    dispatch(resetExtraFilters());
    formik.resetForm();
    setShowform(false);
  };

  return {
    renderTabContent,
  };
};

export default useFilter;
