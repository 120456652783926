import {
  Input,
  Button,
  IconText,
  Image,
  FormTabs,
} from "@wac-ui-dashboard/wac_component_library";
import useUpdateMediaVideo from "./useUpdateMediaVideo";
import Select from "react-select";
import { limitStrLength } from "../../../utils/functions/table";
import Style from "./mediaVideoForm.module.scss";
import { clearConfig } from "dompurify";

const UpdateMediaVideo = ({ activeId, refetch, isEditData,  uploadState, setUploadState, showCreateModal }) => {
  const {
    tabs,
    activeTab,
    handleTabClick,
    handleMoveToErrorTab,
    renderTabContent,
    formik,
  } = useUpdateMediaVideo({
    activeId,
    refetch,
    isEditData,
    uploadState, 
    setUploadState,
    showCreateModal
  });
  
  return (
    <>
     <FormTabs
       propStyle={{ root: Style.formTabs_root }}
       tabs={tabs}
       active={activeTab}
       handleClick={handleTabClick}
     />
     <form onSubmit={(e) => {formik.handleSubmit(e); handleMoveToErrorTab();}}>{renderTabContent()}</form>
   </>
  );
};
export default UpdateMediaVideo;
