import {
  FormTabs
} from "@wac-ui-dashboard/wac_component_library";
import useUpdateMediaShorts from "./useUpdateMediaShorts";
import Style from "./mediaShortsForm.module.scss";

const UpdateMediaShorts = ({ activeId, refetch, isEditData }) => {
  const {
    tabs,
    activeTab,
    handleTabClick,
    handleMoveToErrorTab,
    renderTabContent,
    formik,
  } = useUpdateMediaShorts({
    activeId,
    refetch,
    isEditData,
  });

  return (
    <>
    <FormTabs
      propStyle={{ root: Style.formTabs_root }}
      tabs={tabs}
      active={activeTab}
      handleClick={handleTabClick}
    />
    <form onSubmit={(e) => {formik.handleSubmit(e); handleMoveToErrorTab();}}>{renderTabContent()}</form>
  </>
  );
};
export default UpdateMediaShorts;
