import { Input, Button } from "@wac-ui-dashboard/wac_component_library";
import Select from "react-select";
import useDetails from "./useDetails";

const Details = ({
  formik,
  getFieldError,
  isEditData,
  adType,
  adPlacementType,
  currentFormData,
  clientsList,
  campaignsList,
  setImagePreview,
            setImageCoverPreview,
            setBasicThumbnailImageCoverPreview
}) => {
  const { handleCloseModal, handleNext } = useDetails({
    formik,
  });

  return (
    <div className={`pro-w-100`}>
      <Input
        label={"Name*"}
        type="text"
        id="name"
        name="name"
        className={`pro-input lg ${getFieldError("name") && " error"}`}
        {...formik.getFieldProps("name")}
        error={getFieldError("name")}
        errorMessage={getFieldError("name")}
      />

      <Input
        label={"Title*"}
        type="text"
        id="title"
        name="title"
        className={`pro-input lg ${getFieldError("title") && " error"}`}
        {...formik.getFieldProps("title")}
        error={getFieldError("title")}
        errorMessage={getFieldError("title")}
      />

      {/* <Input
        label={"Sub Title"}
        type="text"
        id="sub_title"
        name="sub_title"
        className={`pro-input lg ${getFieldError("sub_title") && " error"}`}
        {...formik.getFieldProps("sub_title")}
        error={getFieldError("sub_title")}
        errorMessage={getFieldError("sub_title")}
      /> */}

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Description*
        </label>
        <textarea
          id="description"
          rows={4}
          name="description"
          className={`pro-input lg ${
            formik.touched.description && formik.errors.description && " error"
          }`}
          {...formik.getFieldProps("description")}
        ></textarea>
        {formik.touched.description && formik.errors.description && (
          <span className="error-text">{formik.errors.description}</span>
        )}
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Type*
        </label>
        <Select
          id="type"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg ${getFieldError("type") && " error"}`}
          classNamePrefix="pro-input"
          options={adType}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={adType?.filter((m) => formik.values.type?._id === m?._id)}
          onBlur={formik.handleBlur("type")}
          onChange={(value) => {
            formik.setFieldValue("type", value || "");
          }}
        />
        {getFieldError("type") && (
          <span className="error-text">{getFieldError("type")}</span>
        )}
      </div>
      {formik?.values?.type ? (
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Placement Type*
          </label>
          <Select
            id="ad_placement_type"
            isClearable={true}
            placeholder={"Select"}
            className={`pro-input lg ${
              getFieldError("ad_placement_type") && " error"
            }`}
            classNamePrefix="pro-input"
            options={adPlacementType}
            getOptionValue={(option) => option?._id}
            getOptionLabel={(option) => option?.name}
            value={adPlacementType?.filter(
              (m) => formik.values.ad_placement_type?._id === m?._id
            )}
            onBlur={formik.handleBlur("ad_placement_type")}
            onChange={(value) => {
              formik.setFieldValue("ad_placement_type", value || "");
              formik.setFieldValue("image", null);
              formik.setFieldValue("list_thumbnail", null);
              formik.setFieldValue("basic_thumbnail", null);
              setImagePreview("");
            setImageCoverPreview("");
            setBasicThumbnailImageCoverPreview("");
            }}
          />
          {/* {getFieldError("ad_placement_type") && (
            <span className="error-text">
              {getFieldError("ad_placement_type")}
            </span>
          )} */}
          {formik.touched?.ad_placement_type && formik.errors.ad_placement_type && (
            <span className="error-text">{formik.errors.ad_placement_type}</span>
          )}
        </div>
      ) : (
        ""
      )}

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Client*
        </label>
        <Select
          id="client"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg ${getFieldError("client") && " error"}`}
          classNamePrefix="pro-input"
          options={clientsList}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={clientsList?.filter(
            (m) => formik.values.client?._id === m?._id
          )}
          onBlur={formik.handleBlur("client")}
          onChange={(value) => {
            formik.setFieldValue("client", value || "");
          }}
        />
        {getFieldError("client") && (
          <span className="error-text">{getFieldError("client")}</span>
        )}
      </div>

      {formik?.values?.client ? (
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Campaign*
          </label>
          <Select
            id="ad_campaign"
            isClearable={true}
            placeholder={"Select"}
            className={`pro-input lg ${
              getFieldError("ad_campaign") && " error"
            }`}
            classNamePrefix="pro-input"
            options={campaignsList}
            getOptionValue={(option) => option?._id}
            getOptionLabel={(option) => option?.name}
            value={campaignsList?.filter(
              (m) => formik.values.ad_campaign?._id === m?._id
            )}
            onBlur={formik.handleBlur("ad_campaign")}
            onChange={(value) => {
              formik.setFieldValue("ad_campaign", value || "");
            }}
          />
          {getFieldError("ad_campaign") && (
            <span className="error-text">{getFieldError("ad_campaign")}</span>
          )}
        </div>
      ) : (
        ""
      )}
      <Input
        label={"Website Link*"}
        type="text"
        id="website_link"
        name="website_link"
        className={`pro-input lg ${getFieldError("website_link") && " error"}`}
        {...formik.getFieldProps("website_link")}
        error={getFieldError("website_link")}
        errorMessage={getFieldError("website_link")}
      />

      {isEditData && currentFormData?.is_converted === 1 ? (
        <div className="input-wrap pro-mb-4 pro-flex-row pro-items-center">
          <div className="form-check form-switch pro-mb-0">
            <input
              type="checkbox"
              className="form-check-input cursor-pointer"
              id="status"
              name="status"
              checked={Boolean(formik.values?.status) ?? false}
              onChange={(e) => {
                formik.setFieldValue(
                  "status",
                  !Boolean(formik.values?.status) ?? false
                );
              }}
            />
          </div>
          <label htmlFor="" className="pro-font-sm pro-lh-base pro-fw-medium">
            Status
          </label>
        </div>
      ) : (
        ""
      )}

      <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end">
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => handleCloseModal()}
          type="button"
        >
          Cancel
        </Button>

        <Button className={"pro-btn-primary lg pro-mx-3"} onClick={handleNext} disabled={formik?.values?.type !== "" ? false : true}>
          Next
        </Button>
      </div>
    </div>
  );
};
export default Details;
