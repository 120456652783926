import style from "./dashboardCard.module.scss";

const DashboardCard = ({ title, data, icon, active }) => {
  return (
    <div className={`${style.root} ${active ? `active` : ""}`}>
      <div className={`${style.inner}  pro-flex-column pro-w-100`}>
        <div className={`${style.body} pro-d-flex pro-justify-between pro-items-center pro-w-100  pro-flex-row`}>
          <div className="title-wrap">
            <h3 className="pro-ttl h3 pro-mb-2 pro-fw-medium">{data?.current}</h3>
            <h6 className="pro-ttl h6 pro-mb-0 pro-fw-medium">
              {title}
            </h6> 
          </div>
          <div className={`${style.icon}`}>
            <span className="material-symbols-outlined x4">{icon}</span>
          </div>
        </div>
        <div className={`pro-d-flex pro-items-center pro-pt-4 pro-mt-auto pro-gap-3`}>
            <span className={`${
                data?.growth && data?.growth.includes('-') ?
                style.decrement : style.increment 
              } ${
                style.growth_percentage
              } pro-align-self-center pro-font-xs pro-fw-medium pro-badge`}>
            <span className={`material-symbols-outlined ${style.arrow}`}>
                arrow_outward
              </span>
            <span>{data?.growth}</span>
            </span>
            <p className="pro-mb-0 pro-fw-medium">{`Previous : ${data?.previous}`}</p>
        </div>
        
      </div>
    </div>
  );
};

export default DashboardCard;
