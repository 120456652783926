import { useSelector } from "react-redux";
import {
  useDeleteRoleDataMutation,
  useGetRoleListDataQuery,
  useUpdateRoleTableHeadDataMutation,
} from "../../../store/queries/role";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateConfig } from "../../../store/slices/Role/roleSlice";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const useRoleList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [roleDelete] = useDeleteRoleDataMutation();
  const [updateRoleFields] = useUpdateRoleTableHeadDataMutation();
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const roleState = useSelector((state) => state.role);
  const { selectedUpdateRows } = useSelector((state) => state.role);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  let paginationOptions = [
    { label: "20 Per page", value: 20 },
    { label: "40 Per page", value: 40 },
    { label: "60 Per page", value: 60 },
  ];

  const {
    data: roleList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetRoleListDataQuery({
    page_size: roleState.currentPageSize,
    page: roleState.currentPage,
    sort_by: roleState.sortBy,
    sort_order: roleState.sortOrder,
    start: roleState.startDate,
    end: roleState.endDate,
    search: roleState.search,
  });

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasCreatePermission = useMemo(() => {
    let permission = roleList?.data?.original?.permissions?.create_permission;
    return permission ?? false;
  }, [roleList]);

  const hasEditPermission = useMemo(() => {
    let permission = roleList?.data?.original?.permissions?.edit_permission;
    return permission ?? false;
  }, [roleList]);
  const hasViewPermission = useMemo(() => {
    let permission = roleList?.data?.original?.permissions?.list_permission;
    return permission ?? false;
  }, [roleList]);
  const hasDeletePermission = useMemo(() => {
    let permission = roleList?.data?.original?.permissions?.delete_permission;
    return permission ?? false;
  }, [roleList]);

  const tableHeading = {
    name: { label: "Name", sortable: true },
  };

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = tableHeading;
      })
    );
    // eslint-disable-next-line
  }, []);
  const handleCreateClick = (item) => {
    if (item) {
      navigate({
        pathname: "update",
        search: `role=${item._id}`,
      });
    } else {
      navigate("update");
    }
    dispatch(
      updateConfig((state) => {
        state.isEdit = false;
      })
    );
  };

  const deleteRoleDataClick = (item) => {
    setShowDeleteModal(true);
    dispatch(
      updateConfig((state) => {
        state.selectedUpdateRows = item;
      })
    );
  };
 
  const deleteRole = () => {
    roleDelete({
      _id: selectedUpdateRows,
    }).then((response) => {
      if (response?.data?.status_code === 200) {
        refetch();
        dispatch(
          updateConfig((state) => {
            state.clearSelection = true;
          })
        );
        setShowDeleteModal(false);
        toast.success("Successfully Deleted");
      } else if (response?.error?.status === 422) {
        toast.success("Something went wrong");
      }
    });
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleEditAction = (e) => {
    navigate({
      pathname: "update",
      search: `role=${e?.[0]}&update=true`,
    });
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (roleState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = roleState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  // const handleApprovePermission = (id) => {
  //   let body = {
  //     role_id: id,
  //   };
  //   approveRequest(body).then((response) => {
  //     if (response?.data?.status_code === 200) {
  //       toast.success("Updated");
  //       refetch();
  //     } else {
  //       toast.error("Something went wrong");
  //     }
  //   });
  // };
  return {
    roleState,
    showEditModal,
    tableFields,
    roleList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleEditColumnsClick,
    handleSort,
    handlePagination,
    handleCreateClick,
    handleEditAction,
    handleSearch,
    updateRoleFields,
    handleClearClick,
    handlePageSize,
    refetch,
    deleteRoleDataClick,
    deleteRole,
    closeDeleteModal,
    showDeleteModal,
    hasCreatePermission,
    hasEditPermission,
    hasViewPermission,
    hasDeletePermission,
  };
};

export default useRoleList;
