import { useSelector } from "react-redux";
import { useMemo } from "react";

import Users from "../components/Users";
import Rj from "../components/Rj";
import Podcast from "../components/Podcast";
import MediaVideo from "../components/MediaVideo";
import MediaShorts from "../components/MediaShorts";
import MediaNews from "../components/MediaNews";
import Advertisement from "../components/Advertisement";
import Programme from "../components/Programme";
import Category from "../components/Category";
import NewsCategory from "../components/NewsCategory";
import Role from "../components/Role";
import Client from "../components/Client";
import Campaign from "../components/Campaign";
import SubAdmin from "../components/SubAdmin";
import Schedule from "../components/Schedule";
import Dashboard from "../components/Dashboard";
import PrivacyAndTerms from "../components/PrivacyAndTerms";
import BannerSettings from "../components/BannerSettings";
import HomeDashboard from "../components/HomeDashboard";

const useRoutes = () => {
  const globalState = useSelector((state) => state.global);

  const DashboardComponent = [
    {
      component: <HomeDashboard />,
      permission: "Dashboard",
      path: "home-dashboard?activeTab=app_analytics",
    },
  ];

  const UsersComponents = [
    {
      component: <Users />,
      permission: "User",
      path: "users",
    },
  ];
  const RjComponents = [
    {
      component: <Rj />,
      permission: "RJ",
      path: "rj",
    },
  ];

  const MediaComponents = [
    {
      component: <Podcast />,
      permission: "Podcast",
      path: "media",
    },
    {
      component: <MediaVideo />,
      permission: "Video",
      path: "media/video",
    },
    {
      component: <MediaShorts />,
      permission: "Shorts",
      path: "media/shorts",
    },
    {
      component: <MediaNews />,
      permission: "News",
      path: "media/news",
    },
  ];

  const AdvertisementComponents = [
    {
      component: <Dashboard />,
      permission: "Ad Analytics",
      path: "dashboard?activeTab=all",
    },
    {
      component: <Client />,
      permission: "Client",
      path: "dashboard/clients",
    },
    {
      component: <Campaign />,
      permission: "Campaign",
      path: "dashboard/campaign",
    },
    {
      component: <Advertisement />,
      permission: "Ads",
      path: "dashboard/advertisement",
    },
  ];

  const ProgrammeComponents = [
    {
      component: <Programme />,
      permission: "Program",
      path: "programme",
    },
  ];

  const CategoryComponents = [
    {
      component: <Category />,
      permission: "Category",
      path: "category",
    },
  ];

  const NewsCategoryComponents = [
    {
      component: <NewsCategory />,
      permission: "News Category",
      path: "news-category",
    },
  ];

  const SubAdminComponents = [
    {
      component: <SubAdmin />,
      permission: "Sub Admin",
      path: "subadmin",
    },
  ];

  const ScheduleComponents = [
    {
      component: <Schedule />,
      permission: "Schedule",
      path: "schedule",
    },
  ];

  const RoleComponents = [
    {
      component: <Role />,
      permission: "Role",
      path: "role",
    },
  ];

  const PrivacyComponents = [
    {
      component: <PrivacyAndTerms />,
      permission: "App",
      path: "settings",
    },
    {
      component: <BannerSettings />,
      permission: "Banner",
      path: "settings/banner",
    },
  ];

  const drawerMenuPermission = useMemo(() => {
    let menus = globalState?.dashboard_permissions?.menu_permissions?.flatMap?.(
      (menu) => {
        let permission = menu.sub_menu_permissions?.map(
          (side_menu) => side_menu.sub_menu_name
        );

        return [
          ...(permission ? permission : []),
          menu?.menu_name,
        ];
      }
    );

    if (!menus) {
      menus = [];
    }
    menus = [...menus, "Dashboard"];
    return menus;

    //eslint-disable-next-line
  }, [globalState.dashboard_permissions, location.pathname]);

  function hasPermission(permission) {
    const user = {
      permissions: drawerMenuPermission,
    };
    return user.permissions?.includes(permission);
  }

  

  const allowedUsersComponents = UsersComponents.filter(({ permission }) =>
    hasPermission(permission)
  );

  const allowedDashboardComponents = DashboardComponent.filter(({ permission }) =>
    hasPermission(permission)
  );

  const allowedRjComponents = RjComponents.filter(({ permission }) =>
    hasPermission(permission)
  );

  const allowedMediaComponents = MediaComponents.filter(({ permission }) =>
    hasPermission(permission)
  );

  const allowedAdvertisementComponents = AdvertisementComponents.filter(
    ({ permission }) => hasPermission(permission)
  );
  const allowedProgrammeComponents = ProgrammeComponents.filter(
    ({ permission }) => hasPermission(permission)
  );
  const allowedCategoryComponents = CategoryComponents.filter(
    ({ permission }) => hasPermission(permission)
  );

  const allowedNewsCategoryComponents = NewsCategoryComponents.filter(
    ({ permission }) => hasPermission(permission)
  );

  const allowedRoleComponents = RoleComponents.filter(({ permission }) =>
    hasPermission(permission)
  );
  const allowedSubAdminComponents = SubAdminComponents.filter(
    ({ permission }) => hasPermission(permission)
  );
  const allowedScheduleComponents = ScheduleComponents.filter(
    ({ permission }) => hasPermission(permission)
  );
  const allowedPrivacyComponents = PrivacyComponents.filter(
    ({ permission }) => hasPermission(permission)
  );


  return {
    allowedUsersComponents,
    allowedRjComponents,
    allowedMediaComponents,
    allowedAdvertisementComponents,
    allowedProgrammeComponents,
    allowedCategoryComponents,
    allowedNewsCategoryComponents,
    allowedRoleComponents,
    allowedSubAdminComponents,
    allowedScheduleComponents,
    allowedPrivacyComponents,
    allowedDashboardComponents
  };
};

export default useRoutes;
