import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { updateConfig } from "../../../../store/slices/Privacy/privacySlice";
import { toast } from "react-toastify";
import { getDataById, updatePrivacySettings } from "../api";

const useUpdatePrivacy = ({ activeId, refetch }) => {
  const dispatch = useDispatch();
 
  const [errors, setErrors] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentFormData, setCurrentFormData] = useState([]);

  useEffect(() => {
    if (activeId) {
      getDataById(activeId).then((response) => {
   
        formik.setErrors("");
        setErrors("");

        if (response?.data?.status_code === 200) {
          setCurrentFormData(response?.data?.data);
        } else if (response?.data?.error_code === 403) {
          formik.setErrors(response?.data?.errors);
        }
      });
    }
  }, []);

  let usersVal = Yup.object({
    name: Yup.string().required("*Required"),
    value: Yup.string().required("*Required"),
  });

  const formik = useFormik({
    initialValues: {
      _id: activeId,
      name: null,
      value: null,
    },
    validationSchema: usersVal,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      setIsLoading(true);
      try {
        let obj = {
          ...values,
        };
        let formData = new FormData();

      Object.keys(obj).forEach((key) => {
        return formData.append(key, obj[key]);
      });
      formData.append("_method", "PUT");
        updatePrivacySettings(obj).then((response) => {
          formik.setErrors("");
          setErrors("");

          if (response?.data?.status_code === 200) {
            setIsLoading(false);
            refetch();
            resetForm();
            dispatch(
              updateConfig((state) => {
                state.showEditNumberTypeModal = false;
              })
            );
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
            toast.success("Successfully udpated");
          } else if (response?.data?.error_code === 403) {
            setIsLoading(false);
            formik.setErrors(response?.data?.errors);
          } else if (response?.status_code === 500) {
            setIsLoading(false);

            toast.error("Something Went Wrong");
          } else {
            setIsLoading(false);
          }
        });
      } catch (error) {
        setErrors("Something went wrong");
      }
    },
  });

  useEffect(() => {
    if (currentFormData) {
      Object.keys(currentFormData || {}).forEach((key) => {
        formik.setFieldValue(key, currentFormData?.[key]);
      });
    }

    // eslint-disable-next-line
  }, [currentFormData]);

  const handleCloseModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showEditNumberTypeModal = false;
      })
    );
  };

  return {
    formik,
    errors,
    handleCloseModal,
    currentFormData,
    isLoading,
  };
};

export default useUpdatePrivacy;
