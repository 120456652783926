import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { updateConfig } from "../../../store/slices/Clients/clientSlice";
import { toast } from "react-toastify";
import { emailRegExp, phoneRegExp } from "../../../utils/functions/table";
import { getDataById, createNew } from "./api";
import {
  useUpdateClientListDataMutation,
} from "../../../store/queries/client";
import { useSelector } from "react-redux";

const useUpdateClient = ({ activeId, refetch, isEditData }) => {
  const dispatch = useDispatch();
  const [updateClients] = useUpdateClientListDataMutation();
  const { currentFormData } = useSelector((state) => state.client);
  const nameRegExp = /^[^\s].*$/;

  const [errors, setErrors] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (activeId) {
      getDataById(activeId).then((response) => {
        formik.setErrors("");
        setErrors("");

        if (response?.data?.status_code === 200) {
          dispatch(
            updateConfig((state) => {
              state.currentFormData = response?.data?.data;
            })
          );
        } else if (response?.error_code === 403) {
          formik.setErrors(response?.data?.errors);
        }
      });
    }
  }, []);

  let clientVal = Yup.object({
    name: Yup.string().required("Name is Required").matches(nameRegExp, "Leading space is not allowed"),
    contact_person: Yup.string().required("Contact Person is Required").matches(nameRegExp, "Leading space is not allowed"),
    email: Yup.string()
      .required("Email is Required")
      .matches(emailRegExp, "Enter a valid Email"),
    country_code: Yup.string().required("Country code is Required"),
    mobile: Yup.string()
      .matches(phoneRegExp, "Enter a valid phone number")
      .min(5, "Mobile number must be greater than 5 digits ")
      .required("Phone Number is Required"),
  });

  let clientUpdateVal = Yup.object({
    name: Yup.string().required("Name is Required").matches(nameRegExp, "Leading space is not allowed"),
    contact_person: Yup.string().required("Contact Person is Required").matches(nameRegExp, "Leading space is not allowed"),
    email: Yup.string()
      .required("Email is Required")
      .matches(emailRegExp, "Enter a valid Email"),
    country_code: Yup.string().required("Country code is Required"),
    mobile: Yup.string()
      .matches(phoneRegExp, "Enter a valid phone number")
      .min(5, "Mobile number must be greater than 5 digits ")
      .required("Phone Number is Required"),
  });

  const formik = useFormik({
    initialValues: {
      _id: activeId,
      name: null,
      contact_person: null,
      email: null,
      country_code: null,
      mobile: null,
      status: null,
    },
    validationSchema: isEditData ? clientUpdateVal : clientVal,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      setIsLoading(true);
      try {
        let obj = {
          name: values.name,
          contact_person: values.contact_person,
          country_code: values.country_code,
          mobile: values.mobile,
          email: values.email,
          status: +Boolean(values.status),
        };
        if (isEditData) {
          obj._id = values._id;
        }

        let formData = new FormData();

        Object.keys(obj).forEach((key) => {
          return formData.append(key, obj[key]);
        });
        if (isEditData === true) {
          updateClients(formData).then((response) => {
            formik.setErrors("");
            setErrors("");

            if (response?.data?.status_code === 200) {
              setIsLoading(false);
              refetch();
              resetForm();
              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                })
              );
              dispatch(
                updateConfig((state) => {
                  state.clearSelection = true;
                })
              );
              toast.success("Successfully udpated");
            } else if (response?.data?.error_code === 403) {
              setIsLoading(false);
              formik.setErrors(response?.data?.errors);
            } else if (response?.status_code === 500) {
              setIsLoading(false);
              toast.error("Something Went Wrong");
            } else {
              setIsLoading(false);
            }
          });
        } else {
          createNew(formData).then((response) => {
            formik.setErrors("");
            setErrors("");

            if (response?.data?.status_code === 200) {
              setIsLoading(false);
              refetch();
              resetForm();
              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                })
              );
              toast.success("Successfully Added");
            } else if (response?.error_code === 403) {
              setIsLoading(false);
              formik.setErrors(response?.errors);
            } else if (response?.status_code === 500) {
              setIsLoading(false);
              toast.error("Something Went Wrong");
            } else {
              setIsLoading(false);
            }
          });
        }
      } catch (error) {
        setErrors("Something went wrong");
      }
    },
  });

  const handleCloseModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };

  useEffect(() => {
    if (isEditData && currentFormData) {
      Object.keys(currentFormData || {}).forEach((key) => {
        formik.setFieldValue(key, currentFormData?.[key]);
      });
    }
    // }
    // eslint-disable-next-line
  }, [currentFormData, isEditData]);

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  return {
    formik,
    errors,
    isLoading,
    currentFormData,
    getFieldError,
    handleCloseModal,
  };
};

export default useUpdateClient;
