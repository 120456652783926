import { Outlet } from "react-router-dom";
import CommonLayout from "../../pages/Layouts/CommonLayout";
import useAdvertisement from "./useAdvertisement";

const Advertisement = () => {
  const { drawerMenu } = useAdvertisement();

  return (
    <CommonLayout drawerMenu={drawerMenu}>
      <Outlet />
    </CommonLayout>
  );
};

export default Advertisement;
