import {
  Button,
  IconText,
  Image,
  ModalLayout,
} from "@wac-ui-dashboard/wac_component_library";
import Style from "../newscategoryUpdationForm.module.scss";
import { limitStrLength } from "../../../../utils/functions/table";
import useUploads from "./useUploads";
import { ProfileCard } from "../../../Global/ProfileCard";

const Uploads = ({
  formik,
  getFieldError,
  isEditData,
  isLoading,
  profilefileInputRef,
  handleImage, 
  imagePreview, 
  show, 
  closeModal,
  fieldName,
  setShow,
  setImagePreview,
  cropData,
  formikImage,
  setFormikImage,
  handleClick,
}) => {
  const { handleCloseModal } = useUploads({
    formik,
  });

  return (
    <div className={`pro-w-100`}>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Image [1:1]*
        </label>
        <div className="input-drag">
          <input
            type="file"
            placeholder="placeholder"
            accept=".jpeg, .jpg , .png, .gif"
            ref={profilefileInputRef}
            className={`pro-input lg ${getFieldError("image") && " error"}`}
            id="image"
            name="image"
            onBlur={formik.handleBlur("image")}
            onChange={(e) => handleImage(e)}
            onClick={handleClick}
          />
          <span className="input-drag-box">
            <IconText
             title={
              formik?.values?.image?.name !== undefined &&
              isEditData &&
              formik?.values?.image?.name
                ? limitStrLength(formik?.values?.image?.name, 30)
                : isEditData && formik?.values?.image
                ? limitStrLength(formik?.values?.image ?? "", 30)
                : !isEditData && formik?.values?.image?.name
                ? limitStrLength(
                    formik?.values?.image?.name ?? "",
                    30
                  )
                : `Drop files to attach or browse`
            }
            />
          </span>
          {getFieldError("image") && (
            <span className="error-text">{getFieldError("image")}</span>
          )}
        </div>
        <p className="pro-mb-0 pro-font-xs pro-mt-1">(file of type: jpeg, png, jpg, gif.)</p>
      </div>
      <div className={`col-2 pro-my-2`}>
        <div className={`${Style.root_image_inner}`}>
          <Image
            width={100}
            height={100}
            src={imagePreview}
            alt={`image`}
          />
        </div>
      </div>

      {formikImage && (
        <ModalLayout backdrop="static" show={show} handleClose={closeModal}>
          <ProfileCard
            setShow={setShow}
            fieldName={fieldName}
            formik={formik}
            data={formikImage}
            handleClose={closeModal}
            setImagePreview={setImagePreview}
            cropData={cropData}
            setFormikImage={setFormikImage}
          />
        </ModalLayout>
      )}

      <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end">
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => handleCloseModal()}
          type="button"
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-mx-3"}
          type="submit"
          disabled={isLoading}
        >
          {isEditData ? "Update" : "Create"}
        </Button>
      </div>
    </div>
  );
};
export default Uploads;
