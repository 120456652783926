import {
  DataContainer,
  NavGroup,
} from "@wac-ui-dashboard/wac_component_library";
import useDashboardList from "./useDashboardList";
import { Link } from "react-router-dom";
import EmptyData from "../../Global/EmptyData";
import DashboardHero from "../DashboardHero";
import Style from "./dashboardListing.module.scss";
import Select from "react-select";
import AdPlacementList from "../AdPlacementList";
import Charts from "../../Global/Charts";
import { MyVerticallyCenteredModal } from "../../Global/MyVerticallyCenteredModal";
import BarChart from "../BarChart";
import ExportData from "../ExportData";
import { Doughnut } from "react-chartjs-2";

const DashboardList = () => {
  const {
    tabNavs,
    dashboardItems,
    dashboardData,
    isFetching,
    children,
    clientsList,
    campaignsList,
    clientId,
    adcampaign,
    handleClientChange,
    handleDateChange,
    setAdCampaign,
    lineData,
    datePeriod,
    activeTab,
    options,
    barOptions,
    genderGraph,
    barData,
    pieData,
    filteredTotals,
    hasCreatePermission,
    hasEditPermission,
    hasViewPermission,
    hasDeletePermission,
    dashboardState,
    doughnutTextCenter,
  } = useDashboardList();
  if (!dashboardData || !dashboardData?.data) {
    return <div className="app-loading"></div>;
  }

  return (
    <>
      <div className="row">
        <div className="col-md-4">
          <NavGroup navigations={tabNavs} type={"type-2"} Link={Link} />
        </div>
        <div className="col-md-8 pro-d-flex pro-justify-end pro-items-center">
          <div className={`${Style.filter_wrap} row  pro-w-100 pro-jusify-end`}>
            <div className="col">
              <div className="input-wrap ">
                {/* <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                  Select Date
                </label> */}
                <Select
                  id="date"
                  placeholder={"Select"}
                  className={`pro-input lg`}
                  classNamePrefix="pro-input"
                  options={dashboardData?.data?.lineChartDateTypeList}
                  getOptionValue={(option) => option?._id}
                  getOptionLabel={(option) => option?.name}
                  value={dashboardData?.data?.lineChartDateTypeList?.find(
                    (date) => date._id === datePeriod
                  )}
                  onChange={(value) => handleDateChange(value)}
                />
              </div>
            </div>
            <div className="col">
              <div className="input-wrap">
                  {/* <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                    Client
                  </label> */}
                  <Select
                    id="client"
                    // isClearable={true}
                    placeholder={"Select"}
                    className={`pro-input lg`}
                    classNamePrefix="pro-input"
                    options={clientsList}
                    getOptionValue={(option) => option?._id}
                    getOptionLabel={(option) => option?.name}
                    value={clientsList?.find(
                      (client) => client?._id === clientId?._id
                    )}
                    onChange={(value) => handleClientChange(value)}
                  />
              </div>
            </div>
            {clientId && (
            <div className="col">
              <div className="input-wrap ">
                {/* <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                  Campaign
                </label> */}
                <Select
                  id="ad_campaign"
                  // isClearable={true}
                  isMulti
                  placeholder={"Select"}
                  className={`pro-input lg`}
                  classNamePrefix="pro-input"
                  options={campaignsList}
                  getOptionValue={(option) => option?._id}
                  getOptionLabel={(option) => option?.name}
                  // value={campaignsList?.find(
                  //   (campaign) => campaign._id === adcampaign
                  // )}
                  // onChange={(value) => setAdCampaign(value?._id)}
                  value={campaignsList?.filter((m) =>
                    adcampaign?.includes?.(m._id)
                  )}
                  onChange={(value) => {
                    // Extract _id values from the array
                    const selectedIds = value.map((v) => v._id);
                    // Set the state with the array of selected IDs
                    setAdCampaign(selectedIds);
                  }}
                />
              </div>
            </div>
            )}
            <div className="btn-wrap col-md-2">
              <ExportData
                dashboardData={dashboardData}
                adcampaign={dashboardState.ad_campaign_id}
                activeTab={activeTab}
                className="pro-w-100"
              />
            </div>
          </div>
          
        </div>
      </div>
      
      <div className={`pro-w-100 pro-my-5`}>
        <DashboardHero
          data={filteredTotals}
          obj={dashboardItems}
          loading={isFetching}
          listingType={Object.keys(dashboardData?.data?.totals || {}) ?? ""}
          shimmerCount={4}
        />
      </div>
      {dashboardData?.data && Object.keys(dashboardData?.data)?.length !== 0 ? (
        children
      ) : (
        <>
          <EmptyData />
        </>
      )}
      <div className={Style.charts_container}>
      {!isFetching ? (
        <div className="row gx-5">
          <div className={`${Style.graph_box} ${Style.box_lg} flex-fill`}>
            <DataContainer>
              <h6 className="pro-ttl h6">Performance</h6>
              <Charts
                type="line"
                labels={lineData?.labels}
                datasets={lineData?.datasets}
                options={options}
              />
            </DataContainer>
          </div>

          <div className={`${Style.graph_box}`}>
            <DataContainer>
              <h6 className="pro-ttl h6">Gender</h6>
              {/* <Charts
                type="doughnut"
                labels={pieData?.labels}
                datasets={pieData?.datasets}
                options={genderGraph}
              /> */}
              {dashboardData?.data?.pieChart?.total_count ? 
              <div className={Style.doughnut_chart_wrap}>
              <Doughnut data={pieData} options={genderGraph} plugins={[doughnutTextCenter]} />
              </div> :
              <>
              <EmptyData />
            </>
              }
              
            </DataContainer>
          </div>

          <div className={`${Style.graph_box}`}>
            <DataContainer>
              <h6 className="pro-ttl h6">Age Category</h6>
              {dashboardData?.data?.barChart ? 
              <Charts
                type="bar"
                labels={barData?.labels}
                datasets={barData?.datasets}
                options={barOptions}
              /> : 
              <>
          <EmptyData />
        </>
}
            </DataContainer>
          </div>

          <div className={`${Style.graph_box}`}>
            <DataContainer>
              <BarChart dashboardData={dashboardData} />
            </DataContainer>
          </div>
        </div>
      ) : (
        <div className="app-loading fill"></div>
      )}
      </div>
      <AdPlacementList dashboardData={dashboardData?.data?.adPlacementReport} />
    </>
  );
};

export default DashboardList;
