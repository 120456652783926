import CommonLayout from "../../pages/Layouts/CommonLayout";
import { Outlet } from "react-router-dom";
import usePrivacyAndTerms from "./usePrivacyAndTerms";

const PrivacyAndTerms = () => {
  const {drawerMenu} = usePrivacyAndTerms();

  return (
    <CommonLayout drawerMenu={drawerMenu}>
      <Outlet />
    </CommonLayout>
  );
};

export default PrivacyAndTerms;