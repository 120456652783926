import {
  HeadingGroup,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
  ConfirmationBox,
  ModalLayout,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import Style from "../schedule.module.scss";
import { ImageCard } from "../../Global/ImageCard";
import useScheduleList from "./useScheduleList";
import UpdateSchedule from "../UpdateSchedule";
//   import Filter from "../Filter";

const ScheduleList = () => {
  const {
    schedule,
    scheduleState,
    filters,
    activeId,
    currentPage,
    activeFilter,
    showCreateModal,
    paginationOptions,
    isFetching,
    isLoading,
    showDeleteModal,
    isEditData,
    filterShow,
    setFilterShow,
    handleClearClick,
    handleEditModal,
    handleCreateClick,
    closeDeleteModal,
    handlePagination,
    handlePageSize,
    handleDateChange,
    refetch,
    handleSort,
    handleSearch,
    handleScheduleDataEditClick,
    closeModal,
    deleteScheduleDataClick,
    getRow,
    deleteSchedule,
    tableFields,
    hasCreatePermission,
    hasEditPermission,
    hasViewPermission,
    hasDeletePermission,
  } = useScheduleList();

  return (
   
        <>
          <HeadingGroup
            title={"Schdule"}
            buttonTitle={hasCreatePermission ?? false ? "Add New" : ""}
            extraClassName={`pro-mb-4`}
            handleClick={handleCreateClick}
          />
          <div className="row pro-mb-4 pro-pt-3 gx-2">
            <div className="col-auto">
              <SearchFilters
                data={filters}
                activeFilter={activeFilter}
                showFilters={true}
                onSearchInput={handleSearch}
                loading={isLoading}
                showActions={false}
                handleActionClick={handleEditModal}
                SearchIcon={
                  <span className="material-symbols-outlined"> search </span>
                }
                showDateRange={false}
                handleClear={handleClearClick}
              />
            </div>
            <div className="col">
              <button
                className={`pro-btn pro-btn-outline pro-h-100 pro-px-5 ${Style.filter_btn}`}
                onClick={() => setFilterShow(true)}
              >
                <span className="material-symbols-outlined">tune</span>
                <span>Filter</span>
              </button>
            </div>
            <Table
              data={schedule?.data?.original?.data || []}
              uniqueID={"_id"}
              fields={tableFields}
              showCheckBox={
                hasEditPermission || hasDeletePermission ? true : false
              }
              SortIcon={<FaSort />}
              handleEdit={handleScheduleDataEditClick}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              handleDelete={deleteScheduleDataClick}
              deleteIcon={
                <span className="material-symbols-outlined">delete</span>
              }
              handleSort={handleSort}
              getRow={getRow}
              loading={isFetching}
              perpage={scheduleState.currentPageSize}
              assignable={false}
              multiSelect={false}
              clear={scheduleState.clearSelection}
              editable={hasEditPermission ? true : false}
              deletable={hasDeletePermission ? true : false}
            />
            {schedule?.data?.original?.data?.length > 0 && (
              <Pagination
                currentPage={currentPage}
                totalPageCount={schedule?.data?.original?.last_page_number}
                onPageChange={handlePagination}
                options={paginationOptions}
                onActionChange={handlePageSize}
                center
              />
            )}
            <ModalLayout
              centered
              show={showDeleteModal}
              handleClose={closeDeleteModal}
            >
              <div className="pro-m-5">
                <ConfirmationBox
                  title={`Are you sure you want to delete?`}
                  isRight={true}
                  cancelText={`Cancel`}
                  submitText={`Delete`}
                  cancelAction={closeDeleteModal}
                  submitAction={deleteSchedule}
                >
                  ConfirmationBox
                </ConfirmationBox>
              </div>
            </ModalLayout>

            <OffCanvasLayout
              show={showCreateModal}
              handleClose={closeModal}
              title={`${
                isEditData ? "Update Schedule Details" : "Create Schedule"
              }`}
              closeIcon={
                <span className="material-symbols-outlined"> close </span>
              }
            >
              <UpdateSchedule
                activeId={activeId}
                refetch={refetch}
                isEditData={isEditData}
              />
            </OffCanvasLayout>

            <OffCanvasLayout
              centered={true}
              show={filterShow}
              handleClose={() => {
                setFilterShow(false);
              }}
              title={"Filter"}
              closeIcon={
                <span className="material-symbols-outlined">close</span>
              }
            >
              {/* <Filter setShowform={setFilterShow} /> */}
            </OffCanvasLayout>
          </div>
        </>
  );
};

export default ScheduleList;
