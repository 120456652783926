import {
  FormTabs,
} from "@wac-ui-dashboard/wac_component_library";
import useUpdateMediaNews from "./useUpdateMediaNews";
import Style from "./mediaNewsForm.module.scss";

const UpdateMediaNews = ({ activeId, refetch, isEditData }) => {
  const {
    tabs,
    activeTab,
    handleMoveToErrorTab,
    handleTabClick,
    renderTabContent,
    formik,
  } = useUpdateMediaNews({
    activeId,
    refetch,
    isEditData,
  });

  return (
    <>
    <FormTabs
      propStyle={{ root: Style.formTabs_root }}
      tabs={tabs}
      active={activeTab}
      handleClick={handleTabClick}
    />
    <form onSubmit={(e) => {formik.handleSubmit(e); handleMoveToErrorTab();}}>{renderTabContent()}</form>
  </>
  );
};
export default UpdateMediaNews;
