import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useObserver from "../../../../utils/hooks/useObserver";
import {
  getGlobalSearchData,
  updateConfig,
} from "../../../../store/slices/Global";

const useHeaderActions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchInputRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const globalState = useSelector((state) => state.global);
  const [page, setPage] = useState(1);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === "k") {
        event.preventDefault();
        setIsModalOpen((state) => !state);
        focusSearchInput();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [searchInputRef]);

  const focusSearchInput = () => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  };
  const closeModal = () => {
    setIsModalOpen(false);
    dispatch(
      updateConfig((state) => {
        state.searchKey = "";
        state.searchData = {};
      })
    );
  };

  const handleSearchData = (item) => {
    dispatch(
      updateConfig((state) => {
        state.searchKey = item;
        state.searchData = {};
      })
    );
    if (item?.length >= 2 && globalState.searchKey !== item) {
      dispatch(
        getGlobalSearchData({
          searchKey: item,
          page: 1,
          clearState: true,
          // user_id: activeUser,
        })
      );
    }
  };

  const handleLoadMore = () => {
    setPage((prev) => prev + 1);
    dispatch(
      getGlobalSearchData({
        searchKey: globalState?.searchKey,
        page: page + 1,
        // user_id: activeUser,
      })
    );
  };

  const { lastElement } = useObserver({
    loading: globalState?.searchStatus === "pending",
    hasMore:
      globalState?.searchData?.per_page *
        globalState?.searchData?.current_page <
      globalState?.searchData?.total,
    callback: handleLoadMore,
  });

  const handleInputChange = (item) => {
    setIsModalOpen(true);
    focusSearchInput();
    dispatch(
      updateConfig((state) => {
        state.searchKey = item;
        state.searchData = {};
      })
    );
    if (item?.length >= 2 && globalState.searchKey !== item) {
      dispatch(
        getGlobalSearchData({
          searchKey: item,
          page: 1,
          // user_id: activeUser,
        })
      );
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("USER_ACCESS_TOKEN");
    localStorage.removeItem("dashboard_permissions");
    dispatch(
      updateConfig((state) => {
        state.dashboard_permissions = {};
      })
    );
    navigate("/");
  };

  return {
    isModalOpen,
    searchInputRef,
    globalState,
    // isFetching,
    lastElement,
    setIsModalOpen,
    handleLogout,
    handleInputChange,
    closeModal,
    handleSearchData,
    globalData: globalState?.searchData,
  };
};

export default useHeaderActions;
