import { useEffect, useMemo, useState } from "react";
import {
  getResourceTypes,
  getDataByResourceType,
  // getDataByResourceTypeSearch,
  createNew,
  tagsListing,
  getDraggedItemById,
  resourcePositionList,
  publishBanner,
} from "./api";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../store/slices/Banner/bannerSlice";
import { toast } from "react-toastify";
import { all } from "axios";
import { CiGlass } from "react-icons/ci";

const useBannerList = () => {
  const [resourceTypesList, setResourceTypesList] = useState([]);
  const [resourceTypesDataList, setResourceTypesDataList] = useState([]);
  const [isItemDropped, setIsItemDropped] = useState(false);
  const [formikImage, setFormikImage] = useState("");
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState("");
  const [tagsList, setTagsList] = useState([]);
  const [incomingTagsList, setIncomingTagsList] = useState([]);
  const [curentClickedItem, setCurentClickedItem] = useState({});
  const [resourceType, setResourceType] = useState();
  const [draggedItemById, setDraggedItemById] = useState({});
  const [savedDraggedItemById, setSavedDraggedItemById] = useState();
  const [isEdited, setIsEdited] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [clickedItemIndex, setClickedItemIndex] = useState();
  const [imagesSet, setImagesSet] = useState(false);
  const [draggedItems, setDraggedItems] = useState([]);
  const [savedDraggedItems, setSavedDraggedItems] = useState([]);
  const [publishedItems, setPublishedItems] = useState([]);
  const [publishedItemsId, setPublishedItemsId] = useState([]);
  const [isChecked, setIschecked] = useState(false);
  const [allItems, setAllItems] = useState([]);
  const [draggedAllItem, setDraggedAllItem] = useState({});
  const [draggedAllItemId, setDraggedAllItemId] = useState({});
  const [currentDraggedItem, setcurrentDraggedItem] = useState({});
  const [clickedIndex, setClickedIndex] = useState(null);
  const [positionData, setPoistionData] = useState({})

  const dispatch = useDispatch();
  const { fieldName, cropData } = useSelector((state) => state.banner);
  useEffect(() => {
    if (publishedItems?.length) {
      const filteredPublishedItems = publishedItems.filter(
        (publishedItem) =>
          !draggedItems.some(
            (draggedItem) => draggedItem._id === publishedItem._id
          )
      );

      // Combine filtered published items with dragged items
      setAllItems([...filteredPublishedItems, ...draggedItems]);
      if(draggedItems.length > 0) {
        toast.warning("Add Banner Details of New Item")
      }
    } else if (savedDraggedItems?.length) {
      const filteredItems = savedDraggedItems.filter((savedItem) =>
        draggedItems.some(
          (draggedItem) => draggedItem._id === savedItem.media_id
        )
      );
      const filteredItems1 = draggedItems.filter(
        (draggedItem) =>
          !filteredItems.some(
            (filteredItem) => filteredItem.media_id === draggedItem.media_id
          )
      );
      setAllItems([...filteredItems, ...filteredItems1]);
    } else {
      setAllItems(draggedItems);
    }
  }, [publishedItems, draggedItems]);


  


  useEffect(() => {
    getResourceTypes().then((response) => {
      setResourceTypesList(response?.data?.data);
      const videoType = response?.data?.data.find(
        (item) => item.type === "Video"
      );
      if (videoType) {
        setResourceType(videoType?.id);
        getDataByResourceType(videoType?.id).then((responseData) => {
          setResourceTypesDataList(responseData?.data?.data);
        });
      }
    });
    tagsListing().then((response) => {
      setIncomingTagsList(response?.data?.data);
    });
    resourcePositionList().then((response) => {
      // setDraggedItems(response?.data?.data?.data);
      setPoistionData(response?.data?.data)
      setPublishedItems(response?.data?.data?.data);
    });
  }, []);

  const [imageTitleImagePreview, setImageTitleImagePreview] = useState();
  const [imageBannerPreview, setImageBannerPreview] = useState();

  const handleNavigationClick = (clickedItem) => {
    setResourceType(clickedItem?.id);
    getDataByResourceType(clickedItem?.id, "").then((response) => {
      setResourceTypesDataList(response?.data?.data);
    });
  };
  const navigationItems = resourceTypesList?.map((item) => {
    return {
      label: item.type,
      id: item.id,
      // link: `#${item.type.toLowerCase()}`, // Assuming the link should be based on the type
      isButton: false,
    };
  });

  const handleSearchResourceListChange = (value) => {
    getDataByResourceType(resourceType, value).then((response) => {
      setResourceTypesDataList(response?.data?.data);
    });
  };
  const handleSearchResourcePOsitionListChange = (value) => {
    resourcePositionList(value).then((response) => {
      setPublishedItems(response?.data?.data?.data);
    });
  };

  

  const hasCreatePermission = useMemo(() => {
    let permission =
      positionData?.permissions?.create_permission;
    return permission ?? false;
  }, [positionData?.permissions]);

  const hasEditPermission = useMemo(() => {
    let permission =
      positionData?.permissions?.edit_permission;
    return permission ?? false;
  }, [positionData?.permissions]);
  const hasViewPermission = useMemo(() => {
    let permission =
      positionData?.permissions?.list_permission;
    return permission ?? false;
  }, [positionData?.permissions]);
  const hasDeletePermission = useMemo(() => {
    let permission =
      positionData?.permissions?.delete_permission;
    return permission ?? false;
  }, [positionData?.permissions]);


  const handleDragStart = (event, item, index) => {
    setcurrentDraggedItem(item);
    setCurentClickedItem([]);
    if (
      (!draggedItems?.length || imagesSet) &&
      !draggedItems?.some((draggedItem) => draggedItem?._id === item?._id)
    ) {
      setDraggedItems([...draggedItems, item]);
    } else {
      toast.error(
        "Please update title image and banner image before dropping."
      );
    }

    setIsItemDropped(true);
    setImageTitleImagePreview(null);
    setImageBannerPreview(null);
    const newClickedItems = [...clickedItems];
    newClickedItems.fill(false); // Reset all clicked items
    newClickedItems[index] = true; // Set clicked item
    setClickedItems(newClickedItems);
    setClickedItemIndex(index + 1);
    const selectedIndex = selectedItems.findIndex(
      (selectedItem) => selectedItem?._id === item?._id
    );

    if (selectedIndex === -1) {
      // Item is not already selected, add it to the list
      const updatedSelectedItems = [...selectedItems, item];
      setSelectedItems(updatedSelectedItems);

      // Set the clickedItemIndex based on the length of selectedItems
      setClickedItemIndex(updatedSelectedItems.length);
    } else {
      // Item is already selected, update clickedItemIndex to its index + 1
      setClickedItemIndex(selectedIndex + 1);
    }
  };

  const handleDragOver = (event) => {
    // event.preventDefault();
  };
  const handleDragOverTwo = (event) => {
    if(hasEditPermission) {
      event.preventDefault();
    } 
  };

  const handleDrop = (event, index) => {
    event.preventDefault();
    setIsItemDropped(true);
    const updatedItems = [...resourceTypesDataList];

    // Filter out the dragged items from the updatedItems array
    const filteredItems = updatedItems.filter(
      (item) =>
        !draggedItems.some((draggedItem) => draggedItem?._id === item?._id)
    );

    // Insert the dragged items at the specified index
    filteredItems.splice(index, 0, ...draggedItems);

    setResourceTypesDataList(filteredItems);
    setDraggedItems([]);
  };

  const handleDragStartAllItems = (event, draggedItem, index) => {
    event.dataTransfer.setData("text", index);
    event.dataTransfer.effectAllowed = "move";
    setDraggedAllItem(draggedItem);
    setDraggedAllItemId(index);
  };

  const handleDropAllItems = (event, draggedItem, index) => {
    event.preventDefault(); // Prevent the default behavior

    // Find the index of the dragged item in the allItems array
    const draggedItemIndex = allItems.findIndex(
      (item) => item?._id === draggedItem?._id
    );

    if (draggedItemIndex !== -1) {
      // Create a copy of the allItems array
      const updatedAllItems = [...allItems];

      // Get the item at the drop position
      const itemAtDropPosition = updatedAllItems[index];

      // Swap the positions only if the dragged item and the item at the drop position are different
      if (draggedAllItem?._id !== itemAtDropPosition?._id) {
        // Swap the items
        const temp = updatedAllItems[index];
        updatedAllItems[index] = updatedAllItems[draggedAllItemId];
        updatedAllItems[draggedAllItemId] = temp;

        // Update the state with the updated array
        if (updatedAllItems?.length > 0) {
          setIsEdited(true);
          setAllItems(updatedAllItems);
          setSavedDraggedItems([...savedDraggedItems, updatedAllItems]);
        }
      }
    }
  };

  const handleRemoveItem = (itemToRemove) => {
    const updatedAllItems = allItems.filter(
      (item) => item?._id !== itemToRemove?._id
    );
    setAllItems(updatedAllItems);
    setDraggedItems([]);
    setSavedDraggedItems(updatedAllItems);
    setPublishedItems(updatedAllItems);
    setIsEdited(true);
    checkActive();
  };

  const [clickedItems, setClickedItems] = useState(
    Array(draggedItems?.length).fill(false)
  );

  const handleItemClick = (index, draggedItem) => {

    setClickedIndex(index);
    setcurrentDraggedItem([]);
    getDraggedItemById(draggedItem?._id).then((response) => {
      setDraggedItemById(response?.data?.data ?? {});
    });
    setCurentClickedItem(draggedItem);
    setClickedItemIndex(index + 1);
    const newClickedItems = [...clickedItems];
    newClickedItems.fill(false); // Reset all clicked items
    newClickedItems[index] = true; // Set clicked item
    setClickedItems(newClickedItems);
    setIsItemDropped(true);
  };

  let validations = Yup.object().shape({
    tags: Yup.array().min(1, "At least one tag is required"),
  });

  const handleChangeStatus = (index) => {
    // Create a copy of allItems
    const updatedItems = [...allItems];
    // Toggle the status of the clicked item
    updatedItems[index].status = updatedItems[index].status === 1 ? 0 : 1;
    // Update allItems state
    setAllItems(updatedItems);
    const isAnyItemActive = updatedItems?.some(item => item.status === 1);
    formik.setFieldValue("status", isAnyItemActive ? 1 : 0);
    setIsEdited(true);
    checkActive();
  };

  const formik = useFormik({
    initialValues: {
      title_image: null,
      banner_image: null,
      title: curentClickedItem?.title || currentDraggedItem?.title,
      media_id: curentClickedItem?.media_id || currentDraggedItem?.media_id,
      thumbnail:
        curentClickedItem?.thumbnail_url || currentDraggedItem?.thumbnail_url,
      sub_title: curentClickedItem?.sub_title || currentDraggedItem?.sub_title,
      status: curentClickedItem?.status || 0,
      position: clickedItemIndex,
      media_type: resourceType,
      tags: [],
    },
    validationSchema: validations,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      setIsLoading(true);
      try {
        let obj = {
          title: values.title,
          media_id: values.media_id,
          thumbnail: values.thumbnail,
          sub_title: values.sub_title,
          status: values.status,
          position: values.position,
          media_type: values.media_type,
        };
        if (values.title_image?.name || values.title_image?.length === 0) {
          obj.title_image = values.title_image;
        } else if (curentClickedItem?.title_image_url) {
          obj.title_image = curentClickedItem.title_image;
        } else if (currentDraggedItem?.title_image_url) {
          obj.title_image = currentDraggedItem.title_image;
        }
        if (values.banner_image?.name || values.banner_image?.length === 0) {
          obj.banner_image = values.banner_image;
        } else if (curentClickedItem?.banner_image_url) {
          obj.banner_image = curentClickedItem.banner_image;
        } else if (currentDraggedItem?.banner_image_url) {
          obj.banner_image = currentDraggedItem.banner_image;
        }
        if (values.tags) {
          values.tags.forEach((rating, index) => {
            obj[`tags[${index}][name]`] = rating;
          });
        }

        let formData = new FormData();

        Object.keys(obj).forEach((key) => {
          return formData.append(key, obj[key]);
        });

        createNew(formData).then((response) => {
          formik.setErrors("");
          setErrors("");

          if (response?.data?.status_code === 200) {
            setIsLoading(false);
            setIsEdited(true);
            setSavedDraggedItemById(response?.data?.data?._id);
            setSavedDraggedItems([...savedDraggedItems, response?.data?.data]);
            const updatedAllItems = allItems?.map((item) => {
              if (item?.media_id === response?.data?.data?.media_id) {
                return response?.data?.data;
              } else {
                return item;
              }
            });
            tagsListing().then((response) => {
              setIncomingTagsList(response?.data?.data);
            });
            setAllItems(updatedAllItems);
            formik.setFieldValue("tags", [null]);
            // resetForm();
            dispatch(
              updateConfig((state) => {
                state.showCreateModal = false;
              })
            );
            setImagesSet(true);
            toast.success(
              "Successfully Added. Please publish to reflect changes."
            );
          } else if (response?.error_code === 403) {
            if(response?.errors?.title_image) {
              toast.error("Please upload a title image/banner image before proceeding");
            }
            setIsLoading(false);
            setIsEdited(false);
            formik.setErrors(response?.errors);
          } else if (response?.status_code === 500) {
            setIsLoading(false);
            setIsEdited(false);
            toast.error("Something Went Wrong");
          } else {
            
            setIsLoading(false);
            setIsEdited(false);
          }
        });
      } catch (error) {
        setErrors("Something went wrong");
      }
    },
  });

  useEffect(() => {
    if (savedDraggedItemById !== undefined) {
      getDraggedItemById(savedDraggedItemById).then((response) => {
        setDraggedItemById(response?.data?.data);
      });
    }
  }, [savedDraggedItemById]);
 

  const handleTitleImage = (e) => {
    const files = e.target.files;
    if (files?.length > 0) {
      const file = files[0];
      const extension = file.name.split(".").pop().toLowerCase(); // Get the file extension
      if (extension === "png") {
        setShow(true);
        dispatch(
          updateConfig((state) => {
            state.fieldName = "title_image";
            state.cropData = {
              unit: "px",
              width: 300,
              height: 200,
              aspect: 3 / 2,
            };
          })
        );

        if (file.size / (1024 * 1024) <= 1) {
          setFormikImage(files);
          // setImagesSet(true); // Set imagesSet to true
        } else {
          toast.error("The image must be less than 1MB in size.");
          formik.setFieldError(
            "title_image",
            "The image must be less than 1MB in size."
          );
          closeModal();
        }
      } else {
        toast.error("Please select a PNG file.");
        formik.setFieldError("title_image", "Please select a PNG file.");
      }
    }
  };

  const handleBannerImage = (e) => {
    const files = e.target.files;
    if (files?.length > 0) {
      const file = files[0];
      const extension = file.name.split(".").pop().toLowerCase();
      if (["jpeg", "png", "jpg", "gif"].includes(extension)) {
        // Proceed with handling the image upload
        setShow(true);
        dispatch(
          updateConfig((state) => {
            state.fieldName = "banner_image";
            state.cropData = {
              unit: "px",
              width: 748,
              height: 818,
              aspect: 374 / 409,
            };
          })
        );
        if (file.size / (1024 * 1024) <= 1) {
          setFormikImage(files);
          // setImagesSet(true); // Set imagesSet to true
        } else {
          toast.error("The image must be less than 1MB in size.");
          formik.setFieldError(
            "banner_image",
            "The image must be less than 1MB in size."
          );
          closeModal();
        }
      } else {
        toast.error("Only JPEG, PNG, JPG and GIF files are allowed.");
      }
    }
  };
  const getFieldError = (fieldName) => {
    // if (formik.touched[fieldName] && formik.errors[fieldName]) {
    //   return formik.errors[fieldName];
    // }
    // return "";
  };
  const handleClick = (event) => {
    const { target = {} } = event || {};
    target.value = "";
  };
  const closeModal = () => {
    setShow(false);
  };

  const publishBannerSetting = () => {
    const data = allItems?.map((item,index) => ({
      title: item?.title,
      media_id: item?.media_id,
      thumbnail: item?.thumbnail,
      title_image: item?.title_image,
      banner_image: item?.banner_image,
      type: item?.type,
      sub_title: item?.sub_title,
      position: index + 1, // Fill this with the position value
      status: item?.status,
      media_type: item?.media_type,
      tags: item.tags && item.tags.length > 0 ? item.tags : undefined,
    }));

    const transformedData = { data: data };

    publishBanner(transformedData).then((response) => {
      if (response?.data?.status_code === 200) {
        setAllItems(response?.data?.data);
        toast.success("Successfully Published");
        setIsEdited(false);
      } else if (response?.error_code === 403) {
        toast.error("Error Occured");
        formik.setErrors(response?.errors);
      } else if (response?.status_code === 500) {
        toast.error("Something Went Wrong");
      }
    });
  };
  const hasNoPositionKey = allItems?.some(obj => !obj?.hasOwnProperty('position'));

  const checkActive = () => {
    if(hasEditPermission) {
      if(!hasNoPositionKey) {
        if(!isEdited)
        {
          return true;
        }
        else {
          return false
        }
      }
      else {
        return true
      }
    }
    else {
      return true
    }  
  }

  return {
    navigationItems,
    handleNavigationClick,
    resourceTypesDataList,
    handleDragStart,
    handleDragOver,
    handleDrop,
    draggedItems,
    handleRemoveItem,
    isItemDropped,
    handleTitleImage,
    handleBannerImage,
    formikImage,
    setFormikImage,
    setShow,
    show,
    formik,
    getFieldError,
    handleClick,
    closeModal,
    cropData,
    isLoading,
    errors,
    fieldName,
    imageTitleImagePreview,
    setImageTitleImagePreview,
    imageBannerPreview,
    setImageBannerPreview,
    tagsList,
    setTagsList,
    curentClickedItem,
    setCurentClickedItem,
    clickedItems,
    handleItemClick,
    incomingTagsList,
    draggedItemById,
    isEdited,
    publishBannerSetting,
    savedDraggedItems,
    publishedItemsId,
    publishedItems,
    handleSearchResourceListChange,
    handleSearchResourcePOsitionListChange,
    handleDragStartAllItems,
    handleDropAllItems,
    allItems,
    savedDraggedItemById,
    currentDraggedItem,
    handleDragOverTwo,
    clickedIndex,
    handleChangeStatus,
    setDraggedItemById,
    checkActive,
    hasEditPermission
  };
};

export default useBannerList;
