import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateConfig } from "../../../store/slices/TopGenres/topGenresSlice";
import Style from "./topGenres.module.scss";
import { Image } from "@wac-ui-dashboard/wac_component_library";
import Assets from "../../../assets/Assets";
import { useGetTopGenresListDataQuery } from "../../../store/queries/topGenres";
import { getFormatedDate } from "../../../utils/functions/table";


const useTopGenresList = () => {
  const topGenresState = useSelector((state) => state.topGenres);
  const dashboardState = useSelector((state) => state.homeDashboard);
  const { showDetailModal } = useSelector(
    (state) => state.topGenres
  );
  const { tableFieldsGenres } = useSelector((state) => state.global);
  const dispatch = useDispatch();

  const [searchCriteria, setSearchCriteria] = useState("");

  const [isSelectedAll, setIsSelectedAll] = useState(true);
  const [isSelectedVideos, setIsSelectedVideos] = useState(false);
  const [isSelectedPodcast, setIsSelectedPodcast] = useState(false);
  const [isSelectedShort, setIsSelectedShort] = useState(false);

  const {
    data: genresDetailData = {},
    isFetching,
    isSuccess,
    refetch
  } = useGetTopGenresListDataQuery({
    // date_period: "this_week",
    sort_by: topGenresState.sortBy,
    sort_order: topGenresState.sortOrder,
    from_date: getFormatedDate(dashboardState?.startDate),
    to_date: getFormatedDate(dashboardState?.endDate),
    list_type: "category",
    media_type: topGenresState?.media_type
  });

  const {
    data: genresDetailsData = {},
  } = useGetTopGenresListDataQuery({
    from_date: topGenresState?.startDate ? getFormatedDate(topGenresState?.startDate) : getFormatedDate(dashboardState?.startDate),
    to_date: topGenresState?.endDate ? getFormatedDate(topGenresState?.endDate) : getFormatedDate(dashboardState?.endDate),
    list_type: topGenresState?.list_type,
    media_type: topGenresState?.media_type,
    category_id: topGenresState?.category_id
  });

  const filteredData = genresDetailData?.data?.list?.filter((item) => {
    const lowerCaseCriteria = searchCriteria.toLowerCase();
    return (
      item?.name.toLowerCase().includes(lowerCaseCriteria)
    );
  });

  const tableHeading = {
    name: { label: "Genres", sortable: true},
    total_play_time: { label: "Play Time", sortable: true, tooltip:  "Total time spent watching or listening to a genre." }, 
    average_watch_time: { label: "Avg Watch Time", sortable: true, tooltip:  "Average time spent watching or listening to a genre." }, 
    reach: { label: "Reach", sortable: true, tooltip:  "Total number of unique users who have viewed your genre." }, 
    plays: { label: "Plays", sortable: true, tooltip:  "The number of times this genre has been watched or listened to" }, 
    retention_rate: { label: "Retention Rate", sortable: true, tooltip:  "The percentage of the Users who return to the content after their initial visit" },
    liked_count : { label: "Liked Count", sortable: true, tooltip:  "The number of likes this genre has received." }, 
    unliked_count : { label: "Unliked Count", sortable: true, tooltip:  "The number of dislikes this genre has received." },  
  };

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFieldsGenres = tableHeading;
      })
    );
    // eslint-disable-next-line
  }, []);



  const handleSort = (label) => {
    if (topGenresState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = topGenresState.sortOrder === 0 ? 1 : 0;
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = 0;
        })
      );
    }
  };

  const setActiveTab = (tab) => {
    if(tab == "All") {
      setIsSelectedAll(true);
      setIsSelectedVideos(false);
      setIsSelectedPodcast(false);
      setIsSelectedShort(false)
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.media_type = [1,2,3];
        })
      );
    }
    else if(tab == "Video") {
      setIsSelectedAll(false);
      setIsSelectedVideos(true);
      setIsSelectedPodcast(false);
      setIsSelectedShort(false)
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.media_type = [1];
        })
      );
    }
    else if(tab == "Short") {
      setIsSelectedAll(false);
      setIsSelectedVideos(false);
      setIsSelectedPodcast(false);
      setIsSelectedShort(true);
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.media_type = [3];
        })
      );
    }
    else {
      setIsSelectedAll(false);
      setIsSelectedVideos(false);
      setIsSelectedPodcast(true);
      setIsSelectedShort(false)
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.media_type = [2];
        })
      );
    }
  }

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => (
        <p className="pro-mb-0">{data[feild] ? `${data[feild]}` : "-"}</p>
      ),
      name: (feild, data) => {
        return (
          <div
            className={`pro-d-flex avatar-container ${Style.avatar_container}`}
            onClick={() => {
              handleDetailClick(data['category_id'])
            }}
          >
            <div
              className={`pro-overflow-hidden pro-rounded-2 pro-align-self-center ${Style.img_wrap}`}
            >
              <Image
                src={
                  data?.thumbnail !== "" && data?.thumbnail
                    ? data?.thumbnail
                    : Assets.GENDER_IMAGE
                }
                width={16}
                height={16}
                alt={data?.name}
              />
            </div>
            <span
              className={`pro-ms-2 pro-align-self-center `}
            >
              {data?.[feild]}
            </span>
          </div>
        );
      },
      total_play_time: (feild, data) => (
        <p className="pro-mb-0">{data[feild] !== 0 ? `${data[feild]}` : 0}</p>
      ),
      reach: (feild, data) => (
        <p className="pro-mb-0">{data[feild] !== 0 ? `${data[feild]}` : 0}</p>
      ),
      plays: (feild, data) => (
        <p className="pro-mb-0">{data[feild] !== 0 ? `${data[feild]}` : 0}</p>
      ),
      retention_rate: (feild, data) => (
        <p className="pro-mb-0">{data[feild] !== 0 ? `${data[feild]}` : 0}</p>
      ),
      liked_count: (feild, data) => (
        <p className="pro-mb-0">{data[feild] !== 0 ? `${data[feild]}` : 0}</p>
      ),
      unliked_count: (feild, data) => (
        <p className="pro-mb-0">{data[feild] !== 0 ? `${data[feild]}` : 0}</p>
      ),
      
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleDetailClick = (id) => {
    dispatch(
      updateConfig((state) => {
        state.activeId = id;
        state.showDetailModal = true;
        state.category_id = id;
        state.list_type = "details"
      })
    );
  };

  const closeModal = () => {
    refetch();
    dispatch(
      updateConfig((state) => {
        state.showDetailModal = false;
      })
    );
  };

  return {
    handleSort,
    getRow,
    tableFieldsGenres,
    filteredData,
    isSelectedAll,
    isSelectedVideos,
    isSelectedPodcast,
    isSelectedShort,
    setActiveTab,
    // genresDetailData,
    handleDetailClick,
    closeModal,
    showDetailModal,
    refetch,
    genresDetailsData,
    isFetching,
  };
};

export default useTopGenresList;
