import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key] === "status") {
        return `${key}=${parseInt(params[key])}&`;
      } else if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };
    try {
      const response = await api[method](
        `${endpoint}?${getParams("_id")}${getParams("status")}${getParams(
          "sort_by"
        )}sort_order=${params?.sort_order || ""}&page_size=${
          params?.page_size || "10"
        }&${getParams("search")}&page=${params?.page || 1}&${getParams(
          "length"
        )}`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        data: err.response?.data || err.message,
      }; 
    }
  };

export const newscategory = createApi({
  reducerPath: "newscategoryApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["NewsCategory"],
  endpoints: (builder) => ({
    getNewsCategoryListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/news-category/list`,
      }),
      provideTags: ["NewsCategory"],
    }),
    getNewsCategoryFormDataById: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/news-category/edit`,
      }),
      provideTags: ["NewsCategory"],
    }),
    updateNewsCategoryListData: builder.mutation({
        query: (body) => ({
          method: "post",
          body,
          endpoint: `/admin/news-category/update`,
        }),
  
        invalidatesTags: ["NewsCategory"],
      }),
    deleteNewsCategoryData: builder.mutation({
      query: (values) => ({
        method: "delete",
        params: values,
        endpoint: `/admin/news-category/delete`,
      }),

      invalidatesTags: ["NewsCategory"],
    }),
    getFilterListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/filters/common-search-filters`,
      }),
    }),
    statusChange: builder.mutation({
      query: (values) => ({
        method: "post",
        params: values,
        endpoint: `/admin/news-category/status-change`,
      }),
    }),
  }),
});

export const {
  useGetNewsCategoryListDataQuery,
  useGetNewsCategoryFormDataByIdQuery,
  useUpdateNewsCategoryListDataMutation,
  useDeleteNewsCategoryDataMutation,
  useGetFilterListDataQuery,
  useStatusChangeMutation,
} = newscategory;
