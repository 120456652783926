import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Programme/programmeSlice";


const useUploads = () => {
  const dispatch = useDispatch();
  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };

  return { handleCloseModal };
};

export default useUploads;
