import React from "react";
import useRouteUtils from "../../utils/hooks/useRouteUtils";

const useDashboard = () => {
  const { checkIfActiveRoute } = useRouteUtils();

  const drawerMenu = [
    {
      title: "Ad Analytics",
      link: "/dashboard?activeTab=all",
      icon: <span className="material-symbols-outlined x3"> bar_chart </span>,
      active: checkIfActiveRoute("/dashboard", true),
    },
    {
      title: "Client",
      link: "/dashboard/clients",
      icon: <span className="material-symbols-outlined x3"> handshake </span>,
      active: checkIfActiveRoute("/dashboard/clients", true),
    },
    {
      title: "Campaign",
      link: "/dashboard/campaign",
      icon: <span className="material-symbols-outlined x4"> campaign </span>,
      active: checkIfActiveRoute("/dashboard/campaign", true),
    },
    {
      title: "Ads",
      link: "/dashboard/advertisement",
      icon: <span className="material-symbols-outlined x3"> ad </span>,
      active: checkIfActiveRoute("/dashboard/advertisement", true),
    },
  ];

  return {
    drawerMenu,
  };
};

export default useDashboard;
