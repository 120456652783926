import { Button, Input, ModalLayout } from "@wac-ui-dashboard/wac_component_library";
import Modal from "react-bootstrap/Modal";
import useMyVerticallyCenteredModal from "../MyVerticallyCenteredModal/useMyVerticalCenteredModal"
import { format } from "date-fns";
import { useState } from "react";

export const MyVerticallyCenteredModal =({show,onHide, adCampaign, activeTab, from_date, to_date})=> {
    const {
      formik,
      errors,
      isLoading,
      getFieldError,
      } = useMyVerticallyCenteredModal({adCampaign,activeTab,onHide,from_date,to_date});

      const today = new Date();
      today.setDate(today.getDate());
      const minDate = today.toISOString().split("T")[0];
    
      const reverseDateString = (dateString) => {
        if (dateString) {
          const parts = dateString.split("-");
          const reversedDateString = parts[2] + "-" + parts[1] + "-" + parts[0];
          return reversedDateString;
        }
      };
      let dateStr = reverseDateString(formik.values.from_date);
      let dateStr1 = reverseDateString(formik.values.to_date);

    return (
      <ModalLayout
      show={show}
      centered
      handleClose={onHide}
        
      >
        <div className="pro-p-5">
        <Input
          className={`pro-input lg`}
          type="date"
          label={'From date'}
          name="from_date"
          max={minDate}
          // onChange={formik.handleChange}
          // value={formik.values.from_date}
          value={formik.values.from_date === "" ? "dd-MM-yyyy" : dateStr}
          onChange={(e) =>
            formik.setFieldValue(
              "from_date",
              format(new Date(e.target.value), "dd-MM-yyyy")
            )
          }
          onBlur={formik.handleBlur("from_date")}
          error={getFieldError("from_date")}
          errorMessage={getFieldError("from_date")}
        />
        <Input
          className={`pro-input lg`}
          type="date"
          name="to_date"
          label={'To date'}
          max={minDate}
          // onChange={formik.handleChange}
          // value={formik.values.to_date}
          value={formik.values.to_date === "" ? "dd-MM-yyyy" : dateStr1}
          onChange={(e) =>
            formik.setFieldValue(
              "to_date",
              format(new Date(e.target.value), "dd-MM-yyyy")
            )
          }
          onBlur={formik.handleBlur("to_date")}
          error={getFieldError("to_date")}
          errorMessage={getFieldError("to_date")}
        />
      <div className="pro-d-flex pro-justify-end pro-pt-4">
        <button className="pro-btn pro-btn-primary pro-px-5 lg" type="submit" onClick={formik.handleSubmit}>Export</button>
      </div>
        </div>
      </ModalLayout>
    );
  }