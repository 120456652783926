import { useState } from "react";

const useBarChart = ({ dashboardData }) => {
  const [isSelectedCountry, setIsSelectedCountry] = useState(true);
  const [isSelectedState, setIsSelectedState] = useState(false);
  const [isSelectedCity, setIsSelectedCity] = useState(false);

  const mainData = {
    labels: isSelectedCountry
      ? dashboardData?.data?.topCountries?.labels
      : isSelectedState
      ? dashboardData?.data?.topStates?.labels
      : isSelectedCity
      ? dashboardData?.data?.topCities?.labels
      : null,
    datasets: [
      {
        label: "Performance",
        data: isSelectedCountry
          ? dashboardData?.data?.topCountries?.data
          : isSelectedState
          ? dashboardData?.data?.topStates?.data
          : isSelectedCity
          ? dashboardData?.data?.topCities?.data
          : null,
        backgroundColor: "rgba(250, 107, 7, 0.5)",
        borderWidth: 0,
        barThickness: 4,
      },
    ],
  };
  const performanceOptions = {
    indexAxis: "y",
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        barPercentage: 1.0,
        categoryPercentage: 1.0,
        grid: {
          display: false,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    width: 300,
    height: 300,
    aspectRatio: 1,
  };

  return {
    mainData,
    performanceOptions,
    isSelectedCountry,
    setIsSelectedCountry,
    isSelectedState,
    setIsSelectedState,
    isSelectedCity,
    setIsSelectedCity,
  };
};

export default useBarChart;
