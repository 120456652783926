import {
  Table,
  HeadingGroup,
  Pagination,
  Button,
  OffCanvasLayout,
  MultiColumnTable,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import useGenresDetails from "./useGenresDetails";
import {
  DataContainer,
} from "@wac-ui-dashboard/wac_component_library";

import Style from "./genresDetails.module.scss";
import OverviewShimmer from "../../../Global/OverviewShimmer";
import Charts from "../../../Global/Charts";
import BarChart from "./BarChart";
import EpisodeList from "./Episode";
import CustomDateRangePicker from "../../../Global/CustomDateRangePicker";
import { SimpleReport } from "../../../Global/SimpleReport";

const GenresDetails = ({refetch, genresDetailData}) => {
  const {
    headerDetails,
    ageBarData,
    ageBarDataOptions,
    isLoading,
    barData,
    barDataOptions,
    ExportData,
    handleDateRangeChange,
    date,
  } = useGenresDetails({refetch, genresDetailData});
  return (
    <>
      {isLoading ? (
        <OverviewShimmer />
      ) : (
        <>
        <div className="row pro-justify-end">
            {/* <div className="col-auto">
              <Button onClick={() => ExportData()}>Export</Button>
            </div> */}
            <div className="col-auto">
              <CustomDateRangePicker
                defaultDateRange={date}
                handleDateRangeChange={handleDateRangeChange}
              />
            </div>
          </div>
          <div className="pro-my-5">
            <DataContainer propStyle={{ root: Style.container_root }}>
              <SimpleReport data={headerDetails} extraClassNames={`report-w-100`} />
            </DataContainer>
          </div>

          
              <div className={`${Style.graph_box} pro-mb-5`}>
                <DataContainer>
                  <BarChart mostActiveTimes={genresDetailData} />
                </DataContainer>
              </div>
              <div className="row row-cols gx-5 pro-mb-5">
                <div className={`${Style.graph_box} col`}>
                  <DataContainer>
                    <h6 className="pro-ttl h6">Region</h6>
                    <div className={Style.bar_chart_wrap}>
                      <Charts
                        type="bar"
                        labels={barData?.labels}
                        datasets={barData?.datasets}
                        options={barDataOptions}
                      />
                    </div>
                  </DataContainer>
                </div>
                <div className={`${Style.graph_box} col `}>
                    <DataContainer>
                      <h6 className="pro-ttl h6">Age & Gender</h6>
                      <div className={Style.bar_chart_wrap}>
                        <Charts
                          type="bar"
                          labels={ageBarData?.labels}
                          datasets={ageBarData?.datasets}
                          options={ageBarDataOptions}
                        />
                      </div>
                    </DataContainer>
                </div>
              </div>
              <div className="row row-cols gx-5 pro-mb-5">
                {/* <TopRjList dashboardData={dashboardData?.data?.topCategories?.rjs} /> */}
                  <EpisodeList
                  />
              </div>
        </>
      )}
    </>
  );
};

export default GenresDetails;
