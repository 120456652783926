import { useEffect, useState } from "react";

const useTable = ({
  fields,
  data,
  uniqueID,
  multiSelect,
  clear,
  handleSelect,
}) => {
  const [checked, setChecked] = useState([]);

  const tableFields = fields ? Object?.values(fields) : [];

  useEffect(() => {
    if (clear) {
      setChecked([]);
    }
  }, [clear]);

  function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key].label === value);
  }

  const handleOnChange = (id) => {
    if (checked?.includes(id)) {
      let filteredIds = checked?.filter((a) => a != id);
      setChecked(filteredIds);
      handleSelect(filteredIds);
    } else {
      if (multiSelect) {
        setChecked((state) => [...state, id]);
        handleSelect((state) => [...state, id]);
      } else {
        setChecked([id]);
        handleSelect([id]);
      }
    }
  };
  const handleCheckAll = () => {
    if (checked?.length == data?.length) {
      setChecked([]);
    } else {
      setChecked(data?.map((item) => item?.[uniqueID]));
    }
  };

  const checkedRowCount = checked?.length;
  return {
    tableFields,
    checkedRowCount,
    checked,
    getKeyByValue,
    handleOnChange,
    handleCheckAll,
  };
};

export default useTable;
