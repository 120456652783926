import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { updateConfig } from "../../../store/slices/Schedule/scheduleSlice";
import { createNew, getDataById } from "./api";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Details from "./Details";
import Uploads from "./Uploads";
import { useGetScheduleFormDataByIdQuery, useUpdateScheduleListDataMutation } from "../../../store/queries/schedule";
 
const useUpdateSchedule = ({ activeId, refetch, isEditData }) => {
  const { activeTab, completedTabs } = useSelector((state) => state.schedule);
  const [errors, setErrors] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentFormData, setCurrentFormData] = useState([]);
  const dispatch = useDispatch();
  const [moveToTab, setMoveToTab] = useState(false);
  const { data: formData = {} } = useGetScheduleFormDataByIdQuery();

  const [imagePreview, setImagePreview] = useState(
    currentFormData !== "" ? currentFormData?.image : ""
  );

  useEffect(() => {
    if (currentFormData !== "" && isEditData) {
      setImagePreview(currentFormData?.image ?? "");
    }
  }, [currentFormData]);

  useEffect(() => {
    if (activeId) {
      getDataById(activeId).then((response) => {
        formik.setErrors("");
        setErrors("");

        if (response?.data?.status_code === 200) {
          setCurrentFormData(response?.data?.data);
        } else if (response?.data?.error_code === 403) {
          formik.setErrors(response?.data?.errors);
        }
      });
    }
  }, []);

  const [updateSchedule] = useUpdateScheduleListDataMutation();

  let scheduleVal = Yup.object({
    title: Yup.string().required("*Required"),
    name: Yup.string().required("*Required"),
    time: Yup.string().required("*Required"),
    description: Yup.string().required("*Required"),
    image: Yup.string().required("*Required"),
  });

  const formik = useFormik({
    initialValues: {
      _id: activeId,
      title: null,
      name: null,
      time: null,
      description: null,
      image: null,
    },
    validationSchema: scheduleVal,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      setIsLoading(true);
      try {
        let obj = {
          title: values.title,
          name: values.name,
          time: values.time,
          description: values.description,
          image: values.image,
          // status: + Boolean(
          //   values.status
          // ),
        };

        if (isEditData) {
          obj._id = values._id;
        }
     
        if (values.image?.[0]?.name || values.image?.length === 0) {
          obj.image = values.image?.[0];
        }
       
        let formData = new FormData();
       
        Object.keys(obj).forEach((key) => {
          return formData.append(key, obj[key]);
        });
        if (isEditData === true) {
          updateSchedule(formData).then((response) => {
            formik.setErrors("");
            setErrors("");

            if (response?.data?.status_code === 200) {
              setIsLoading(false)
              refetch();
              resetForm();
              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                })
              );
              dispatch(
                updateConfig((state) => {
                  state.clearSelection = true;
                })
              );
              toast.success("Successfully udpated");
            } else if (response?.data?.error_code === 403) {
              setIsLoading(false)
              formik.setErrors(response?.data?.errors);
            }else if (response?.status_code === 500) {
              setIsLoading(false)
              toast.error("Something Went Wrong");
            }else{
              setIsLoading(false)
            }
          });
        } else {
          createNew(formData).then((response) => {
            formik.setErrors("");
            setErrors("");

            if (response?.data?.status_code === 200) {
              setIsLoading(false)
              refetch();
              resetForm();
              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                })
              );
              toast.success("Successfully Added");
            } else if (response?.error_code === 403) {
              setIsLoading(false)
              formik.setErrors(response?.errors);
            }else if (response?.status_code === 500) {
              setIsLoading(false)
              toast.error("Something Went Wrong");
            }else{
              setIsLoading(false)
            }
          });
        }
      } catch (error) {
        setErrors("Something went wrong");
      }
    },
  });

  const handleRemovePhotoImage = (e) => {
    formik.setFieldValue("photo", "");
  };
 
  useEffect(() => {
    if (isEditData && currentFormData) {
      Object.keys(currentFormData || {}).forEach((key) => {
        formik.setFieldValue(key, currentFormData?.[key]);
      });
    }
    // }
    // eslint-disable-next-line
  }, [currentFormData, isEditData]);

  const handleCloseModal = (e) => {
    // e.preventDefault();
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.CompletedPercentage = {
          ...state.CompletedPercentage,
          Details: 0,
        };
        state.activeTab = "Details";
      })
    );
  };

  const handleImage = (e) => {
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 1) {
      formik.setFieldValue("image", e?.target?.files);

      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setImagePreview(reader.result);
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 1) {
      toast.error("The image must be less than 1MB in size.");
      formik.setFieldError(
        "cover_photo",
        "The image must be less than 1MB in size."
      );
    }
  };

  const tabs = [
    {
      label: "Details",
      completed: calculateCompletionStatus([
        "title",
        "name",
        "time",
        "description",
        // "status",
      ]),
    },
    {
      label: "Uploads",
      completed: calculateCompletionStatus([
        "image",
      ]),
    },
  ];

  const tabsAndFields = [
    {
      label: "Details",
      fields: [
        "title",
        "name",
        "time",
        "description",
        // "status",
      ],
    },
    {
      label: "Uploads",
      fields: ["image",],
    },
  ];

  useEffect(() => {
    if (moveToTab === true) {
      handleMoveToErrorTab();
    }
    setMoveToTab(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.errors, moveToTab]);

  function calculateCompletionStatus(fields) {
    let finalArray = [];
    finalArray = fields;
    // eslint-disable-next-line array-callback-return
    var completedFields = finalArray?.filter((field) => {
      return !!formik?.values[field];
    });

    return (completedFields.length / fields.length) * 100;
  }

  const handleTabClick = (tab) => {
    dispatch(
      updateConfig((state) => {
        state.activeTab = tab?.label;
      })
    );
  };

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "Details":
        return (
          <Details
            formik={formik}
            tabs={tabs}
            formData={formData?.data}
            getFieldError={getFieldError}
            currentFormData={currentFormData}
            isEditData={isEditData}
            activeId={activeId}
            refetch={refetch}
          />
        );
      case "Uploads":
        return (
          <Uploads
            formik={formik}
            formData={formData?.data}
            tabs={tabs}
            isLoading={isLoading}
            getFieldError={getFieldError}
            imagePreview={imagePreview}
            handleImage={handleImage}
            isEditData={isEditData}
            activeId={activeId}
            refetch={refetch}
          />
        );
      default:
        return null;
    }
  };

  function setActiveTabByName(active) {
    setMoveToTab(false);
    const updatedTab = tabs.find((tab) => tab?.label === active);
    dispatch(
      updateConfig((state) => {
        state.activeTab = updatedTab?.label;
      })
    );
  }

  const handleMoveToErrorTab = () => {
    const firstErrorTab = Object.keys(formik.errors)[0];
    if (firstErrorTab && firstErrorTab !== activeTab) {
      const errorTab = tabsAndFields.find((tab) =>
        tab.fields.includes(firstErrorTab)
      );
      setActiveTabByName(errorTab?.label);
    }
    setMoveToTab(true);
  };

  return {
    formik,
    errors,
    tabs,
    isLoading,
    activeTab,
    currentFormData,
    handleMoveToErrorTab,
    handleCloseModal,
    handleRemovePhotoImage,
    handleImage,
    handleTabClick,
    renderTabContent,
    imagePreview,
  };
};

export default useUpdateSchedule;
