import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeTab: "",
  date_period: "",
  ad_campaign_id: [],
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 10,
  startDate: null,
  endDate: null,
  sortBy: "",
  sortOrder: "",
  search: "",
  showCreateModal: false,
  activeId: null,
  currentFormData: [null],
  isEditData: false,
  status: "",
  clearSelection: false,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = dashboardSlice.actions;

export default dashboardSlice.reducer;
