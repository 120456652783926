import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/MediaVideo/mediavideoSlice";


const useUploads = ({uploadState,setShowAlertModal}) => {
  const dispatch = useDispatch();
  const handleCloseModal = () => {

    if (uploadState && uploadState?.isUploading) {
      setShowAlertModal(true);
    }else{
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  }
  };

  return { handleCloseModal };
};

export default useUploads;
