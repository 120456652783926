import {
  Button,
  IconText,
  Image,
  ModalLayout,
} from "@wac-ui-dashboard/wac_component_library";
import Style from "../advertisementForm.module.scss";
import { limitStrLength } from "../../../../utils/functions/table";
import useUploads from "./useUploads";
import { ProfileCard } from "../../../Global/ProfileCard";

const Uploads = ({
  formik,
  getFieldError,
  isLoading,
  isEditData,
  profilefileInputRef,
  handleImage,
  handleThumbnail,
  handleBasicThumbnail,
  imagePreview,
  imageCoverPreview,
  basicThumbnailImageCoverPreview,
  handlevideo,
  handleSong,
  show,
  closeModal,
  fieldName,
  setShow,
  setImagePreview,
  setImageCoverPreview,
  setBasicThumbnailImageCoverPreview,
  cropData,
  formikImage,
  setFormikImage,
  currentFormData,
  handleClick,
}) => {
  const { handleCloseModal } = useUploads({
    formik,
  });


  return (
    <div className={`pro-w-100`}>
      {formik?.values?.type?._id === 1 && (
        <>
          <div className="input-wrap pro-mb-4">
            <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
              Video*
            </label>
            <div className="input-drag">
              <input
                type="file"
                placeholder="placeholder"
                accept=".mp4"
                ref={profilefileInputRef}
                className={`pro-input ${
                  formik.errors.video && formik.touched.video && " error"
                }`}
                id="video"
                name="video"
                onBlur={formik.handleBlur("video")}
                onChange={(e) => handlevideo(e)}
              />
              <span className="input-drag-box">
                <IconText
                  title={
                    formik?.values?.video?.[0]?.name !== undefined &&
                    isEditData &&
                    formik?.values?.video?.[0]?.name
                      ? limitStrLength(formik?.values?.video?.[0]?.name, 30)
                      : isEditData && formik?.values?.video
                      ? limitStrLength(formik?.values?.video ?? "", 30)
                      : !isEditData && formik?.values?.video?.[0]?.name
                      ? limitStrLength(
                          formik?.values?.video?.[0]?.name ?? "",
                          30
                        )
                      : `Drop files to attach or browse`
                  }
                />
                {/* <IconText
                  title={
                    isEditData && currentFormData?.is_converted !== 1
                      ? "Processing"
                      : formik?.values?.video?.[0]?.name !== undefined &&
                        isEditData &&
                        formik?.values?.video?.[0]?.name
                      ? limitStrLength(formik?.values?.video?.[0]?.name, 30)
                      : isEditData && formik?.values?.video
                      ? limitStrLength(formik?.values?.video ?? "", 30)
                      : !isEditData && formik?.values?.video?.[0]?.name
                      ? limitStrLength(
                          formik?.values?.video?.[0]?.name ?? "",
                          30
                        )
                      : "Drop files to attach or browse"
                  }
                /> */}
              </span>
              {formik.touched.video && formik.errors.video && (
                <span className="error-text">{formik.errors.video}</span>
              )}
            </div>
            <p className="pro-mb-0 pro-font-xs  pro-mt-1">
              (file of type: mp4, ogx, oga, ogv, ogg, webm, mkv, mov.)
            </p>
          </div>
        </>
      )}

      {formik.values?.type?._id === 2 && (
        <>
          <div className="input-wrap pro-mb-4">
            <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
              Audio*
            </label>
            <div className="input-drag">
              <input
                type="file"
                placeholder="placeholder"
                accept=".mp3, .wav"
                ref={profilefileInputRef}
                className={`pro-input lg ${getFieldError("audio") && " error"}`}
                id="audio"
                name="audio"
                onBlur={formik.handleBlur("audio")}
                onChange={(e) => handleSong(e)}
              />
              <span className="input-drag-box">
                <IconText
                  title={
                    formik?.values?.audio?.[0]?.name !== undefined &&
                    isEditData &&
                    formik?.values?.audio?.[0]?.name
                      ? limitStrLength(formik?.values?.audio?.[0]?.name, 30)
                      : isEditData && formik?.values?.audio
                      ? limitStrLength(formik?.values?.audio ?? "", 30)
                      : !isEditData && formik?.values?.audio?.[0]?.name
                      ? limitStrLength(
                          formik?.values?.audio?.[0]?.name ?? "",
                          30
                        )
                      : `Drop files to attach or browse`
                  }
                />
                {/* <IconText
                  title={
                    isEditData && currentFormData?.is_converted !== 1
                      ? "Processing"
                      : formik?.values?.audio?.[0]?.name !== undefined &&
                        isEditData &&
                        formik?.values?.audio?.[0]?.name
                      ? limitStrLength(formik?.values?.audio?.[0]?.name, 30)
                      : isEditData && formik?.values?.audio
                      ? limitStrLength(formik?.values?.audio ?? "", 30)
                      : !isEditData && formik?.values?.audio?.[0]?.name
                      ? limitStrLength(
                          formik?.values?.audio?.[0]?.name ?? "",
                          30
                        )
                      : "Drop files to attach or browse"
                  }
                /> */}
              </span>
              {getFieldError("audio") && (
                <span className="error-text">{getFieldError("audio")}</span>
              )}
            </div>
            <p className="pro-mb-0 pro-font-xs pro-mt-1">
              (file of type: mp3, wav)
            </p>
          </div>
        </>
      )}

      {formik.values?.type?._id === 3 && (
        <>
          <>
            {formik.values?.ad_placement_type?._id === 4 && (
              <>
                <div className="input-wrap pro-mb-4">
                  <label
                    htmlFor=""
                    className={`pro-font-sm pro-mb-1 pro-fw-medium`}
                  >
                    Image [4:5]*
                  </label>
                  <div className="input-drag">
                    <input
                      type="file"
                      placeholder="placeholder"
                      accept=".jpeg, .jpg , .png"
                      ref={profilefileInputRef}
                      className={`pro-input ${
                        formik.errors.image && formik.touched.image && " error"
                      }`}
                      id="image"
                      name="image"
                      onBlur={formik.handleBlur("image")}
                      onChange={(e) => handleImage(e)}
                      onClick={handleClick}
                    />
                    <span className="input-drag-box">
                      <IconText
                        title={
                          formik?.values?.image?.name !== undefined &&
                          isEditData &&
                          formik?.values?.image?.name
                            ? limitStrLength(formik?.values?.image?.name, 30)
                            : isEditData
                            ? limitStrLength(formik?.values?.image ?? "", 30)
                            : !isEditData && formik?.values?.image?.name
                            ? limitStrLength(
                                formik?.values?.image?.name ?? "",
                                30
                              )
                            : `Drop files to attach or browse`
                        }
                      />
                    </span>
                    {formik.touched.image && formik.errors.image && (
                      <span className="error-text">{formik.errors.image}</span>
                    )}
                  </div>
                  <p className="pro-mb-0 pro-font-xs  pro-mt-1">
                    (file of type: jpeg, png, jpg, gif.)
                  </p>
                </div>
                <div className={`col-2 pro-my-2`}>
                  <div className={`${Style.root_image_inner}`}>
                    <Image
                      width={100}
                      height={100}
                      src={imagePreview}
                      alt={`image`}
                    />
                  </div>
                </div>
              </>
            )}
          </>
          {formik.values?.ad_placement_type?._id === 7 && (
            <>
              <div className="input-wrap pro-mb-4">
                <label
                  htmlFor=""
                  className={`pro-font-sm pro-mb-1 pro-fw-medium`}
                >
                  Image [4:5]*
                </label>
                <div className="input-drag">
                  <input
                    type="file"
                    placeholder="placeholder"
                    accept=".jpeg, .jpg , .png"
                    ref={profilefileInputRef}
                    className={`pro-input ${
                      formik.errors.image && formik.touched.image && " error"
                    }`}
                    id="image"
                    name="image"
                    onBlur={formik.handleBlur("image")}
                    onChange={(e) => handleImage(e)}
                    onClick={handleClick}
                  />
                  <span className="input-drag-box">
                    <IconText
                      title={
                        formik?.values?.image?.name !== undefined &&
                        isEditData &&
                        formik?.values?.image?.name
                          ? limitStrLength(formik?.values?.image?.name, 30)
                          : isEditData
                          ? limitStrLength(formik?.values?.image ?? "", 30)
                          : !isEditData && formik?.values?.image?.name
                          ? limitStrLength(
                              formik?.values?.image?.name ?? "",
                              30
                            )
                          : `Drop files to attach or browse`
                      }
                    />
                  </span>
                  {formik.touched.image && formik.errors.image && (
                    <span className="error-text">{formik.errors.image}</span>
                  )}
                </div>
                <p className="pro-mb-0 pro-font-xs  pro-mt-1">
                  (file of type: jpeg, png, jpg, gif.)
                </p>
              </div>
              <div className={`col-2 pro-my-2`}>
                <div className={`${Style.root_image_inner}`}>
                  <Image
                    width={100}
                    height={100}
                    src={imagePreview}
                    alt={`image`}
                  />
                </div>
              </div>

              <div className="input-wrap pro-mb-4">
                <label
                  htmlFor=""
                  className={`pro-font-sm pro-mb-1 pro-fw-medium`}
                >
                  List Thumbnail [16:9]*
                </label>
                <div className="input-drag">
                  <input
                    type="file"
                    placeholder="placeholder"
                    accept=".jpeg, .jpg , .png"
                    ref={profilefileInputRef}
                    className={`pro-input lg ${
                      getFieldError("list_thumbnail") && " error"
                    }`}
                    id="list_thumbnail"
                    name="list_thumbnail"
                    onBlur={formik.handleBlur("list_thumbnail")}
                    onChange={(e) => handleThumbnail(e)}
                    onClick={handleClick}
                  />
                  <span className="input-drag-box">
                    <IconText
                      title={
                        formik?.values?.list_thumbnail?.name !== undefined &&
                        isEditData &&
                        formik?.values?.list_thumbnail?.name
                          ? limitStrLength(
                              formik?.values?.list_thumbnail?.name,
                              30
                            )
                          : isEditData
                          ? limitStrLength(
                              formik?.values?.list_thumbnail ?? "",
                              30
                            )
                          : !isEditData && formik?.values?.list_thumbnail?.name
                          ? limitStrLength(
                              formik?.values?.list_thumbnail?.name ?? "",
                              30
                            )
                          : `Drop files to attach or browse`
                      }
                    />
                  </span>
                  {getFieldError("list_thumbnail") && (
                    <span className="error-text">
                      {getFieldError("list_thumbnail")}
                    </span>
                  )}
                </div>
                <p className="pro-mb-0 pro-font-xs  pro-mt-1">
                  (file of type: jpeg, png, jpg, gif.)
                </p>
              </div>
              <div className={`col-2 pro-my-2`}>
                <div className={`${Style.root_image_inner}`}>
                  <Image
                    width={100}
                    height={100}
                    src={imageCoverPreview}
                    alt={`list_thumbnail`}
                  />
                </div>
              </div>
            </>
          )}
          {formik.values?.ad_placement_type?._id === 3 && (
            <>
              <div className="input-wrap pro-mb-4">
                <label
                  htmlFor=""
                  className={`pro-font-sm pro-mb-1 pro-fw-medium`}
                >
                  List Thumbnail [16:9]*
                </label>
                <div className="input-drag">
                  <input
                    type="file"
                    placeholder="placeholder"
                    accept=".jpeg, .jpg , .png"
                    ref={profilefileInputRef}
                    className={`pro-input lg ${
                      getFieldError("list_thumbnail") && " error"
                    }`}
                    id="list_thumbnail"
                    name="list_thumbnail"
                    onBlur={formik.handleBlur("list_thumbnail")}
                    onChange={(e) => handleThumbnail(e)}
                    onClick={handleClick}
                  />
                  <span className="input-drag-box">
                    <IconText
                      title={
                        formik?.values?.list_thumbnail?.name !== undefined &&
                        isEditData &&
                        formik?.values?.list_thumbnail?.name
                          ? limitStrLength(
                              formik?.values?.list_thumbnail?.name,
                              30
                            )
                          : isEditData
                          ? limitStrLength(
                              formik?.values?.list_thumbnail ?? "",
                              30
                            )
                          : !isEditData && formik?.values?.list_thumbnail?.name
                          ? limitStrLength(
                              formik?.values?.list_thumbnail?.name ?? "",
                              30
                            )
                          : `Drop files to attach or browse`
                      }
                    />
                  </span>
                  {getFieldError("list_thumbnail") && (
                    <span className="error-text">
                      {getFieldError("list_thumbnail")}
                    </span>
                  )}
                </div>
                <p className="pro-mb-0 pro-font-xs  pro-mt-1">
                  (file of type: jpeg, png, jpg, gif.)
                </p>
              </div>
              <div className={`col-2 pro-my-2`}>
                <div className={`${Style.root_image_inner}`}>
                  <Image
                    width={100}
                    height={100}
                    src={imageCoverPreview}
                    alt={`list_thumbnail`}
                  />
                </div>
              </div>

              <div className="input-wrap pro-mb-4">
                <label
                  htmlFor=""
                  className={`pro-font-sm pro-mb-1 pro-fw-medium`}
                >
                  Basic Thumbnail [1:1]*
                </label>
                <div className="input-drag">
                  <input
                    type="file"
                    placeholder="placeholder"
                    accept=".jpeg, .jpg , .png"
                    ref={profilefileInputRef}
                    className={`pro-input lg ${
                      getFieldError("basic_thumbnail") && " error"
                    }`}
                    id="basic_thumbnail"
                    name="basic_thumbnail"
                    onBlur={formik.handleBlur("basic_thumbnail")}
                    onChange={(e) => handleBasicThumbnail(e)}
                    onClick={handleClick}
                  />
                  <span className="input-drag-box">
                    <IconText
                      title={
                        formik?.values?.basic_thumbnail?.name !== undefined &&
                        isEditData &&
                        formik?.values?.basic_thumbnail?.name
                          ? limitStrLength(
                              formik?.values?.basic_thumbnail?.name,
                              30
                            )
                          : isEditData
                          ? limitStrLength(
                              formik?.values?.basic_thumbnail ?? "",
                              30
                            )
                          : !isEditData && formik?.values?.basic_thumbnail?.name
                          ? limitStrLength(
                              formik?.values?.basic_thumbnail?.name ?? "",
                              30
                            )
                          : `Drop files to attach or browse`
                      }
                    />
                  </span>
                  {getFieldError("basic_thumbnail") && (
                    <span className="error-text">
                      {getFieldError("basic_thumbnail")}
                    </span>
                  )}
                </div>
                <p className="pro-mb-0 pro-font-xs  pro-mt-1">
                  (file of type: jpeg, png, jpg, gif.)
                </p>
              </div>
              <div className={`col-2 pro-my-2`}>
                <div className={`${Style.root_image_inner}`}>
                  <Image
                    width={100}
                    height={100}
                    src={basicThumbnailImageCoverPreview}
                    alt={`basic_thumbnail`}
                  />
                </div>
              </div>
            </>
          )}
        </>
      )}

      {/* <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          List Thumbnail [16:9]
        </label>
        <div className="input-drag">
          <input
            type="file"
            placeholder="placeholder"
            accept=".jpeg, .jpg , .png"
            ref={profilefileInputRef}
            className={`pro-input lg ${
              getFieldError("list_thumbnail") && " error"
            }`}
            id="list_thumbnail"
            name="list_thumbnail"
            onBlur={formik.handleBlur("list_thumbnail")}
            onChange={(e) => handleThumbnail(e)}
            onClick={handleClick}
          />
          <span className="input-drag-box">
            <IconText
              title={
                formik?.values?.list_thumbnail?.name !== undefined &&
                isEditData &&
                formik?.values?.list_thumbnail?.name
                  ? limitStrLength(formik?.values?.list_thumbnail?.name, 30)
                  : isEditData
                  ? limitStrLength(formik?.values?.list_thumbnail ?? "", 30)
                  : !isEditData && formik?.values?.list_thumbnail?.name
                  ? limitStrLength(
                      formik?.values?.list_thumbnail?.name ?? "",
                      30
                    )
                  : `Drop files to attach or browse`
              }
            />
          </span>
          {getFieldError("list_thumbnail") && (
            <span className="error-text">
              {getFieldError("list_thumbnail")}
            </span>
          )}
        </div>
        <p className="pro-mb-0 pro-font-xs  pro-mt-1">
          (file of type: jpeg, png, jpg, gif.)
        </p>
      </div>
      <div className={`col-2 pro-my-2`}>
        <div className={`${Style.root_image_inner}`}>
          <Image
            width={100}
            height={100}
            src={imageCoverPreview}
            alt={`list_thumbnail`}
          />
        </div>
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Basic Thumbnail [1:1]
        </label>
        <div className="input-drag">
          <input
            type="file"
            placeholder="placeholder"
            accept=".jpeg, .jpg , .png"
            ref={profilefileInputRef}
            className={`pro-input lg ${
              getFieldError("basic_thumbnail") && " error"
            }`}
            id="basic_thumbnail"
            name="basic_thumbnail"
            onBlur={formik.handleBlur("basic_thumbnail")}
            onChange={(e) => handleBasicThumbnail(e)}
            onClick={handleClick}
          />
          <span className="input-drag-box">
            <IconText
              title={
                formik?.values?.basic_thumbnail?.name !== undefined &&
                isEditData &&
                formik?.values?.basic_thumbnail?.name
                  ? limitStrLength(formik?.values?.basic_thumbnail?.name, 30)
                  : isEditData
                  ? limitStrLength(formik?.values?.basic_thumbnail ?? "", 30)
                  : !isEditData && formik?.values?.basic_thumbnail?.name
                  ? limitStrLength(
                      formik?.values?.basic_thumbnail?.name ?? "",
                      30
                    )
                  : `Drop files to attach or browse`
              }
            />
          </span>
          {getFieldError("basic_thumbnail") && (
            <span className="error-text">
              {getFieldError("basic_thumbnail")}
            </span>
          )}
        </div>
        <p className="pro-mb-0 pro-font-xs  pro-mt-1">
          (file of type: jpeg, png, jpg, gif.)
        </p>
      </div>
      <div className={`col-2 pro-my-2`}>
        <div className={`${Style.root_image_inner}`}>
          <Image
            width={100}
            height={100}
            src={basicThumbnailImageCoverPreview}
            alt={`basic_thumbnail`}
          />
        </div>
      </div> */}

      {formikImage && (
        <ModalLayout backdrop="static" show={show} handleClose={closeModal}>
          <ProfileCard
            setShow={setShow}
            fieldName={fieldName}
            formik={formik}
            data={formikImage}
            handleClose={closeModal}
            setImagePreview={setImagePreview}
            setImageCoverPreview={setImageCoverPreview}
            setBasicThumbnailImageCoverPreview={
              setBasicThumbnailImageCoverPreview
            }
            cropData={cropData}
            setFormikImage={setFormikImage}
          />
        </ModalLayout>
      )}

      <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end">
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => handleCloseModal()}
          type="button"
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-mx-3"}
          type="submit"
          disabled={isLoading}
        >
          {isEditData ? "Update" : "Create"}
        </Button>
      </div>
    </div>
  );
};
export default Uploads;
