import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { resendOtp, validateOTP } from "../api";
import useValidations from "../../../utils/hooks/useValidations";
import { toast } from "react-toastify";

const useForgotPassword = (emailRef, countryCodeRef, otpExpiryRef) => {
  const { onlyNumbers } = useValidations();
  const navigate = useNavigate();

  const [otp, setOtp] = useState("");
  const [errors, setErrors] = useState("");

  const [otpStatus, setOtpStatus] = useState("idle");
  const [otpInfo, setOtpInfo] = useState({});
  const [status, setStatus] = useState("idle");
  const [info, setInfo] = useState({});
  const [otpCounter, setOtpCounter] = useState(otpExpiryRef.current * 60);

  useEffect(() => {
    setOtpCounter(otpExpiryRef.current * 60);
  }, [otpExpiryRef.current]);

  useEffect(() => {
    let timer = null;
  
    if (otpCounter > 0) {
      timer = setInterval(() => {
        setOtpCounter(prevCounter => prevCounter - 1);
      }, 1000);
    }
  
    return () => {
      clearInterval(timer);
    };
  }, [otpCounter]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  useEffect(() => {
    if (otpStatus === "success") {
      navigate("/login/reset-password");
    } else if (otpStatus === "failed") {
      setErrors(otpInfo?.otp);
    }

    // if (status === "success") {
    //   navigate("/login/reset-password");
    // } else if (status === "failed") {
    //   setErrors(info?.otp);
    // }
    // eslint-disable-next-line
  }, [otpStatus]); //info

  const handleBlur = () => {
    setErrors("");
  };

  const handleChange = (e) => {
    setErrors("");
    if (
      (onlyNumbers(e.target.value) && e.target.value.length <= 4) ||
      e.target.value.length === 0
    ) {
      setOtp(e.target.value);
    }
  };

  const handleResendOtp = () => {
    const inputvalue = emailRef.current;
    const code = countryCodeRef.current;
    resendOtp(inputvalue, code).then((response) => {
      if (response?.data?.status_code === 200) {
        setStatus("success");
        toast.success(response?.data?.message);
        const newOtpExpiry = response?.data?.data?.otp_expiry_time;
        if (newOtpExpiry === otpExpiryRef.current) {
          otpExpiryRef.current = newOtpExpiry;
          setOtpCounter(otpExpiryRef.current * 60); 
        }
      } else if (response?.status_code === 400) {
        setInfo({
          inputvalue: response?.message?.value?.[0],
        });
        setStatus("failed");
      } else if (response?.error_code === 403) {
        setInfo({
          inputvalue: response?.message?.value?.[0],
        });
        setStatus("failed");
        toast.success("Something Went Wrong");
      } else setStatus("An error occured");
    });
  };

  const handleSubmit = (e) => {
    e?.preventDefault();

    if (otp) {
      setOtpStatus("pending");

      validateOTP(emailRef.current, countryCodeRef.current, parseInt(otp)).then(
        (response) => {
          if (response?.data?.status_code === 200) {
            setOtpStatus("success");
            localStorage.setItem(
              "USER_ACCESS_TOKEN",
              response?.data?.message?.token
            );
            toast.success(response?.data?.data);
          } else if (response?.error_code === 400) {
            setOtpInfo({
              // otp: response?.message?.otp?.[0],
              otp: response?.errors?.otp,
            });
            setOtpStatus("failed");
            toast.error(response?.errors?.otp);
          } else if (response?.error_code === 403) {
            setOtpInfo({
              otp: response?.errors?.otp,
            });
            setOtpStatus("failed");
            // toast.error(response?.errors?.otp)
          } else setOtpStatus("An error occured");
        }
      );
    } else {
      setErrors("*OTP cannot be empty");
    }
  };

  return {
    otp,
    errors,
    otpStatus,
    handleChange,
    handleSubmit,
    handleBlur,
    handleResendOtp,
    otpCounter,
    formatTime,
  };
};

export default useForgotPassword;
