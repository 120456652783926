import { getAxiosInstance } from "../../../api";

  export const getDataById = async (id) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`admin/sub-admin/edit?_id=${id}`);
      return response;
    } catch (error) {
      return error.response.data;
    }
  };
  export const createNew = async (data) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("admin/sub-admin/save",data);
      return response;
    } catch (error) {
      return error.response.data;
    }
  };

  export const subAdminDataList = async () => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("admin/sub-admin/basic-data");
      return response;
    } catch (error) {
      return error.response.data;
    }
  };