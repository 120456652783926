import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key] === "status") {
        return `${key}=${parseInt(params[key])}&`;
      } else if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };
    try {
      const response = await api[method](
        `${endpoint}?${getParams("_id")}${getParams("from_date")}${getParams(
          "to_date"
        )}${getParams("status")}${getParams("category_id")}${getParams(
          "rj_id"
        )}${getParams("type")}${getParams("programe_id")}${getParams(
          "sort_by"
        )}sort_order=${params?.sort_order || ""}&${getParams("search")}page=${
          params?.page || 1
        }&${getParams("length")}${getParams("media_id")}${getParams(
          "search"
        )}${getParams("is_update")}`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        data: err.response?.data || err.message,
      };
    }
  };

export const banner = createApi({
  reducerPath: "bannerApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["Banner"],
  endpoints: (builder) => ({}),
});

export const {} = banner;
