import { Input, Button } from "@wac-ui-dashboard/wac_component_library";
import useUpdatePrivacy from "./useUpdatePrivacy";

const UpdatePrivacy = ({ activeId, refetch }) => {
  const { handleCloseModal, formik, errors, isLoading, dateStr, minDate } =
    useUpdatePrivacy({
      activeId,
      refetch,
    });

  return (
    <div className={`pro-w-100`}>
      <Input
        label={"Name"}
        type="text"
        id="name"
        name="name"
        className={`pro-input lg ${
          formik.errors.name && formik.touched.name && " error"
        }`}
        {...formik.getFieldProps("name")}
        error={formik.touched.name && formik.errors.name}
        errorMessage={formik.errors.name}
      />
      <Input
        label={"Value"}
        type="text"
        id="value"
        name="value"
        className={`pro-input lg ${
          formik.errors.value && formik.touched.value && " error"
        }`}
        {...formik.getFieldProps("value")}
        error={formik.touched.value && formik.errors.value}
        errorMessage={formik.errors.value}
      />

      <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end">
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => handleCloseModal()}
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-mx-3"}
          type="submit"
          disabled={isLoading}
          onClick={formik.handleSubmit}
        >
          Update
        </Button>
      </div>
      {errors && <span className="error-text">{errors}</span>}
    </div>
  );
};
export default UpdatePrivacy;
