import { createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 10,
  episodeCurrentPageSize: 10,
  episodeCurrentPage: 1,
  startDate: null,
  endDate: null,
  sortBy: "",
  sortOrder: 0,
  episodeSortBy: "",
  episodeSortOrder: 0,
  search: "",
  showCreateModal: false,
  activeId: null,
  currentFormData: [null],
  activeTab: "Basic",
  primary_radio_file: "", 
  completedTabs: {
    Basic: false,
  },
  CompletedPercentage: {
    Basic: 0,
  },
  isEditData: false, 
  status : "",
  clearSelection: false,
  selectedUpdateRows : [null],
  media_type: [1,2],
  list_type: "program",
  activeTab: ""
};


export const exportAllData = async (data) => {

  
  const api = await getAxiosInstance();
  
  // Define the base payload
  let payload = {
    from_date: data?.from_date,
    to_date: data?.to_date,
    dashboard_type: data?.dashboard_type
  };

  // Add dashboard_type conditionally
  if (data.dashboard_type == 2) {
    // payload.dashboard_type = data.dashboard_type;
    payload.sort_by = data.sort_by;
        payload.sort_order = data.sort_order;
        payload.program_id = data.program_id;
        payload.media_type = data.media_type;
  }

  try {
    const response = await api.post(
      `/admin/dashboard/analytics-export`,
      payload,
      {
        responseType: "blob",
      }
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};

const topProgramsSlice = createSlice({
  name: "topPrograms",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    setStoreFormValues: (state, action) => {
      state.status= action.payload.status;
    },
    resetExtraFilters: (state, action) => {
      state.status= '';
    },
  },
});

export const { updateConfig, setStoreFormValues,resetExtraFilters } = topProgramsSlice.actions;

export default topProgramsSlice.reducer;
