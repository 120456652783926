import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import RjFilterForm from "./RjFilterForm";
import {
  resetExtraFilters,
  setStoreFormValues,
} from "../../../store/slices/Rj/rjSlice";

const useFilter = ({ setShowform,refetch }) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.rj);

  const validationSchema = Yup.object({
    status: Yup.string().nullable(),
    from_date: Yup.date().nullable(),
    to_date: Yup.date().nullable(),
  });

  const initialValues = {
    status: state.status,
    from_date: state.from_date,
    to_date: state.to_date,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (values) {
        dispatch(setStoreFormValues(values));
      }
      const { status, from_date, to_date } = values;
      if (status === "" && from_date === "" && to_date === "") {
        toast.error("All fields are empty!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        setShowform(false);
      }
      let data = {
        status,
        from_date,
        to_date,
      };
    },
  });

  useEffect(() => {
    // Check if status is not empty before refetching
    if (formik.values.status !== '') {
      refetch();
    }
  }, [formik.values.status, refetch]);

  const renderTabContent = () => {
    return <RjFilterForm formik={formik} handleReset={handleReset} />;
  };

  const handleReset = () => {
    dispatch(resetExtraFilters());
    formik.resetForm();
    setShowform(false);
  };

  return {
    renderTabContent,
  };
};

export default useFilter;
