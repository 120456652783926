import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key] === "status") {
        return `${key}=${parseInt(params[key])}&`;
      } else if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };
    try {
      const response = await api[method](
        `${endpoint}?${getParams("_id")}${getParams("status")} ${getParams(
          "sort_by"
        )}sort_order=${params?.sort_order || ""}&page_size=${
          params?.page_size || "10"
        }&${getParams("search")}&page=${params?.page || 1}&${getParams(
          "length"
        )}`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        data: err.response?.data || err.message,
      }; 
    }
  };

export const programme = createApi({
  reducerPath: "programmeApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["Programme"],
  endpoints: (builder) => ({
    getProgrammeListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/programme/fetch-list`,
      }),
      provideTags: ["Programme"],
    }),
    getProgrammeFormDataById: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/programme/edit`,
      }),
      provideTags: ["Programme"],
    }),
    updateProgrammeListData: builder.mutation({
        query: (body) => ({
          method: "post",
          body,
          endpoint: `/admin/programme/update`,
        }),
  
        invalidatesTags: ["Podcast"],
      }),
    deleteProgrammeData: builder.mutation({
      query: (values) => ({
        method: "delete",
        params: values,
        endpoint: `/admin/programme/delete`,
      }),

      invalidatesTags: ["Programme"],
    }),
    getFilterListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/filters/common-search-filters`,
      }),
    }),
    statusChange: builder.mutation({
      query: (values) => ({
        method: "post",
        params: values,
        endpoint: `/admin/programme/status-change`,
      }),
    }),
    typeList: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/programme/basic-data`,
      }),
    }),
  }),
});

export const {
  useGetProgrammeListDataQuery,
  useGetProgrammeFormDataByIdQuery,
  useTypeListQuery,
  useUpdateProgrammeListDataMutation,
  useDeleteProgrammeDataMutation,
  useGetFilterListDataQuery,
  useStatusChangeMutation,
} = programme;
