import {
  FormTabs,
} from "@wac-ui-dashboard/wac_component_library";
import useUpdateRj from "./useUpdateRj";
import Style from "./rjUpdationForm.module.scss";

const UpdateRj = ({ activeId, refetch, isEditData }) => {
  const {
    tabs,
    activeTab,
    handleTabClick,
    handleMoveToErrorTab,
    renderTabContent,
    formik,
  } = useUpdateRj({
    activeId,
    refetch,
    isEditData,
  });

  return (
    <>
    <FormTabs
      propStyle={{ root: Style.FormTabs_root }}
      tabs={tabs}
      active={activeTab}
      handleClick={handleTabClick}
    />
    <form onSubmit={(e) => {formik.handleSubmit(e); handleMoveToErrorTab();}}>{renderTabContent()}</form>
    </>
  );
};
export default UpdateRj;
