import { useState } from "react";
import { useParams } from "react-router-dom";
import { exportAllData, updateConfig } from "../../../../store/slices/TopGenres/topGenresSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getFormatedDate, getFormatedDateRange } from "../../../../utils/functions/table";
import { toast } from "react-toastify";
import moment from "moment";
import { computeHeadingLevel } from "@testing-library/react";

const useGenresDetails = ({refetch, genresDetailData}) => {

  const topGenresState = useSelector((state) => state.topGenres);
  const dashboardState = useSelector((state) => state.homeDashboard);
  const dispatch = useDispatch();

  const headerLabel = {

    new_followers: { label: "New Followers", tooltip:  "New followers a genre gained over a given date range."}, 
    total_followers: { label: "Total Followers", tooltip:  "Total followers a genre gained over a given date range."}, 
    plays: { label: "Plays", tooltip:  "The number of times this genre has been watched or listened to."}, 
    retention_rate: { label: "Retention Rate", tooltip:  "The percentage of the Users who return to the content after their initial visit."}, 
    total_paly_time: { label: "Total Play Time", tooltip:  "Total time spent watching or listening to a genre."}, 
    average_age: { label: "Average Age", tooltip:  "Average age of total listers/viewers of the genre."}, 
    average_watch_time: { label: "Average Watch Time", tooltip:  "Average time spent watching or listening to a genre."}, 
    liked_count : { label: "Liked Count", sortable: true, tooltip:  "The number of likes this genre has received." }, 
    unliked_count : { label: "Unliked Count", sortable: true, tooltip:  "The number of dislikes this genre has received." }, 
    share: { label: "Share"}, 
  };

  const overView = genresDetailData?.data || {};
  const HeaderValue = {
    // new_followers: `${overView.sectionDetails?.new_followers || "0"}`,
    // total_followers: `${overView.sectionDetails?.total_followers || "0"}`,
    plays: `${overView.sectionDetails?.plays || "0"}`,
    retention_rate: `${overView.sectionDetails?.retention_rate || "0"}`,
    total_paly_time: `${overView.sectionDetails?.total_play_time || "0"}`,
    average_age: `${overView.sectionDetails?.average_age || "0"}`,
    average_watch_time: `${overView.sectionDetails?.average_watch_time || "0"}`,
    liked_count: `${overView.sectionDetails?.liked_count| "0"}`,
    unliked_count: `${overView.sectionDetails?.unliked_count|"0"}`,
    // share: `${overView.sectionDetails?.shares || "0"}`
  };

  const headerDetails = Object.keys(HeaderValue).map((key) => {
    return {
      label: headerLabel[key],
      value:
        typeof HeaderValue[key] === "string"
          ? HeaderValue?.[key]
          : typeof HeaderValue[key] === "number"
          ? HeaderValue?.[key]
          : HeaderValue?.[key]?.name,
    };
  });

  const barData = {
    labels: overView?.mostActiveCountries?.labels,
    datasets:[
      {
        label: "",
        data: overView?.mostActiveCountries?.data,
        backgroundColor: [
          'rgba(245, 158, 11, 1)',
          'rgba(245, 158, 11, 1)',
          'rgba(245, 158, 11, 1)',
          'rgba(245, 158, 11, 1)',
          'rgba(245, 158, 11, 1)',
          'rgba(245, 158, 11, 1)',
          'rgba(245, 158, 11, 1)'
        ],
        borderWidth: 0,
      barThickness: 22,
      }
    ]
      
  };

  const barDataOptions = {
    indexAxis: "x",
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      y: {
        stacked: true,
        ticks: {
          beginAtZero: true,
        },
        grid: {
          color: "#D2D1D4",
        },
        border: {
          display: false,
          dash: [4, 4],
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    width: 300,
    height: 300,
    aspectRatio: 1,
  };

  const ageBarData = {
    labels: overView?.ageAndGenderDistribution?.labels,
    datasets: overView?.ageAndGenderDistribution?.datasets
     
  };

  const ageBarDataOptions = {
    indexAxis: "x",
    plugins: {
      legend: {
        position: "bottom",
        align: "center",
        labels: {
          boxWidth: 8,
          boxHeight: 8,
          borderColor: "transparent",
          itemMargin: 5,
          font: {
            size: 12,
          },
        },
      },
      
      layout: {
        padding: {
          left: 0,
          right: 10,
          top: 0,
          bottom: 50,
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      y: {
        stacked: true,
        ticks: {
          beginAtZero: true,
        },
        grid: {
          color: "#D2D1D4",
        },
        border: {
          display: false,
          dash: [4, 4],
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    width: 300,
    height: 300,
    aspectRatio: 1,
  };

  const ExportData = () => {
    try {
      let obj = {
        sort_by: topGenresState.episodeSortBy,
    sort_order: topGenresState.episodeSortOrder,
    from_date: getFormatedDate(topGenresState?.startDate),
    to_date: getFormatedDate(topGenresState?.endDate),
    dashboard_type : dashboardState?.activeTab === "content_analytics" ? 2 : 1,
    category_id: topGenresState?.category_id,
    media_type: topGenresState?.media_type,
        
      };
      

      exportAllData(obj).then((response) => {
  
        if (response?.status === 200) {
          
          toast.success("Success");

          let downloadLink = document.createElement("a");
          let blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

          downloadLink.href = window.URL.createObjectURL(blob);
          downloadLink.setAttribute(
            "download",
            `Genre_${overView.sectionDetails?.name}_${getFormatedDateRange(date?.startDate)} - ${getFormatedDateRange(date?.endDate)}.xlsx`
          );

          document.body.appendChild(downloadLink);
          downloadLink.click();
          
        } else if (response?.data?.error_code === 403) {
          toast.error(response?.data?.errors);
        } else if (response?.status_code === 500) {
          toast.error("Something Went Wrong");
        } 
      });
    } catch (error) {
      toast.error("Something went wrong");
    }
  }

  const [date, setDate] = useState({
    startDate: dashboardState.startDate,
    endDate: dashboardState.endDate,
    key: "selection",
  });

  const handleDateRangeChange = (range) => {
    const startDate = range[0];
    const endDate = range[1];


    setDate({
      startDate,
      endDate,
    });

    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = startDate;
        state.endDate = endDate;
      })
    );
  };

  return {
    headerDetails,
    barData,
    barDataOptions,
    ageBarData,
    ageBarDataOptions,
    ExportData,
    handleDateRangeChange,
    date
  };
};

export default useGenresDetails;