import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateConfig } from "../../../store/slices/TopPrograms/topProgramsSlice";
import Style from "./topPrograms.module.scss";
import { Image } from "@wac-ui-dashboard/wac_component_library";
import Assets from "../../../assets/Assets";
import { getFormatedDate } from "../../../utils/functions/table";
import { useGetTopProgramsListDataQuery } from "../../../store/queries/topPrograms";

const useTopProgramList = () => {
  const topProgramState = useSelector((state) => state.topPrograms);
  const dashboardState = useSelector((state) => state.homeDashboard);
  const { showDetailModal } = useSelector(
    (state) => state.topPrograms
  );
  const { tableFieldsProgram } = useSelector((state) => state.global);
  const dispatch = useDispatch();

  const [searchCriteria, setSearchCriteria] = useState("");


  const [isSelectedAll, setIsSelectedAll] = useState(true);
  const [isSelectedVideos, setIsSelectedVideos] = useState(false);
  const [isSelectedPodcast, setIsSelectedPodcast] = useState(false);

  const {
    data: programDetailData = {},
    isFetching,
    isSuccess,
    refetch
  } = useGetTopProgramsListDataQuery({
    // date_period: "this_week",
    sort_by: topProgramState.sortBy,
    sort_order: topProgramState.sortOrder,
    from_date: getFormatedDate(dashboardState?.startDate),
    to_date: getFormatedDate(dashboardState?.endDate),
    list_type: "program",
    media_type: topProgramState?.media_type,
  });


  const {
    data: programDetailsData = {},
  } = useGetTopProgramsListDataQuery({
    from_date: topProgramState?.startDate ? getFormatedDate(topProgramState?.startDate) : getFormatedDate(dashboardState?.startDate),
    to_date: topProgramState?.endDate ? getFormatedDate(topProgramState?.endDate) : getFormatedDate(dashboardState?.endDate),
    list_type: topProgramState?.list_type,
    media_type: topProgramState?.media_type,
    program_id: topProgramState?.program_id
  });

  const filteredData = programDetailData?.data?.list?.filter((item) => {
    const lowerCaseCriteria = searchCriteria.toLowerCase();
    return (
      item?.name.toLowerCase().includes(lowerCaseCriteria)
    );
  });
  
  const tableHeading = {
    name: { label: "Program Name", sortable: true},
    total_play_time: { label: "Play Time", sortable: true, tooltip:  "Total time spent watching or listening to a program."}, 
    average_watch_time: { label: "Avg Watch Time", sortable: true, tooltip:  "Average time spent watching or listening to a program."}, 
    reach: { label: "Reach", sortable: true, tooltip:  "Total number of unique users who have viewed your program." }, 
    plays: { label: "Plays", sortable: true, tooltip:  "The number of times this program has been watched or listened to." }, 
    retention_rate: { label: "Retention Rate", sortable: true, tooltip:  "The percentage of the Users who return to the content after their initial visit." }, 
    new_followers: { label: "New Followers", sortable: true, tooltip:  "New followers a program gained over a given date range."}, 
    liked_count : { label: "Liked Count", sortable: true, tooltip:  "The number of likes this program has received." }, 
    unliked_count : { label: "Unliked Count", sortable: true, tooltip:  "The number of dislikes this program has received." }, 
  };

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFieldsProgram = tableHeading;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.startDate = dashboardState?.startDate;
        state.endDate = dashboardState?.endDate;
      })
    );
    // eslint-disable-next-line
  }, []);

  

  const setActiveTab = (tab) => {
    if(tab == "All") {
      setIsSelectedAll(true);
      setIsSelectedVideos(false);
      setIsSelectedPodcast(false);
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.media_type = [1,2];
        })
      );
    }
    else if(tab == "Video") {
      setIsSelectedAll(false);
      setIsSelectedVideos(true);
      setIsSelectedPodcast(false);
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.media_type = [1];
        })
      );
    }
    else {
      setIsSelectedAll(false);
      setIsSelectedVideos(false);
      setIsSelectedPodcast(true);
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.media_type = [2];
        })
      );
    }
  }

  const handleSort = (label) => {
    if (topProgramState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = topProgramState.sortOrder === 0 ? 1 : 0;
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = 0;
        })
      );
    }
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => (
        <p className="pro-mb-0">{data[feild] ? `${data[feild]}` : "-"}</p>
      ),
      name: (feild, data) => {
        return (
          <div
            className={`pro-d-flex avatar-container ${Style.avatar_container}`}
            onClick={() => {
              handleDetailClick(data['program_id'])
            }}
          >
            <div
              className={`pro-overflow-hidden pro-rounded-2 pro-align-self-center ${Style.img_wrap}`}
            >
              <Image
                src={
                  data?.thumbnail !== "" && data?.thumbnail
                    ? data?.thumbnail
                    : Assets.GENDER_IMAGE
                }
                width={16}
                height={16}
                alt={data?.name}
              />
            </div>
            <span
              className={`pro-ms-2 pro-align-self-center `}
            >
              {data?.[feild]}
            </span>
          </div>
        );
      },
      total_play_time: (feild, data) => (
        <p className="pro-mb-0">{data[feild] !== 0 ? `${data[feild]}` : 0}</p>
      ),
      reach: (feild, data) => (
        <p className="pro-mb-0">{data[feild] !== 0 ? `${data[feild]}` : 0}</p>
      ),
      total_play_time: (feild, data) => (
        <p className="pro-mb-0">{data[feild] !== 0 ? `${data[feild]}` : 0}</p>
      ),
      retention_rate: (feild, data) => (
        <p className="pro-mb-0">{data[feild] !== 0 ? `${data[feild]}` : 0}</p>
      ),
      new_followers: (feild, data) => (
        <p className="pro-mb-0">{data[feild] !== 0 ? `${data[feild]}` : 0}</p>
      ),
      liked_count: (feild, data) => (
        <p className="pro-mb-0">{data[feild] !== 0 ? `${data[feild]}` : 0}</p>
      ),
      unliked_count: (feild, data) => (
        <p className="pro-mb-0">{data[feild] !== 0 ? `${data[feild]}` : 0}</p>
      ),
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleDetailClick = (id) => {
    dispatch(
      updateConfig((state) => {
        state.activeId = id;
        state.showDetailModal = true;
        state.program_id = id;
        state.list_type = "details";
      })
    );
  };

  const closeModal = () => {
    refetch();
    dispatch(
      updateConfig((state) => {
        state.showDetailModal = false;
      })
    );
  };

  return {
    handleSort,
    getRow,
    tableFieldsProgram,
    filteredData,
    showDetailModal,
    // programDetailData,
    refetch,
    closeModal,
    setActiveTab,
    isSelectedAll,
    isSelectedVideos,
    isSelectedPodcast,
    programDetailsData,
    isFetching,
  };
};

export default useTopProgramList;
