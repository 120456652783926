import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 10,
  startDate: null,
  endDate: null,
  sortBy: "",
  sortOrder: "",
  search: "",
  showCreateModal: false,
  activeId: null,
  activeTab: "Details",
  currentFormData: [null],
  primary_radio_file: "",
  completedTabs: {
    Details: false,
    Uploads: false, 
  },
  CompletedPercentage: {
    Details: 0,
    Uploads: 0,
  },
  isEditData: false, 
  status : "",
  from_date: "",
  to_date: "",
  clearSelection: false,
};

const scheduleSlice = createSlice({
  name: "schedule",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    setStoreFormValues: (state, action) => {
      state.status= action.payload.status;
      state.from_date= action.payload.from_date;
      state.to_date= action.payload.to_date;
    },
    resetExtraFilters: (state, action) => {
      state.status= '';
      state.from_date= '';
      state.to_date= '';
    },
  },
});

export const { updateConfig, setStoreFormValues,resetExtraFilters } = scheduleSlice.actions;

export default scheduleSlice.reducer;
