import DashboardCardShimmer from "../../Global/DashboardCardShimmer";
import DashboardCard from "../DashboardCard";
import Style from "./dashboardHero.module.scss";
import React from "react";

const DashboardHero = ({
  data,
  obj,
  transactions = false,
  loading,
  listingType,
  shimmerCount,
  lineChartDataNew,
  activeTab,
}) => {    
  return (
    <>
    {/* <div className={`pro-w-100`}> */}
      {/* <div className={`row g-4 row-cols-4 row-cols-lg-5`}> */}
        {loading ? (
          <>
            {Object.keys(data || {})?.map((key) => {
              return (
                <React.Fragment key={key}>
                  <div
                    className={`col card_col ${
                      activeTab === "app_analytics" ? Style.dashboardCard_root : ""
                    }`}
                  >
                    <DashboardCard
                      icon={obj?.[key]?.icon}
                      title={data?.[key]?.label}
                      data={data?.[key]}
                      prev={obj?.[key]?.prev}
                      active={data?.[key]?.id === listingType ? true : false}
                      lineChartDataNew={lineChartDataNew}
                      activeTab={activeTab}
                    />
                  </div>
                </React.Fragment>
              );
            })}
          </>
        ) : (
          <>
          {[...Array(shimmerCount)].map((_, i) => (
            <div className={`col ${ activeTab === "app_analytics" ? Style.dashboardCard_root : ""}`} key={i}>
              <DashboardCardShimmer key={i} />
            </div>
          ))}
          </>

        )}
      {/* </div> */}
     {/* </div> */}
    </>
  );
};

export default DashboardHero;
