import { Input, Button } from "@wac-ui-dashboard/wac_component_library";
import useDetails from "./useDetails";
import { Field } from "formik";
import TimePicker from "react-time-picker";
import { useState } from "react";
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';

const Details = ({ formik, getFieldError }) => {
  const { handleCloseModal, handleNext } = useDetails({
    formik,
  });

  return (
    <div className={`pro-w-100`}>
      <Input
        label={"Name"}
        type="text"
        id="name"
        name="name"
        className={`pro-input lg ${getFieldError("name") && " error"}`}
        {...formik.getFieldProps("name")}
        error={getFieldError("name")}
        errorMessage={getFieldError("name")}
      />

      <Input
        label={"Title"}
        type="text"
        id="title"
        name="title"
        className={`pro-input lg ${getFieldError("title") && " error"}`}
        {...formik.getFieldProps("title")}
        error={getFieldError("title")}
        errorMessage={getFieldError("title")}
      />

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Description
        </label>
        <textarea
          id="description"
          rows={4}
          name="description"
          className={`pro-input lg ${
            formik.touched.description && formik.errors.description && " error"
          }`}
          {...formik.getFieldProps("description")}
        ></textarea>
        {formik.touched.description && formik.errors.description && (
          <span className="error-text">{formik.errors.description}</span>
        )}
      </div>

      <div>
        <label htmlFor="time">Time</label>
        <TimePicker
          id="time"
          name="time"
          value={formik.values.time}
          onChange={(newTime) => formik.setFieldValue('time', newTime)}
        />
      </div>
      <div>
      {formik.touched.time && formik.errors.time && (
          <span className="error-text">{formik.errors.time}</span>
        )}
      </div>
      {/* <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Status
        </label>
        <input
          type="checkbox"
          className="pro-check "
          id="status"
          name="status"
          checked={Boolean(formik.values?.status) ?? false}
          onChange={(e) => {
            formik.setFieldValue(
              "status",
              !Boolean(formik.values?.status) ?? false
            );
          }}
        />
      </div> */}

      <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end">
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => handleCloseModal()}
          type="button"
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-mx-3"}
          onClick={() => handleNext()}
        >
          Next
        </Button>
      </div>
    </div>
  );
};
export default Details;
