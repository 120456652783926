import React from "react";
import useRouteUtils from "../../utils/hooks/useRouteUtils";

const usePodcast = () => {
  const { checkIfActiveRoute } = useRouteUtils();

  const drawerMenu = [
    {
        title: "Podcast",
        link: "/podcast",
        icon: <span className="material-symbols-outlined x4"> group </span>,
        active: checkIfActiveRoute("/podcast", true),
      },
      {
        title: "Video",
        link: "/media/video",
        icon: <span className="material-symbols-outlined x4"> group </span>,
        active: checkIfActiveRoute("/media/video", true),
      },
      {
        title: "Shorts",
        link: "/media/shorts",
        icon: <span className="material-symbols-outlined x4"> group </span>,
        active: checkIfActiveRoute("/media/video", true),
      },
      {
        title: "News",
        link: "/media/news",
        icon: <span className="material-symbols-outlined x4"> group </span>,
        active: checkIfActiveRoute("/media/video", true),
      },
  ];

  return {
    drawerMenu,
  };
};

export default usePodcast;
