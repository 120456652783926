import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import MediaShortsFilterForm from "./MediaShortsFilterForm";
import {
  resetExtraFilters,
  setStoreFormValues,
} from "../../../store/slices/MediaShorts/mediashortsSlice";
import { useCategoryDataListQuery } from "../../../store/queries/mediashorts";

const useFilter = ({ setShowform, refetch }) => {
  const dispatch = useDispatch();
  const { data: basicData = {} } = useCategoryDataListQuery();
  const state = useSelector((state) => state.mediashorts);

  const validationSchema = Yup.object({
    status: Yup.string().nullable(),
    category_id: Yup.array().nullable(),
    rj_id: Yup.array().nullable(),
    video_id: Yup.array().nullable(),
    from_date: Yup.date().nullable(),
    to_date: Yup.date().nullable(),
  });

  const initialValues = {
    status: state.status,
    category_id: state.category_id,
    rj_id: state.rj_id,
    video_id: state.video_id,
    from_date: state.from_date,
    to_date: state.to_date,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const { status, category_id, rj_id, video_id, from_date, to_date } =
        values;
      if (
        status === "" &&
        category_id?.length === 0 &&
        rj_id?.length === 0 &&
        video_id?.length === 0 &&
        from_date === "" &&
        to_date === ""
      ) {
        toast.error("All fields are empty!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        setShowform(false);
      }
      let data = {
        status,
        category_id,
        rj_id,
        video_id,
        from_date,
        to_date,
      };
      if (values) {
        dispatch(setStoreFormValues(values));
      }
    },
  });

  useEffect(() => {
    // Check if status is not empty before refetching
    if (formik.values.status !== "") {
      refetch();
    }
  }, [formik.values.status, refetch]);

  const renderTabContent = () => {
    return (
      <MediaShortsFilterForm
        formik={formik}
        handleReset={handleReset}
        categoryList={basicData?.data?.category}
        rjList={basicData?.data?.rjs}
      />
    );
  };

  const handleReset = () => {
    dispatch(resetExtraFilters());
    formik.resetForm();
    setShowform(false);
  };

  return {
    renderTabContent,
  };
};

export default useFilter;
