import style from "./dashboardCardShimmer.module.scss";
const DashboardCardShimmer = () => {
  return (
    <div className="pro-w-100">
      <div className={`pro-p-5 ${style.shimmer_container}`}>
        <div className="pro-d-flex pro-justify-between pro-items-center">
          <div className="pro-d-flex pro-flex-column pro-items-start">
            <div className="pro-pb-5 pro-pt-2 pro-ps-6 pro-mt-1 pro-pe-2 shimmer"></div>
            <div className="pro-py-1 pro-pt-3 pro-px-6 shimmer pro-mt-4"></div>
          </div>
          <div className="pro-px-5 pro-py-5 shimmer pro-rounded-3"></div>
        </div>
        <div className="pro-mt-0 pro-pt-4 pro-d-flex pro-items-center">
          <div className="pro-pb-2 pro-pt-2 pro-ps-5 pro-pe-6 shimmer pro-rounded-3"></div>
          <div className="pro-py-1 pro-ps-6 pro-pe-5 pro-ms-3 shimmer"></div>
        </div>
      </div>
    </div>
  );
};

export default DashboardCardShimmer;
