import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { updateConfig } from "../../../store/slices/Dashboard/dashboardSlice";
import { useDispatch } from "react-redux";
import { useGetDashboardListDataQuery } from "../../../store/queries/dashboard";
import { useSelector } from "react-redux";
import { campaignList, clientList } from "./api";

const useDashboardList = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const activeTab = searchParams.get("activeTab");
  const dashboardState = useSelector((state) => state.dashboard);

  const [datePeriod, setDatePeriod] = useState("this_week");
  const [clientsList, setClientsList] = useState([]);
  const [campaignsList, setCampaignsList] = useState([]);
  const [clientId, setClientId] = useState("");
  const [adcampaign, setAdCampaign] = useState([]);

  useEffect(() => {
    dispatch(updateConfig((state) => (state.activeTab = activeTab)));
    dispatch(updateConfig((state) => (state.date_period = datePeriod)));
    // dispatch(updateConfig((state) => (state.ad_campaign_id = adcampaign)));
    dispatch(updateConfig((state) => {
      // Ensure adcampaign is an array
      const adCampaignIds = Array.isArray(adcampaign) ? adcampaign : [adcampaign];
      state.ad_campaign_id = adCampaignIds;
      return state;
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, datePeriod, adcampaign]);

  const navigation = [
    {
      label: "ALL",
      title: "ALL",
      link: `/dashboard?activeTab=all`,
      active: activeTab === "all",
    },
    {
      label: "VIDEO",
      title: "VIDEO",
      link: `/dashboard?activeTab=video`,
      active: activeTab === "video",
    },
    {
      label: "IMAGE",
      title: "IMAGE",
      link: `/dashboard?activeTab=image`,
      active: activeTab === "image",
    },
  ];

  const {
    data: dashboardData = {},
    isFetching,
    isLoading,
    isSuccess,
    refetch,
  } = useGetDashboardListDataQuery(
    {
      length: dashboardState.currentPageSize,
      page: dashboardState.currentPage,
      sort_by: dashboardState.sortBy,
      sort_order: dashboardState.sortOrder,
      status: dashboardState.status,
      type: dashboardState.activeTab,
      date_period: dashboardState.date_period,
      ad_campaign_id: dashboardState.ad_campaign_id,
    },
    // { skip: adcampaign ? false : true }
    {
      // Conditionally skip the query if type, date_period, or ad_campaign_id is null
      skip: !dashboardState.activeTab || !dashboardState.date_period,
    }
  );

  const totals = dashboardData?.data?.totals;

  const keysToKeep = ["reach", "impression", "clicks", "completed", "skipped"];

  const filteredTotals =
    totals &&
    Object.keys(totals)
      .filter((key) => keysToKeep.includes(key))
      .reduce((obj, key) => {
        obj[key] = totals[key];
        return obj;
      }, {});

  const hasCreatePermission = useMemo(() => {
    let permission = dashboardData?.data?.permissions?.create_permission;
    return permission ?? false;
  }, [dashboardData]);

  const hasEditPermission = useMemo(() => {
    let permission = dashboardData?.data?.permissions?.edit_permission;
    return permission ?? false;
  }, [dashboardData]);
  const hasViewPermission = useMemo(() => {
    let permission = dashboardData?.data?.permissions?.list_permission;
    return permission ?? false;
  }, [dashboardData]);
  const hasDeletePermission = useMemo(() => {
    let permission = dashboardData?.data?.permissions?.delete_permission;
    return permission ?? false;
  }, [dashboardData]);

  useEffect(() => {
    if (dashboardData?.data) {
      refetch();
    }
  }, [
    dashboardState.activeTab,
    dashboardState.date_period,
    dashboardState.ad_campaign_id,
  ]);

  let dashboardItems = {
    clicks: {
      label: "reach",
      title: "Reach",
      icon: "workspace_premium",
    },
    impression: {
      label: "impression",
      title: "Impression",
      icon: "group",
    },
    skipped: {
      label: "clicks",
      title: "Clicks",
      icon: "group",
    },
    completed: {
      label: "completed",
      title: "Completed",
      icon: "category",
    },
    reach: {
      label: "skipped",
      title: "Skipped",
      icon: "speaker_notes",
    },
  };

  const handleClientChange = (value) => {
    setClientId(value);
  };
  const handleDateChange = (value) => {
    setDatePeriod(value?._id);
  };

  useEffect(() => {
    clientList().then((response) => {
      const clientsData = response?.data?.data;
      setClientsList(response?.data?.data);
      if (clientsData?.length > 0) {
        setClientId(clientsData?.[0]);
      }
    });
  }, []);

  useEffect(() => {
    if (clientId?._id) {
      campaignList(clientId?._id).then((response) => {
        const campaignsData = response?.data?.data;

        setCampaignsList(campaignsData);
        if (campaignsData?.length > 0) {
          setAdCampaign(campaignsData?.[0]?._id);
        }
      });
    } else {
      campaignList(clientsList?.[0]?._id).then((response) => {
        const campaignsData = response?.data?.data;
        setCampaignsList(campaignsData);
        if (campaignsData?.length > 0) {
          setAdCampaign(campaignsData?.[0]?._id);
        }
      });
    }
  }, [clientId?._id, clientsList, isLoading]);

  const lineData = useMemo(() => {
    return {
      labels: dashboardData?.data?.lineChart?.labels,
      datasets:
        dashboardData?.data?.lineChart?.data?.map((item, index) => ({
          label: item.labels,
          data: item.data,
          backgroundColor: [
            "rgba(250, 107, 7, 1)",
            "rgba(250, 107, 7, 0.5)",
            "rgba(212, 221, 232, 1)",
          ],
          fill: false,
          borderColor: [
            "rgba(250, 107, 7, 1)",
            "rgba(250, 107, 7, 0.5)",
            "rgba(212, 221, 232, 1)",
          ],
          pointRadius: 10,
          pointHoverRadius: 15,
          pointBackgroundColor: "transparent",
          pointBorderColor: "transparent",
        })) || [],
    };
  }, [dashboardData?.data?.lineChart]);

  const barData = {
    labels: dashboardData?.data?.barChart?.labels,
    datasets: [
      {
        label: "",
        data: dashboardData?.data?.barChart?.data,
        backgroundColor: [
          "rgba(250, 107, 7, 1)",
          "rgba(250, 107, 7, 0.1)",
          "rgba(250, 107, 7, 0.7)",
          "rgba(250, 107, 7, 1)",
          "rgba(250, 107, 7, 0.7)",
          "rgba(250, 107, 7, 0.1)",
          "rgba(250, 107, 7, 1)",
        ],
        borderWidth: 0,
        barThickness: 22,
      },
    ],
  };

  const pieData = {
    labels: dashboardData?.data?.pieChart?.labels,
    datasets: [
      {
        data: dashboardData?.data?.pieChart?.data,
        backgroundColor: [
          "rgba(250, 107, 7, 1)",
          "rgba(250, 107, 7, 0.5)",
          "rgba(212, 221, 232, 1)",
        ],
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        position: "bottom",
        align: "center",
        labels: {
          boxWidth: 8,
          boxHeight: 8,
          borderColor: "transparent",
          itemMargin: 5,
          font: {
            size: 12,
          },
        },
      },
      layout: {
        padding: {
          left: 0,
          right: 10,
          top: 0,
          bottom: 50,
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      y: {
        ticks: {
          beginAtZero: true,
        },
        grid: {
          color: "#D2D1D4",
        },
        border: {
          display: false,
          dash: [4, 4],
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    width: 300,
    height: 300,
    aspectRatio: 1,
  };

  const barOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        barThickness: 100,
        barPercentage: 0,
        categoryPercentage: 0,
      },
      y: {
        display: false,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    width: 300,
    height: 300,
    aspectRatio: 1,
  };

  const genderGraph = {
    plugins: {
      legend: {
        display: true,
        position: "right",
        align: "end",
        labels: {
          boxWidth: 10,
          boxHeight: 10,
          font: {
            size: 10,
          },
        },
      },
      afterDraw: () => {},
    },
    scales: {
      y: {
        display: false,
      },
      x: {
        display: false,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    width: 300,
    height: 300,
    aspectRatio: 1,
  };
  const doughnutTextCenter = {
    id: 'doughnutTextCenter',
    afterDatasetsDraw(chart,args,plugins){
      const { ctx,doughnutData } = chart; 
      const centerX = chart.getDatasetMeta(0)?.data[0]?.x;
      const centerY = chart.getDatasetMeta(0)?.data[0]?.y;
      ctx.save();
      ctx.font = `bold 20px sans-serif`;
      ctx.fillStyle ='black';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle'
      ctx.fillText(dashboardData?.data?.pieChart?.total_count ? `${dashboardData?.data?.pieChart?.total_count}` : `0`, centerX, centerY);
    },
  };

  return {
    tabNavs: navigation,
    dashboardData,
    isFetching,
    isSuccess,
    dashboardItems,
    clientsList,
    campaignsList,
    clientId,
    adcampaign,
    handleClientChange,
    handleDateChange,
    setAdCampaign,
    datePeriod,
    lineData,
    activeTab,
    hasCreatePermission,
    hasEditPermission,
    hasViewPermission,
    hasDeletePermission,
    options,
    barOptions,
    genderGraph,
    barData,
    pieData,
    filteredTotals,
    dashboardState,
    doughnutTextCenter,
  };
};

export default useDashboardList;
