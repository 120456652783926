import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useRouteUtils from "../../../utils/hooks/useRouteUtils";
import { useEffect, useMemo } from "react";
import useRoutes from "../../../routes/useRoutes";
import { useDispatch } from "react-redux";
import { getMenuPermissions, updateConfig } from "../../../store/slices/Global";

const useCommonLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { menuDrawer } = useSelector((state) => state.global);
  const globalState = useSelector((state) => state.global);

  const {
    allowedUsersComponents,
    allowedRjComponents,
    allowedMediaComponents,
    allowedAdvertisementComponents,
    allowedProgrammeComponents,
    allowedCategoryComponents,
    allowedNewsCategoryComponents,
    allowedRoleComponents,
    allowedSubAdminComponents,
    allowedScheduleComponents,
    allowedPrivacyComponents,
    allowedDashboardComponents
  } = useRoutes();

  useEffect(() => {
    let sessionLogged = sessionStorage.getItem("USER_ACCESS_TOKEN");

    if (!sessionLogged) {
      sessionStorage.setItem(
        "USER_ACCESS_TOKEN",
        localStorage.getItem("USER_ACCESS_TOKEN")
      );

      dispatch(
        getMenuPermissions({ token: localStorage.getItem("USER_ACCESS_TOKEN") })
      );
    }

    //eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    if (
      globalState.isLogged === true &&
      (allowedUsersComponents?.length > 0 ||
        allowedDashboardComponents?.length > 0 ||
        allowedRjComponents?.length > 0 ||
        allowedMediaComponents?.length > 0 ||
        allowedAdvertisementComponents?.length > 0 ||
        allowedProgrammeComponents?.length > 0 ||
        allowedCategoryComponents?.length > 0 ||
        allowedNewsCategoryComponents?.length > 0 ||
        allowedRoleComponents?.length > 0 ||
        allowedSubAdminComponents > 0 ||
        allowedScheduleComponents > 0 ||
        allowedPrivacyComponents > 0)
    ) {
      // setTimeout(() => {
      dispatch(
        updateConfig((state) => {
          state.isLogged = "idle";
        })
      );
      // }, 1800);

      if (allowedDashboardComponents?.length > 0) {
        navigate(
          `/${allowedDashboardComponents?.map((item) => item?.path)?.[0]}`
        );
      }
      else if (allowedUsersComponents?.length > 0) {
        navigate(`/${allowedUsersComponents?.map((item) => item?.path)?.[0]}`);
      } 
      else if (allowedRjComponents?.length > 0) {
        navigate(`/${allowedRjComponents?.map((item) => item?.path)?.[0]}`);
      } else if (allowedMediaComponents?.length > 0) {
        navigate(`/${allowedMediaComponents?.map((item) => item?.path)?.[0]}`);
      } else if (allowedAdvertisementComponents?.length > 0) {
        navigate(
          `/${allowedAdvertisementComponents?.map((item) => item?.path)?.[0]}`
        );
      } else if (allowedProgrammeComponents?.length > 0) {
        navigate(
          `/${allowedProgrammeComponents?.map((item) => item?.path)?.[0]}`
        );
      } else if (allowedCategoryComponents?.length > 0) {
        navigate(
          `/${allowedCategoryComponents?.map((item) => item?.path)?.[0]}`
        );
      } else if (allowedNewsCategoryComponents?.length > 0) {
        navigate(
          `/${allowedNewsCategoryComponents?.map((item) => item?.path)?.[0]}`
        );
      } else if (allowedSubAdminComponents?.length > 0) {
        navigate(
          `/${allowedSubAdminComponents?.map((item) => item?.path)?.[0]}`
        );
      } else if (allowedScheduleComponents?.length > 0) {
        navigate(
          `/${allowedScheduleComponents?.map((item) => item?.path)?.[0]}`
        );
      } else if (allowedPrivacyComponents?.length > 0) {
        navigate(
          `/${allowedPrivacyComponents?.map((item) => item?.path)?.[0]}`
        );
      } else {
        navigate(`${allowedRoleComponents?.map((item) => item?.path)?.[0]}`);
      }
    }

    //eslint-disable-next-line
  }, [
    allowedUsersComponents,
    allowedRjComponents,
    allowedMediaComponents,
    allowedAdvertisementComponents,
    allowedProgrammeComponents,
    allowedCategoryComponents,
    allowedNewsCategoryComponents,
    allowedRoleComponents,
    allowedSubAdminComponents,
    allowedScheduleComponents,
    allowedPrivacyComponents,
    allowedDashboardComponents
  ]);

  const { checkIfActiveRoute } = useRouteUtils();
  const navigations = [
    {
      label: "Dashboard",
      link: `/${allowedDashboardComponents?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute("/home-dashboard"),
    },
    {
      label: "User",
      link: `/${allowedUsersComponents?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute("/users"),
    },
    {
      label: "Media",
      link: `/${allowedMediaComponents?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute("/media"),
    },
    {
      label: "Advertisement",
      link: `/${
        allowedAdvertisementComponents?.map((item) => item?.path)?.[0]
      }`,
      isButton: false,
      active: checkIfActiveRoute("/dashboard"),
    },
    {
      label: "RJ",
      link: `/${allowedRjComponents?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute("/rj"),
    },
    {
      label: "Program",
      link: `/${allowedProgrammeComponents?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute("/programme"),
    },
    {
      label: "Category",
      link: `/${allowedCategoryComponents?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute("/category"),
    },
    {
      label: "News Category",
      link: `/${allowedNewsCategoryComponents?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute("/news-category"),
    },
    {
      label: "Sub Admin",
      link: `/${allowedSubAdminComponents?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute("/subadmin"),
    },
    {
      label: "Schedule",
      link: `/${allowedScheduleComponents?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute("/schedule"),
    },
    {
      label: "Role",
      link: `/${allowedRoleComponents?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute("/role"),
    },
    {
      label: "Settings",
      link: `/${allowedPrivacyComponents?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute("/settings"),
    },
  ];

  const handleGoBack = () => {
    navigate(menuDrawer?.backTo);
  };

  const topMenuPermission = useMemo(() => {
    let menus = globalState.dashboard_permissions?.menu_permissions?.flatMap?.(
      (menu) => [menu?.menu_name]
    );

    if (!menus) {
      menus = [];
    }
    else {
      menus = [...menus, "Dashboard"];

    }
    
    return navigations
      .filter((menu) => menus?.includes?.(menu?.label))
      .map((menu) => {
        return {
          ...menu,
        };
      });
    //eslint-disable-next-line
  }, [globalState.dashboard_permissions, location.pathname]);


  return {
    navigations: topMenuPermission,
    menuDrawer,
    globalState,
    handleGoBack,
  };
};

export default useCommonLayout;
