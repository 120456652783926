import { Outlet } from "react-router-dom";
import CommonLayout from "../../pages/Layouts/CommonLayout";
import useMediaShorts from "./useMediaShorts";


const MediaShorts = () => {
  const { drawerMenu } = useMediaShorts();

  return (
    <CommonLayout drawerMenu={drawerMenu}>
      <Outlet />
    </CommonLayout>
  );
};

export default MediaShorts;