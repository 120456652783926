import { forwardRef, useEffect, useState } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const ImageCrop = forwardRef(
  ({ image, crop, setCrop, aspectRatio, setFormikmage }, ref) => {
    const [aspect, setAspect] = useState(1);

    function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
      return centerCrop(
        makeAspectCrop(
          {
            unit: "px",
            width: crop?.width,
            height: crop?.height,
          },
          aspect,
          mediaWidth,
          mediaHeight
        ),
        mediaWidth,
        mediaHeight
      );
    }

    // function onSelectFile(e) {
    //   if (e.target.files && e.target.files.length > 0) {
    //     setCrop(undefined) // Makes crop preview update between images.
    //     const reader = new FileReader()
    //     reader.addEventListener('load', () =>
    //     setFormikmage(reader.result?.toString() || ''),
    //     )
    //     reader.readAsDataURL(e.target.files[0])
    //   }
    // }

    function onImageLoad(e) {
      if (crop?.aspect) {
        const { width, height } = e.currentTarget;
        setCrop(centerAspectCrop(width, height, crop?.aspect));
      }
    }

    useEffect(() => {
      if (crop?.aspect) {
        setAspect(crop?.aspect);
      }
    }, [crop?.aspect]);

    return (
      <>
        <ReactCrop
          crop={crop}
          onChange={(c) => {
            setCrop(c);
          }}
          aspect={aspect}
        >
          <img
            ref={ref}
            src={image}
            alt="crop"
            crossOrigin="anonymous"
            onLoad={onImageLoad}
          />
        </ReactCrop>
      </>
    );
  }
);

export default ImageCrop;
