import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { login } from "../api";
import useValidations from "../../../utils/hooks/useValidations";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../store/slices/Global";


const useAuth = (emailRef) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { validateEmail } = useValidations();

  const [loginStatus, setLoginStatus] = useState("idle");
  const [loginInfo, setLoginInfo] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  const [forgotPasswordStatus, setForgotPasswordStatus] = useState("idle");

  useEffect(() => {
    if (loginStatus === "success") {
      dispatch(
        updateConfig((state) => {
          state.isLogged = true;
        })
      );
      navigate(
        `/`
      );
      // window.location.reload()
      // setTimeout(() => {
      //    window.location.reload()
      // }, 100);
    } else if (loginStatus === "failed") {
      let errorFields = Object.keys(loginInfo);
      errorFields.forEach((field) => {
        formik.setFieldError(field, loginInfo[field]);
      });
    }
    // eslint-disable-next-line
  }, [loginStatus]);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate: (values) => {
      let errors = {};
      const containsSpaces = /\s/.test(values.email);
      const isValidPhoneNumber = /^[0-9]{1,15}$/.test(values.email);

      if (!values.email) {
        errors.email = "*Username Required";
      }
      if (!values.password) {
        errors.password = "*Password Required";
      }
      if (containsSpaces) {
        errors.email = "*Space not allowed";
      } else if (!validateEmail(values.email) && !isValidPhoneNumber) {
        errors.email = "*Enter a valid Username";
      }
      return errors;
    },
    onSubmit: (values) => {
      emailRef.current = values.email;

      setLoginStatus("pending");
      let obj = {
        user_name: values.email,
        password: values.password,
      };

      login(obj).then((response) => {
        if (response?.data?.status_code === 200) {
          setLoginInfo(response?.data?.data);

          localStorage.setItem(
            "USER_ACCESS_TOKEN",
            response?.data?.data?.token
          );
          setLoginStatus("success");
          toast.success("You are logged in");
        } else if (response?.data?.status_code === 422) {
          setLoginStatus("failed");
          toast.error("Invalid Credentials");
          setLoginInfo({ password: response?.data?.message });
        } else if (response?.status_code === 400) {
          setLoginInfo({ email: response?.message?.email?.[0] });
          setLoginStatus("failed");
          toast.error("Invalid Credentials");
        } else if (
          response?.status_code === 403 ||
          response?.error_code === 403
        ) {
          setLoginInfo({
            password: response?.message || response?.errors?.password,
          });
          setLoginStatus("failed");
          // toast.error("Invalid Credentials");
        } else if (response?.error_code === 403) {
          setLoginInfo({ email: response?.message });
          setLoginStatus("failed");
          toast.error("Invalid Credentials");
        } else {
          setLoginStatus("An error occured");
          toast.error("An error occured");
        }
      });
    },
  });

  const handleForgotPassword = () => {
    navigate("forgot-password");
  };

  return {
    formik,
    loginStatus,
    forgotPasswordStatus,
    setForgotPasswordStatus,
    handleForgotPassword,
    showPassword,
    setShowPassword,
  };
};

export default useAuth;
