import React, { useState } from 'react';
import Style from '../BannerSettings.module.scss';

const CustomNavGroup = ({ navigations, onClick }) => {
  const [activeItem, setActiveItem] = useState(1);

  const handleClick = (item) => {
    setActiveItem(item.id);
    onClick(item)
  };

  return (
    <ul className={Style.nav_list}>
      {navigations?.map((item, index) => (
        <li key={index}>
          <button onClick={() => { handleClick(item); }} className={activeItem === item.id ? Style.active : ''}>
            {item.label}
          </button>
        </li>
      ))}
    </ul>
  );
};

export default CustomNavGroup;
