import { Input, Button } from "@wac-ui-dashboard/wac_component_library";
import useUpdateUser from "./useUpdateUser";
import { CountryFlag } from "../../../CountryFlag";
import Select from "react-select";
import { format } from "date-fns";

const UpdateUser = ({ activeId, refetch }) => {
  const { handleCloseModal, formik, errors, isLoading, dateStr, minDate } =
    useUpdateUser({
      activeId,
      refetch,
    });

  return (
    <div className={`pro-w-100`}>
      <Input
        label={"Name*"}
        type="text"
        id="name"
        name="name"
        className={`pro-input lg ${
          formik.errors.name && formik.touched.name && " error"
        }`}
        {...formik.getFieldProps("name")}
        onKeyDown={(e) => {
          const regex = /^[A-Za-z\s]*$/;
          if (!regex.test(e.key)) {
            e.preventDefault();
          }
        }}
        maxLength={32}
        error={formik.touched.name && formik.errors.name}
        errorMessage={formik.errors.name}
      />

      <div className="input-wrap pro-mb-4 ">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Country Code*
        </label>
        <Select
          id="country_code"
          options={CountryFlag}
          getOptionLabel={(option) => `${option.dial_code}`}
          getOptionValue={(option) => `${option.dial_code}`}
          className={`pro-input lg multi-select ${
            formik.touched.country_code &&
            formik.errors.country_code &&
            " error"
          }`}
          value={CountryFlag?.filter(
            (p) =>
              p.dial_code?.toString() === formik.values.country_code?.toString()
          )}
          classNamePrefix="pro-input"
          onBlur={() => formik.handleBlur("country_code")}
          onChange={(value) =>
            formik.setFieldValue("country_code", value?.dial_code)
          }
        ></Select>

        {formik.touched.country_code && formik.errors.country_code && (
          <span className="error-text">{formik.errors.country_code}</span>
        )}
      </div>
      <Input
        label={"Mobile Number*"}
        type="text"
        id="mobile"
        name="mobile"
        className={`pro-input lg ${
          formik.errors.mobile && formik.touched.mobile && " error"
        }`}
        {...formik.getFieldProps("mobile")}
        error={formik.touched.mobile && formik.errors.mobile}
        errorMessage={formik.errors.mobile}
        onKeyDown={(e) => {
          // Allow backspace (keyCode 8)
          if (e.keyCode === 8) {
            return;
          }

          const regex = /^[0-9]*$/; // Only allows digits
          if (!regex.test(e.key)) {
            e.preventDefault();
          }
        }}
        maxLength={15}
      />

      <Input
        label={"Email"}
        type="text"
        id="email"
        name="email"
        className={`pro-input lg multi-select ${
          formik.touched.email && formik.errors.email && " error"
        }`}
        {...formik.getFieldProps("email")}
        error={formik.touched.email && formik.errors.email}
        errorMessage={formik.errors.email}
      />

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Date of Birth*
        </label>
        <Input
          value={formik.values.dob === "" ? "dd-MM-yyyy" : dateStr}
          type="date"
          id="dob"
          name="dob"
          max={minDate}
          className={`pro-input lg ${
            formik.errors.dob && formik.touched.dob && " error"
          }`}
          onChange={(e) =>
            formik.setFieldValue(
              "dob",
              format(new Date(e.target.value), "dd-MM-yyyy")
            )
          }
          onBlur={formik.handleBlur("dob")}
          error={formik.touched.dob && formik.errors.dob}
          errorMessage={formik.errors.dob}
        />
      </div>

      <div className="input-wrap pro-mb-4 pro-flex-row pro-items-center">
        <div className="form-check form-switch pro-mb-0 ">
          <input
            type="checkbox"
            className="form-check-input cursor-pointer"
            id="status"
            name="status"
            checked={Boolean(formik.values?.status) ?? false}
            onChange={(e) => {
              formik.setFieldValue(
                "status",
                !Boolean(formik.values?.status) ?? false
              );
            }}
          />
        </div>
        <label htmlFor="" className="pro-font-sm pro-fw-medium pro-lh-base">
          Status
        </label>
      </div>

      <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end">
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => handleCloseModal()}
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-mx-3"}
          type="submit"
          disabled={isLoading}
          onClick={formik.handleSubmit}
        >
          Update
        </Button>
      </div>
      {errors && <span className="error-text">{errors}</span>}
    </div>
  );
};
export default UpdateUser;
