import React, { forwardRef } from "react";
// import DefaultStyle from "./Table.module.scss";
import PropTypes from "prop-types";
// import { mergeClasses } from "../../Logic/mergeClasses";
import useTable from "./useTable";
import TableShimmer from "./TableShimmer";
import { Tooltip } from "react-tooltip";


export const Table = forwardRef(
  (
    {
      SortIcon,
      editText,
      deleteText,
      assignText,
      editIcon,
      deleteIcon,
      assignIcon,
      fields,
      data,
      showCheckBox,
      loading,
      perpage,
      assignable = true,
      editable = true,
      deletable = true,
      multiSelect = true,
      clear,
      uniqueID,
      propStyle,
      handleEdit,
      handleDelete,
      handleAssign,
      handleSort,
      handleSelect = () => {},
      getRow,
      isSelectBox,
      extraClasssName,
      disabledKey = "disable",
    },
    ref
  ) => {
    // const Style = mergeClasses(DefaultStyle, propStyle);
    const {
      tableFields,
      checkedRowCount,
      checked,
      getKeyByValue,
      handleOnChange,
      handleCheckAll,
    } = useTable({ fields, data, uniqueID, multiSelect, clear, handleSelect });

    //FIXME handle loading state for the table
    return (
      <div className="table-vscroll-wrap"
        // className={`pro-pt-3 ${Style.proTableWrp} ${
        //   isSelectBox ? Style.selectBox_table : ""
        // }  ${extraClasssName ? extraClasssName : ""}`}
      >
        <div
          // className={`${Style.tableActions} ${
          //   checkedRowCount ? Style.actionShow : ""
          // }`}
        >
          <div className="pro-btn-group pro-pb-2">
            {multiSelect ? (
              <button className="pro-btn pro-btn-secondary">
                {checkedRowCount} Selected
              </button>
            ) : (
              <></>
            )}
            {editable && (
              <button
                className="pro-btn pro-btn-secondary"
                onClick={() => handleEdit(checked)}
              >
                {editIcon}
                {<span>{editText || "Edit"}</span>}
              </button>
            )}
            {deletable && (
              <button
                className="pro-btn pro-btn-secondary"
                onClick={() => handleDelete(checked)}
              >
                {deleteIcon}
                {<span>{deleteText || "Delete"}</span>}
              </button>
            )}
            {assignable && (
              <button
                className="pro-btn pro-btn-secondary"
                onClick={() => handleAssign(checked)}
              >
                {assignIcon}
                {<span>{assignText || "Assign"}</span>}
              </button>
            )}
          </div>
        </div>
        {!loading ? (
          <table ref={ref} className="pro-table custom-pro-table">
            <thead>
              <tr>
                {showCheckBox ? (
                  <th className="checkbox-cell">
                    <div className="pro-check-box">
                      {multiSelect && (
                        <input
                          type="checkbox"
                          className="pro-check"
                          onChange={handleCheckAll}
                          checked={
                            checked?.length == data?.length && data?.length != 0
                          }
                        />
                      )}
                    </div>
                  </th>
                ) : (
                  ""
                )}
                {tableFields?.map((title, index) => {
                  if (!title?.hidden)
                    return (
                      <th
                        key={index}
                        // className={title?.sortable ? Style.sortable : ""}
                        data-tooltip-id={`tooltip-${index}`}
                        //data-tooltip-content={title?.tooltip || ""}
                        data-tooltip-html={title?.tooltip ? `<h6>${title?.label}</h6><p>${title?.tooltip || ""}</p>` : ``}
                      >
                        {title?.label}
                        {title?.sortable
                          ? SortIcon && (
                              <span
                                onClick={() =>
                                  handleSort(
                                    getKeyByValue(fields, title?.label)
                                  )
                                }
                              >
                                {SortIcon}
                              </span>
                            )
                          : ""}
                          <Tooltip id={`tooltip-${index}`} />

                      </th>
                    );
                })}
              </tr>
            </thead>
            <tbody>
              {data.length > 0 ? (
                data?.map((d, i) => {
                  return (
                    <tr key={i}>
                      {showCheckBox && (
                        <td className="checkbox-cell">
                          <div className="pro-check-box">
                            {!d?.[disabledKey] && (
                              <input
                                type="checkbox"
                                className="pro-check"
                                checked={checked?.includes(d?.[uniqueID])}
                                onChange={() => handleOnChange(d?.[uniqueID])}
                              />
                            )}
                          </div>
                        </td>
                      )}
                      {tableFields?.map((field, colIndex) => {
                        if (!field?.hidden)
                          return (
                            <td key={colIndex}>
                              {getRow(getKeyByValue(fields, field?.label), d)}
                            </td>
                          );
                      })}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    // className={Style.empty}
                    colSpan={
                      showCheckBox ? tableFields.length + 1 : tableFields.length
                    }
                  >
                    <p className={`pro-fw-bolder pro-mb-0`}>
                      <span className="material-symbols-outlined pro-pe-2">
                        {" "}
                        grid_off{" "}
                      </span>
                      <span>Table is empty!</span>
                    </p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        ) : (
          <TableShimmer perpage={perpage} fields={tableFields.length} />
        )}
      </div>
    );
  }
);

Table.propTypes = {
  data: PropTypes.array,
  /**
   * Determines whether checkbox is shown or not
   */
  showCheckBox: PropTypes.bool,
  /**
   * returns "_id"s of the selected rows as first argument
   */
  handleEdit: PropTypes.func,
  /**
   * returns "_id"s of the selected rows as first argument
   */
  handleAssign: PropTypes.func,
  /**
   * returns "_id"s of the selected rows as first argument
   */
  handleDelete: PropTypes.func,
  /**
   * getRow function takes two arguments (field, data) and returns the html for that particular column
   * field should be the key name for the value in the data object
   * @example
   *   let rows = {
   * common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
   *  };
   *  const getRow = (feild, data) => {
   *    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
   *  };
   */
  getRow: PropTypes.func.isRequired,
};
