import { Pagination, Table } from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import useAdPlacementList from "./useAdPlacementList";

const AdPlacementList = ({ dashboardData }) => {
  const {
    adPlacementState,
    currentPage,
    paginationOptions,
    isFetching,
    handlePagination,
    handlePageSize,
    handleSort,
    getRow,
    tableFields,
    listData,
    handleSearchCriteriaChange,
    filteredData,
    searchCriteria,
  } = useAdPlacementList({ dashboardData });

  return (
    <>
      <div className="pro-pb-3 pro-pt-5 pro-mt-2">
        <div className="pro-d-flex pro-gap-5 pro-mb-2 pro-items-center">
          <h4 className="pro-mb-0 h4 pro-ttl">Ad Placements</h4>
          <span className="position-relative ">
            <input
              type="text"
              placeholder="Search"
              className="pro-input lg icon-r"
              value={searchCriteria}
              onChange={(e) => handleSearchCriteriaChange(e.target.value)}
            />
            <span className="pro-icon">
              <span className="material-symbols-outlined ">search</span>
            </span>
          </span>
        </div>

        <Table
          data={filteredData || []}
          uniqueID={"_id"}
          fields={tableFields}
          SortIcon={<FaSort />}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          perpage={adPlacementState.currentPageSize}
          assignable={false}
          multiSelect={false}
          clear={adPlacementState.clearSelection}
        />
        {listData?.data?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={1}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
      </div>
    </>
  );
};

export default AdPlacementList;
