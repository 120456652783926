import React from "react";
import Select from "react-select";
import { Button } from "@wac-ui-dashboard/wac_component_library";

const ProgrammeFilterForm = ({
  formik,
  handleReset,
}) => {
  let statusList = [
    {
      id: "0",
      label: "Inactive",
    },
    { id: "1", label: "Active" },
  ];
  
  return (
    <div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Status
        </label>
        <Select
          id="status"
          placeholder={"Select"}
          className={"pro-input lg"}
          classNamePrefix="pro-input"
          name="status"
          options={statusList}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.label}
          value={statusList?.filter((m) => formik.values.status === m.id)}
          onChange={(value) => formik.setFieldValue("status", value?.id)}
        />
      </div>

      <div
        className={`col-12 pro-d-flex pro-justify-end ${"offcanvas-footer-sticky-btn"}`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => handleReset()}
        >
          Reset
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};

export default ProgrammeFilterForm;
