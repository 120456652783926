import { getAxiosInstance } from "../../../api";

  export const getResourceTypes = async () => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get('admin/settings/banner/resource-types');
      return response;
    } catch (error) {
      return error.response.data;
    }
  };
  export const getDataByResourceType = async (id,searchTerm) => {
    searchTerm = searchTerm || "";
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`admin/settings/banner/resource-list?type=${id}&search=${searchTerm}`);
      return response;
    } catch (error) {
      return error.response.data;
    }
  };
  export const createNew = async (data) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("admin/settings/banner/save-single-resource",data);
      return response;
    } catch (error) {
      return error.response.data;
    }
  };
  export const tagsListing = async () => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("admin/settings/banner/tags");
      return response;
    } catch (error) {
      return error.response.data;
    }
  };
  export const getDraggedItemById = async (id) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`admin/settings/banner/edit-single-resource?_id=${id}`);
      return response;
    } catch (error) {
      return error.response.data;
    }
  };
  export const resourcePositionList = async (searchTerm) => {
    searchTerm = searchTerm || "";
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`admin/settings/banner/resource-position-list?search=${searchTerm}`);
      return response;
    } catch (error) {
      return error.response.data;
    }
  };
  export const publishBanner = async (data) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("admin/settings/banner/update-resource-position",data);
      return response;
    } catch (error) {
      return error.response.data;
    }
  };