import {
  HeadingGroup,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
  ConfirmationBox,
  ModalLayout,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import useMediaShortsList from "./useMediaShortsList";
import { ImageCard } from "../../Global/ImageCard";
import UpdateMediaShorts from "../../MediaShorts/UpdateMediaShorts";
import Player from "../../Global/Player";
import Filter from "../Filter";
import Style from "../mediashorts.module.scss";

const MediaShortsList = () => {
  const {
    mediashorts,
    mediashortsState,
    activeId,
    currentPage,
    activeFilter,
    paginationOptions,
    isFetching,
    isLoading,
    showDeleteModal,
    imageData,
    showImageModal,
    basicThumbnailData,
    showBasicThumbnailModal,
    videoData,
    showVideoModal,
    isEditData,
    filterShow,
    dataFileIndex,
    setDataFileIndex,
    dataTitle,
    dataId,
    setFilterShow,
    handleEditModal,
    handleCreateClick,
    closeDeleteModal,
    handlePagination,
    handlePageSize,
    refetch,
    handleSort,
    handleSearch,
    handleMediashortsDataEditClick,
    closeModal,
    deleteMediashortsDataClick,
    getRow,
    deleteMediashorts,
    tableFields,
    hasCreatePermission,
    hasEditPermission,
    hasViewPermission,
    hasDeletePermission,
  } = useMediaShortsList();

  return (
    
        <>
          <HeadingGroup
            title={"Shorts"}
            buttonTitle={hasCreatePermission ?? false ? "Add New" : ""}
             extraClassName={`pro-mb-4`}
            handleClick={handleCreateClick}
          />
            <div className="col-12 pro-pb-6">
              <SearchFilters
                showDateRange={false}
                activeFilter={activeFilter}
                onSearchInput={handleSearch}
                loading={isLoading}
                showActions={false}
                handleActionClick={handleEditModal}
                searchInputProps={{ value: mediashortsState?.search }}
                SearchIcon={
                  <span className="material-symbols-outlined"> search </span>
                }
                extraFilters={
                <div className="col-auto">
                  <button
                  className={`pro-btn pro-btn-outline pro-items-center pro-h-100`}
                  onClick={() => setFilterShow(true)}
                  >
                    <span className="material-symbols-outlined">tune</span>
                    <span>Filter</span>
                  </button>
                </div>
                }
              />
            <Table
              data={mediashorts?.data?.original?.data || []}
              uniqueID={"_id"}
              fields={tableFields}
              showCheckBox={
                hasEditPermission || hasDeletePermission ? true : false
              }
              SortIcon={<FaSort />}
              handleEdit={handleMediashortsDataEditClick}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              handleDelete={deleteMediashortsDataClick}
              deleteIcon={
                <span className="material-symbols-outlined">delete</span>
              }
              handleSort={handleSort}
              getRow={getRow}
              loading={isFetching}
              perpage={mediashortsState.currentPageSize}
              assignable={false}
              multiSelect={false}
              clear={mediashortsState.clearSelection}
              editable={hasEditPermission ? true : false}
              deletable={hasDeletePermission ? true : false}
            />
            {mediashorts?.data?.original?.data?.length > 0 && (
              <Pagination
                currentPage={currentPage}
                totalPageCount={Math.ceil(
                  mediashorts?.data?.original?.recordsTotal /
                    mediashortsState.currentPageSize
                )}
                onPageChange={handlePagination}
                options={paginationOptions}
                onActionChange={handlePageSize}
                center
              />
            )}
            </div>
            <ModalLayout
              centered
              show={showDeleteModal}
              handleClose={closeDeleteModal}
            >
              <div className="pro-m-5">
                <ConfirmationBox
                  title={`Are you sure you want to delete?`}
                  isRight={true}
                  cancelText={`Cancel`}
                  submitText={`Delete`}
                  cancelAction={closeDeleteModal}
                  submitAction={deleteMediashorts}
                >
                  ConfirmationBox
                </ConfirmationBox>
              </div>
            </ModalLayout>

            <OffCanvasLayout
              show={mediashortsState.showCreateModal}
              handleClose={closeModal}
              title={`${
                isEditData ? "Update Shorts Details" : "Create Shorts"
              }`}
              closeIcon={
                <span className="material-symbols-outlined"> close </span>
              }
              backdrop={"static"}
            >
              <UpdateMediaShorts
                activeId={activeId}
                refetch={refetch}
                isEditData={isEditData}
              />
            </OffCanvasLayout>

            <OffCanvasLayout
              centered={true}
              show={filterShow}
              handleClose={() => {
                setFilterShow(false);
              }}
              title={"Filter"}
              closeIcon={
                <span className="material-symbols-outlined">close</span>
              }
            >
              <Filter setShowform={setFilterShow} refetch={refetch}/>
            </OffCanvasLayout>

            <ModalLayout show={showImageModal} handleClose={closeModal}>
              <ImageCard data={imageData} handleClose={closeModal} />
            </ModalLayout>
            <ModalLayout show={showBasicThumbnailModal} handleClose={closeModal}>
              <ImageCard data={basicThumbnailData} handleClose={closeModal} />
            </ModalLayout>
            <ModalLayout
              show={showVideoModal}
              handleClose={closeModal}
              centered
              propStyle={{ root: Style.modal_root }}
            >
              <Player
                data={videoData}
                dataFileIndex={dataFileIndex}
                setDataFileIndex={setDataFileIndex}
                title={dataTitle}
                dataList={mediashorts?.data?.original?.data}
                dataId={dataId}
                isShortVideo
                handleClose={closeModal}
              />
            </ModalLayout>
        </>
      
  );
};

export default MediaShortsList;
