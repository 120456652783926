import { useSelector, useDispatch } from "react-redux";
import {
  useGetPrivacyPolicyListDataQuery,
} from "../../../store/queries/privacy";
import {
  updateConfig,
  getPrivacyPolicyEditData,
} from "../../../store/slices/Privacy/privacySlice";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { useEffect } from "react";
import parse from "html-react-parser"

const usePrivacyList = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const dispatch = useDispatch();
  const privacyState = useSelector((state) => state.privacy);
  const { tableFields } = useSelector((state) => state.global);
  const {
    data: privacyPolicyList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetPrivacyPolicyListDataQuery({
    page_size: privacyState?.currentPageSize,
    page: privacyState?.currentPage,
    sort: privacyState?.sortBy,
    sort_order: privacyState?.sortOrder,
    search: privacyState?.search,
  });
 

  const tableHeading = {
    name: { label: "Name", sortable: true },
    value: { label: "Value", sortable: true },
  };

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = tableHeading;
      })
    );
    // eslint-disable-next-line
  }, []);
 
  useEffect(() => {
    if (privacyState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [privacyState.clearSelection]);
  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleSort = (label) => {
    if (privacyState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = privacyState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };


  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      value: (field, data) => <p className="pro-mb-0">{parse(parse(data[field]))}</p>,
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = !state.showCreateModal;
      })
    );
  };
  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.selectedId = e?.[0];
        state.isEdit = true;
        state.showEditNumberTypeModal = false;
      })
    );
     dispatch(getPrivacyPolicyEditData(e?.[0]));
  };
  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.isEdit = false;
        state.inputTypeData=""
      })
    );
  };
  const handlePrivacyDataEditClick = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showEditNumberTypeModal = true;
        state.selectedId = e?.[0];
        state.showCreateModal = false;
      })
    );
    //  dispatch(getPrivacyPolicyEditData(e?.[0]));
  };
  const closeModal = () => {
    refetch();
    dispatch(
      updateConfig((state) => {
        state.showEditNumberTypeModal = false;
        state.inputTypeData=""
      })
    );
  };

  return {
    privacyState,
    isLoading,
    isFetching,
    tableFields,
    privacyPolicyList,
    paginationOptions,
    handleCloseModal,
    closeModal,
    handleEditAction,
    handlePrivacyDataEditClick,
    handleCreateClick,
    handlePagination,
    handleClearClick,
    handlePageSize,
    handleSearch,
    handleSort,
    refetch,
    getRow,
    tableFields,
  };
};

export default usePrivacyList;
