import React, { useEffect, useState } from "react";
import { useFormik, FieldArray } from "formik";
import * as Yup from "yup";

import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import MediaVideoFilterForm from "./MediaVideoFilterForm";
import { useGetMediaVideoListDataQuery } from "../../../store/queries/mediavideo";
import {
  resetExtraFilters,
  setStoreFormValues,
} from "../../../store/slices/MediaVideo/mediavideoSlice";
import { categoryDataList, programDataList } from "../UpdateMediaVideo/api";

const useFilter = ({ setShowform, refetch }) => {
  const dispatch = useDispatch();
  const [categoryList, setCategoryList] = useState([]);
  const [rjList, setRjList] = useState([]);
  const [videoType, setVideoType] = useState([]);
  const [programList, setProgramList] = useState([]);
  const state = useSelector((state) => state.mediavideo);
  const { data: FilterData = {}, isFetching } = useGetMediaVideoListDataQuery(
    {}
  );

  const validationSchema = Yup.object({
    status: Yup.string().nullable(),
    category_id: Yup.array().nullable(),
    rj_id: Yup.array().nullable(),
    type: Yup.string().nullable(),
    programe_id: Yup.array().nullable(),
    from_date: Yup.date().nullable(),
    to_date: Yup.date().nullable(),
  });

  const initialValues = {
    status: state.status,
    category_id: state.category_id,
    rj_id: state.rj_id,
    type: state.type,
    programe_id: state.programe_id,
    from_date: state.from_date,
    to_date: state.to_date,
  };

  useEffect(() => {
    categoryDataList().then((response) => {
      setCategoryList(response?.data?.data?.category);
      setRjList(response?.data?.data?.rjs);
      setVideoType(response?.data?.data?.video_type);
    });
    // programDataList().then((response) => {
    //   setProgramList(response?.data?.data);
    // }); 
  }, []);

  // const [queryParams, setQueryParams] = useState({
  //   type: "",
  // });

  // useEffect(() => {
  //   if(queryParams?.type !== '') {
  //     programDataList().then((response) => {
  //       setProgramList(response?.data?.data);
  //     });
  //   }  
  // }, [queryParams]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const {
        status,
        category_id,
        rj_id,
        type,
        programe_id,
        from_date,
        to_date,
      } = values;
      if (
        status === "" &&
        category_id?.length === 0 &&
        rj_id?.length === 0 &&
        type === "" &&
        programe_id?.length === 0 &&
        from_date === "" &&
        to_date === ""
      ) {
        toast.error("All fields are empty!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        setShowform(false);
      }
      let data = {
        status,
        category_id,
        rj_id,
        type,
        programe_id,
        from_date,
        to_date,
      };
      if (values) {
        dispatch(setStoreFormValues(values));
      }
    },
  });

  useEffect(() => {
    // Check if status is not empty before refetching
    if (formik.values.status !== "") {
      refetch();
    }
  }, [formik.values.status, refetch]);

  useEffect(() => {
    if (state?.type !== '') {
      setTypeValue(state?.type);
    }
  }, [state?.type]);

  const renderTabContent = () => {
    return (
      <MediaVideoFilterForm
        formik={formik}
        handleReset={handleReset}
        categoryList={categoryList}
        rjList={rjList}
        videoType={videoType}
        programList={programList}
        setTypeValue={setTypeValue}
      />
    );
  };

  const handleReset = () => {
    dispatch(resetExtraFilters());
    formik.resetForm();
    setShowform(false);
  };

  const setTypeValue = (value) => {
    formik.setFieldValue("type", value?._id ?? value)
    programDataList().then((response) => {
      setProgramList(response?.data?.data);
    });
    
  }

  return {
    renderTabContent,
  };
};

export default useFilter;
