import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/MediaVideo/mediavideoSlice";
import { useState } from "react";

const useDetails = ({ uploadState,setShowAlertModal }) => {
  const dispatch = useDispatch();
  

  const handleCloseModal = () => {
    if (uploadState && uploadState?.isUploading) {
      setShowAlertModal(true);
    } else {
      dispatch(
        updateConfig((state) => {
          state.showCreateModal = false;
        })
      );
    }
  };

  const handleNext = () => {
    dispatch(
      updateConfig((state) => {
        state.activeTab = "Uploads";
      })
    );
  };
  return {
    handleCloseModal,
    handleNext,
  };
};

export default useDetails;
