import style from "./dashboardCard.module.scss";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

const DashboardCard = ({ title, data, icon, active, lineChartDataNew, activeTab }) => {
  return (
    <div className={`${style.root}  ${active ? style.active : ""} ${activeTab === "app_analytics" ? (title === "Average Engagement Time" ? style.no_hover : "") : style.no_hover}`}>
      {activeTab !== "app_analytics" ? (
      <div
        className={`${style.inner} ${style.card_v2}  pro-flex-column pro-w-100`}
        // onClick={() => data?.id && lineChartDataNew(1,data?.id)}
      >
        <div
          className={`${style.body} pro-d-flex pro-justify-center  pro-w-100  pro-flex-row`}
        >
          <div className="title-wrap pro-w-100">
            <h3 className="pro-ttl h3 pro-mb-2 pro-fw-medium">
              {title !== "Total Plays" ? data?.current : data?.total}
            </h3>
            <div className={`${style.growth_status} pro-d-flex pro-w-100`}>
              {title === "Registered Users" ? (
                <div className="pro-pe-1">
                  <OverlayTrigger
                    placement="auto-end"
                    // delay={{ show: 250, hide: 50 }}
                    overlay={
                      <Tooltip className="custom-tooltip">
                        <strong>New Registered Users:</strong>

                        <p>{data?.tool_tip?.new_reg_users}</p>
                      </Tooltip>
                    }
                  >
                    <h6 className="pro-ttl h6 pro-mb-0 pro-fw-medium">
                      New Registered Users
                    </h6>
                  </OverlayTrigger>
                </div>
              ) : title === "Active Users" ? (
                <div className="pro-pe-1">
                  <OverlayTrigger
                    placement="auto-end"
                    // delay={{ show: 250, hide: 50 }}
                    overlay={
                      <Tooltip className="custom-tooltip">
                        <strong>{title}:</strong>

                        <p>{data?.tool_tip?.active_users}</p>
                      </Tooltip>
                    }
                  >
                    <h6 className="pro-ttl h6 pro-mb-0 pro-fw-medium">
                      {title}
                    </h6>
                  </OverlayTrigger>
                </div>
              ) : title === "Total Plays" ? (
                <div className="pro-pe-1">
                  <OverlayTrigger
                    placement="auto-end"
                    // delay={{ show: 250, hide: 50 }}
                    overlay={
                      <Tooltip className="custom-tooltip">
                        <strong>{title}:</strong>

                        <p>{data?.tool_tip?.total_plays}</p>
                      </Tooltip>
                    }
                  >
                    <h6 className="pro-ttl h6 pro-mb-0 pro-fw-medium">
                      {title}
                    </h6>
                  </OverlayTrigger>
                </div>
              ) : <div className="pro-pe-1">
                  <OverlayTrigger
                    placement="auto-end"
                    // delay={{ show: 250, hide: 50 }}
                    overlay={
                      <Tooltip className="custom-tooltip">
                        <strong>{title}:</strong>

                        <p>{data?.tool_tip}</p>
                      </Tooltip>
                    }
                  >
                    <h6 className="pro-ttl h6 pro-mb-0 pro-fw-medium">
                      {title}
                    </h6>
                  </OverlayTrigger>
                </div>}
                    {
                      data?.tool_tip?.growth  &&(
                        <div className="pro-ps-1">
                        <OverlayTrigger
                          placement="auto-end"
                          // delay={{ show: 250, hide: 5000 }}
                          overlay={
                            <Tooltip className="custom-tooltip">
                              <strong>Growth:</strong>
                              <p>{data?.tool_tip?.growth}</p>
                            </Tooltip>
                          }
                        >
                          <span
                            className={`${
                              data?.growth.includes("-")
                                ? style.decrement
                                : style.increment
                            } ${
                              style.growth_percentage
                            } pro-align-self-center pro-font-xs pro-fw-medium pro-badge`}
                          >
                            <span
                              className={`material-symbols-outlined ${style.arrow}`}
                            >
                              arrow_outward
                            </span>
                            <span>{data?.growth}</span>
                          </span>
                        </OverlayTrigger>
                      </div>
                      )
                    }
            </div>
          </div>
          {/* <div className={`${style.icon}`}>
            <img src={icon} alt={title} />
          </div> */}
        </div>
        {title !== "Total Plays" && (
          <div
            className={`pro-d-flex pro-items-center pro-pt-4 pro-mt-auto pro-gap-3 pro-flex-wrap`}
          >
            {data?.hasOwnProperty('total') && (
              <div>
                Total - <strong>{data?.total !== 0 ? data?.total : 0}</strong>
              </div>
            )}
            {data?.hasOwnProperty('deleted') && (
              <div>
                Deleted Users - <strong>{data?.deleted !== 0 ? data?.deleted : 0}</strong>
              </div>
            )}
            {data?.hasOwnProperty('returning') && (
              <div>
                Returning Users - <strong>{data?.returning !== 0 ? data?.returning : 0}</strong>
              </div>
            )}
            {/* <p className="pro-mb-0 pro-fw-medium">{`Previous : ${data?.previous}`}</p> */}
          </div>
        )}
        {title === "Total Plays" && (
          <div
            className={`pro-d-flex  pro-items-center pro-pt-4 pro-mt-auto pro-gap-3`}
          >
            {/* <span
              className={`${
                data?.growth.includes("-") ? style.decrement : style.increment
              } ${
                style.growth_percentage
              } pro-align-self-center pro-font-xs pro-fw-medium pro-badge`}
            >
              <span className={`material-symbols-outlined ${style.arrow}`}>
                arrow_outward
              </span>
              <span>{data?.growth}</span>
            </span> */}
            {/* <div className="pro-d-flex pro-items-center pro-pt-4 pro-mt-auto pro-gap-3"> */}
            {data?.data?.map((item) => (
              <div>
                {item?.labels} - <strong>{item?.value}</strong>
              </div>
            ))}
            {/* </div> */}
            {/* <p className="pro-mb-0 pro-fw-medium">{`Previous : ${data?.previous}`}</p> */}
          </div>
        )}
      </div>
      ) :(
        <div
        className={`${style.inner} ${style.card_v2}  pro-flex-column pro-w-100`}
        onClick={() => data?.id && lineChartDataNew(1,data?.id)}
      >
        <div
          className={`${style.body} pro-d-flex pro-justify-center pro-items-center pro-w-100  pro-flex-row`}
        >
          <div className="title-wrap pro-w-100">
            {title === "Average Engagement Time" ? (
              <div className={`${style.growth_status} ${style.no_border} pro-d-flex pro-w-100`}>
              {data && (
                <div className="pro-pe-1">
                    <h6 className="pro-ttl h6 pro-mb-0 pro-fw-medium">
                    <strong>{data?.label}</strong>
                    </h6>
                  {/* <OverlayTrigger
                    placement="bottom"
                    // delay={{ show: 250, hide: 50 }}
                    overlay={
                      <Tooltip className="custom-tooltip">
                        <div>
                        <p>Podcast: {data?.tool_tip?.Podcast},</p>
                        <p>Radio: {data?.tool_tip?.Radio},</p>
                        <p>Shorts: {data?.tool_tip?.Shorts},</p>
                        <p>Video: {data?.tool_tip?.Video}</p>
                        </div>
                      </Tooltip>
                    }
                  >
                  </OverlayTrigger> */}
                    <h3 className="pro-ttl h3 pro-mb-2 pro-fw-medium">
                      {data?.value}
                    </h3>

                  <p>{data?.display_text}: {data?.previous}</p>
                </div>
              ) }

              <div className="pro-ps-1">
                  <span
                    className={`${
                      data?.growth.includes("-")
                        ? style.decrement
                        : style.increment
                    } ${
                      style.growth_percentage
                    } pro-align-self-center pro-font-xs pro-fw-medium pro-badge`}
                  >
                    <span
                      className={`material-symbols-outlined ${style.arrow}`}
                    >
                      arrow_outward
                    </span>
                    <span>{data?.growth}</span>
                  </span>
              </div>
            </div>
            ) : (
              <div className={`${style.growth_status} ${style.no_border} pro-d-flex pro-w-100`}>
              {data && (
                <div className="pro-pe-1">
                  <OverlayTrigger
                    placement="auto-end"
                    // delay={{ show: 250, hide: 50 }}
                    overlay={
                      <Tooltip className="custom-tooltip">
                        <strong>{data?.label}: </strong>
                        <p>{data?.tool_tip?.content}</p>
                      </Tooltip>
                    }
                  >
                    <h6 className="pro-ttl h6 pro-mb-0 pro-fw-medium">
                    <strong>{data?.label}</strong>
                    </h6>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="auto-end"
                    overlay={
                      <Tooltip className="custom-tooltip">
                        <p><strong>{data?.label}: </strong> {data?.original_value}</p>
                      </Tooltip>
                    }
                  >
                    <h3 className="pro-ttl h3 pro-mb-2 pro-fw-medium">
              {data?.value}
            </h3>
                  </OverlayTrigger>

                  <p>{data?.display_text}: {data?.previous}</p>
                </div>
              ) }

              <div className="pro-ps-1">
                <OverlayTrigger
                  placement="auto-end"
                  // delay={{ show: 250, hide: 5000 }}
                  overlay={
                    <Tooltip className="custom-tooltip">
                      <strong>Growth:</strong>
                      <p>{data?.tool_tip?.growth}</p>
                    </Tooltip>
                  }
                >
                  <span
                    className={`${
                      data?.growth.includes("-")
                        ? style.decrement
                        : style.increment
                    } ${
                      style.growth_percentage
                    } pro-align-self-center pro-font-xs pro-fw-medium pro-badge`}
                  >
                    <span
                      className={`material-symbols-outlined ${style.arrow}`}
                    >
                      arrow_outward
                    </span>
                    <span>{data?.growth}</span>
                  </span>
                </OverlayTrigger>
              </div>
            </div>
            )}
          </div>
          {/* <div className={`${style.icon}`}>
            <img src={icon} alt={title} />
          </div> */}
        </div>
        {/* {title && (
          <div
            className={`pro-d-flex pro-items-center pro-pt-4 pro-mt-auto pro-gap-3 pro-flex-wrap`}
          >
           
              <div>
                 <strong>{data?.label}</strong>
              </div>
            
            {data?.hasOwnProperty('deleted') && (
              <div>
                Deleted Users - <strong>{data?.deleted !== 0 ? data?.deleted : 0}</strong>
              </div>
            )}
            {data?.hasOwnProperty('returning') && (
              <div>
                Returning Users - <strong>{data?.returning !== 0 ? data?.returning : 0}</strong>
              </div>
            )}
           
          </div>
        )}
        {title === "Total Plays" && (
          <div
            className={`pro-d-flex  pro-items-center pro-pt-4 pro-mt-auto pro-gap-3`}
          >
            
           
            {data?.data?.map((item) => (
              <div>
                {item?.labels} - <strong>{item?.value}</strong>
              </div>
            ))}
           
          </div>
        )} */}
      </div>
      )}
    </div>
  );
};

export default DashboardCard;
