import React from "react";
import {
  ModalLayout,
} from "@wac-ui-dashboard/wac_component_library";
import Dropdown from "react-bootstrap/Dropdown";
import useHeaderActions from "./useHeaderActions";
import Style from './headerActions.module.scss';
import GlobalSearch from "../../../../components/Global/GlobalSearch";

const HeaderActions = () => {
  const {
    isModalOpen,
    globalData,
    searchInputRef,
    globalState,
    lastElement,
    handleInputChange,
    setIsModalOpen,
    closeModal,
    handleSearchData,
    handleLogout,
  } = useHeaderActions();

  return (
    <div className={`pro-ms-auto pro-d-flex pro-items-center ${Style.root}`}>
      <div className="input-wrap pro-me-3">
        <input
          type="text"
          placeholder="Search"
          className="pro-input icon-l lg"
          onClick={() => setIsModalOpen(true)}
          onChange={(e) => handleInputChange(e.target.value)}
        />
        <span className="pro-icon">
          <span className="material-symbols-outlined"> search </span>
        </span>
        <span className={`${Style.key_icon} pro-d-flex`}>
          <span className={`material-symbols-outlined`}>
            keyboard_command_key
          </span>
          <span>K</span>
        </span>
      </div>
     
      <Dropdown>
        <Dropdown.Toggle id="profile-dropdown">
          <div className="pro-avatar lg pro-flex-shrink-0">
              <span
                className={`material-symbols-outlined x5 ${Style.profile_icon}`}
              >
                {" "}
                account_circle{" "}
              </span>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={handleLogout}
            className="pro-d-flex pro-items-center"
          >
            <span className="material-symbols-outlined x3"> logout </span>
            <span className="pro-ms-2">Logout</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <ModalLayout handleClose={closeModal} show={isModalOpen}>
        <GlobalSearch
          handleSearchData={handleSearchData}
          lastElement={lastElement}
          searchKey={globalState.searchKey}
          globalData={globalData}
          ref={searchInputRef}
          // handleProfileRedirect={handleProfileRedirect}
        />
      </ModalLayout>
    </div>
  );
};

export default HeaderActions;
