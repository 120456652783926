import { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

import { getImage } from "../../../utils/functions/getCroppedImage";
// import { useUpdateProfileDetailsMutation } from "../../../store/queries/Profile";

const useProfileCard = ({
  data,
  fieldName,
  setShow,
  formik,
  setImageTitleImagePreview,
  setImageBannerPreview,
  setImageVerticalThumbnailPreview,
  setImageThumbnailPreview,
  setBasicThumbnailImageCoverPreview,
  setImageCoverPreview,
  setImagePreview,
  setImageDetailPreview,
  cropData,
  setFormikmage,
}) => {
  const [showCrop, setShowCrop] = useState(false);
  const [fileName, setfileName] = useState({});
  const [aspectRatio, setAspectRatio] = useState(true);

  const [crop, setCrop] = useState(cropData);

  const [image, setImage] = useState(null);

  const imgRef = useRef();

  const handleCropClick = () => {
    setShowCrop(true);
    setAspectRatio(true);
    setfileName({
      // name: data?.[fieldName]?.[0]?.name,
      // type: data?.[fieldName]?.[0]?.type,
      name: data?.[0]?.name,
      type: data?.[0]?.type,
    });
  };

  const handleCropCancel = () => {
    setShowCrop(false);
    if (image) {
      setImage(null);
    }
  };

  // useEffect(() => {
  //   if (!image) {
  //     setfileName({
  //       name: data?.[fieldName]?.[0]?.name,
  //       type: data?.[fieldName]?.[0]?.type,
  //     });
  //     if (data[fieldName][0]?.name) {
  //       setImage(URL.createObjectURL(data?.[fieldName]?.[0]));
  //     }
  //   }
  // }, [data?.[fieldName]?.[0]?.name]);

  useEffect(() => {
    if (!image) {
      setfileName({
        name: data?.[0]?.name,
        type: data?.[0]?.type,
      });
      if (data?.[0]?.name) {
        setImage(URL.createObjectURL(data?.[0]));
      }
    }
  }, [data?.[0]?.name]);

  const handleUpload = async () => {
    try {
      await getImage(imgRef.current, crop, fileName).then((res) => {
        if (res) {
          formik.setFieldValue(fieldName, res);
          setShow(false);
          const file = res;
          const reader = new FileReader();
          reader.onloadend = () => {
            if (fieldName === "title_image") {
              setImageTitleImagePreview(reader.result);
            } else if (fieldName === "banner_image") {
              setImageBannerPreview(reader.result);
            } else if (fieldName === "vertical_thumbnail") {
              setImageVerticalThumbnailPreview(reader.result);
            } else if (fieldName === "thumbnail") {
              setImageThumbnailPreview(reader.result);
            } else if (fieldName === "basic_thumbnail") {
              setBasicThumbnailImageCoverPreview(reader.result);
            } else if (fieldName === "list_thumbnail") {
              setImageCoverPreview(reader.result);
            } else if (fieldName === "image") {
              setImagePreview(reader.result);
            } else if (fieldName === "detail_image") {
              setImageDetailPreview(reader.result);
            }
          };

          if (file) {
            reader.readAsDataURL(file);
          }
        }
      });
    } catch (e) {
      toast.error("Failed to upload Image!");
    }
    // eslint-disable-next-line
  };

  const handleFreeCropClick = () => {
    setShowCrop(true);
    setAspectRatio(false);
  };

  return {
    showCrop,
    crop,
    image,
    imgRef,
    aspectRatio,
    handleFreeCropClick,
    setCrop,
    handleCropClick,
    handleCropCancel,
    handleUpload,
  };
};

export default useProfileCard;
