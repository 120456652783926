import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key] === "status") {
        return `${key}=${parseInt(params[key])}&`;
      } else if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return ""; 
      }
    };
    try {
      const response = await api[method](
        `${endpoint}?${getParams("_id")}${getParams("from_date")}${getParams(
          "to_date"
        )}${getParams("status")}${getParams(
          "sort_by"
        )}sort_order=${params?.sort_order || ""}&page_size=${
          params?.page_size || "10"
        }&${getParams("search")}&page=${params?.page || 1}&${getParams(
          "ad_campaign_id"
        )}&${getParams(
          "date_period"
        )}&${getParams(
          "type"
        )}`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        data: err.response?.data || err.message,
      };
    }
  };

export const dashboard = createApi({
  reducerPath: "dashboardApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["Dashboard","DashboardTable"],
  endpoints: (builder) => ({
    getDashboardListData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `admin/advertisement/analytics/report`,
      }),
      provideTags: ["Dashboard"],
    }),

    // getHomeDashboardListData: builder.query({
    //   query: (body) => ({
    //     method: "post",
    //     body,
    //     endpoint: `admin/dashboard/analytics`,
    //   }),
    //   provideTags: ["Dashboard"],
    // }),
    
    getDashboardTableListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/advertisement/analytics/placement-performance-report`,
      }),
      provideTags: ["DashboardTable"],
    }),
  }),
});

export const {
  useGetDashboardListDataQuery,
  useGetDashboardTableListDataQuery,
  // useGetHomeDashboardListDataQuery
} = dashboard;
