import React from "react";
import Style from "./globalSearch.module.scss";
import SearchCard from "./SearchCard";
import { useEffect } from "react";

const GlobalSearch = React.forwardRef((props, ref) => {
  useEffect(() => {
    ref.current.focus();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={`${Style.search_wrap}`}>
      <div className={Style.search_bar_wrap}>
        <div className={`${Style.search_bar}`}>
          <span
            className={`material-symbols-outlined x3 ${Style.search_icon} ${Style.icon}`}
          >
            search
          </span>
          <input
            ref={ref}
            className={`pro-input lg ${Style.input}`}
            value={props?.searchKey}
            onChange={(e) => props?.handleSearchData(e.target.value)}
          />
          <span className={`${Style.key_icon} ${Style.icon} pro-d-flex`}>
            <span className={`material-symbols-outlined`}>
              keyboard_command_key
            </span>
            <span>K</span>
          </span>
        </div>

        {props?.globalData?.data?.length >= 0 ? (
          <p className="pro-font-xs pro-mt-5 pro-mb-0">
            {props?.globalData?.data?.length ?? 0} results found
          </p>
        ) : props?.searchKey.length > 0 ? (
          <p className="pro-font-xs pro-mt-5 pro-mb-0"> 0 results found</p>
        ) : (
          <></>
        )}
      </div>

      {props?.globalData?.data?.map((data, index) => {
        return (
          <SearchCard
            data={data}
            handleProfileRedirect={props?.handleProfileRedirect}
            lastElement={
              index === props?.globalData?.data?.length-4
                ? props?.lastElement
                : false
            }
          />
        );
      })}
    </div>
  );
});

export default GlobalSearch;
