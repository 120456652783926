import React from "react";
import useRouteUtils from "../../utils/hooks/useRouteUtils";

const useSchedule = () => {
  const { checkIfActiveRoute } = useRouteUtils();

  const drawerMenu = [];

  return {
    drawerMenu,
  };
};

export default useSchedule;
