import { Auth } from "@wac-ui-dashboard/wac_component_library";
import { useOutletContext } from "react-router-dom";
import useForgotPassword from "./useForgotPassword";

const ForgotPassword = () => {
  const { emailRef, countryCodeRef, otpExpiryRef } = useOutletContext();

  const {
    otp,
    errors,
    otpStatus,
    handleSubmit,
    handleChange,
    handleBlur,
    handleResendOtp,
    otpCounter,
    formatTime,
  } = useForgotPassword(emailRef, countryCodeRef, otpExpiryRef);

  const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  const isEmail = emailPattern.test(emailRef.current);
  const phonePattern = /^[0-9]{10}$/;

  return (
    <Auth
      title={"Verify OTP"}
      buttonText={"Submit"}
      handleClick={handleSubmit}
      actionText={otpCounter === 0 ? "Resend OTP" : ""}
      handleActionClick={handleResendOtp}
      loading={otpStatus === "pending"}
    >
      <div className="input-wrap pro-mb-5">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          {isEmail
            ? "Enter OTP sent to your email address"
            : "Enter OTP sent to your Phone"}
        </label>
        <input
          value={otp}
          onChange={handleChange}
          onBlur={handleBlur}
          type="text"
          className={`pro-input lg ${errors && "error"}`}
        />
        <div className={`d-flex justify-content-end`}>
          {otpCounter > 0 ? <span> {formatTime(otpCounter)} </span> : ""}
        </div>
        {errors && <span className="error-text">{errors}</span>}
      </div>
    </Auth>
  );
};

export default ForgotPassword;
