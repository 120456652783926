import React, { useMemo } from "react";
import useRouteUtils from "../../utils/hooks/useRouteUtils";
import { useSelector } from "react-redux";

const useAdvertisement = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const globalState = useSelector((state) => state.global)

  const drawerMenu = [
    {
      title: "Ad Analytics",
      link: "/dashboard",
      icon: <span className="material-symbols-outlined x4"> group </span>,
      active: checkIfActiveRoute("/dashboard", true),
    },
    {
      title: "Client",
      link: "/dashboard/clients",
      icon: <span className="material-symbols-outlined x4"> group </span>,
      active: checkIfActiveRoute("/dashboard/clients", true),
    },
    {
      title: "Campaign",
      link: "/dashboard/campaign",
      icon: <span className="material-symbols-outlined x4"> group </span>,
      active: checkIfActiveRoute("/dashboard/campaign", true),
    },
    {
      title: "Ads",
      link: "/dashboard/advertisement",
      icon: <span className="material-symbols-outlined x4"> ad </span>,
      active: checkIfActiveRoute("/dashboard/advertisement", true),
    },
  ];

  const drawerMenuPermission = useMemo(() => {
    let menus = globalState.dashboard_permissions?.menu_permissions?.flatMap?.(
      (menu) => {
        let permission = menu.sub_menu_permissions?.map(
          (side_menu) => side_menu.sub_menu_name
        );
        return [
          // ...menu.sub_menu_permissions?.map((side_menu) => side_menu.sub_menu_name),
          ...(permission ? permission : []),
          menu.menu_name,
        ];
      }
    );
  
    return drawerMenu
      .filter((menu) =>  menus?.includes?.(menu?.title))
      .map((menu) => {
        return {
          ...menu,
        };
      });
    //eslint-disable-next-line
  }, [globalState.dashboard_permissions, location.pathname]);

  const combinedMenuPermissions = [
    ...drawerMenuPermission,
  ];

  return {
    drawerMenu: combinedMenuPermissions,
  };
};

export default useAdvertisement;