import { useMemo, useState } from "react";

const useBarChart = ({ dashboardData }) => {
  const [isSelectedHours, setIsSelectedHours] = useState(true);
  const [isSelectedDays, setIsSelectedDays] = useState(false);

  const mainData = useMemo(() => {
    return {
    labels: isSelectedHours
      ? dashboardData?.data?.mostActiveBarChart?.hours?.labels
      : isSelectedDays
      ? dashboardData?.data?.mostActiveBarChart?.days?.labels
      : null,
    datasets: [
      {
        label: "",
        data: isSelectedHours
        ? dashboardData?.data?.mostActiveBarChart?.hours?.data
        : isSelectedDays
        ? dashboardData?.data?.mostActiveBarChart?.days?.data
        : null,
        backgroundColor: "rgba(245, 158, 11, 1)",
        borderWidth: 0,
        barThickness: 10,
      },
    ],
    performanceOptions : {
      indexAxis: "x",
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          stacked: true,
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
        },
        y: {
          stacked: true,
          ticks: {
            beginAtZero: true,
          },
          grid: {
            color: "#D2D1D4",
          },
          border: {
            display: false,
            dash: [4, 4],
          },
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      width: 300,
      height: 300,
      aspectRatio: 1,
    }
  }}, [dashboardData?.data?.mostActiveBarChart]);
  // const performanceOptions = {
  //   indexAxis: "x",
  //   plugins: {
  //     legend: {
  //       display: false,
  //     },
  //   },
  //   scales: {
  //     x: {
  //       stacked: true,
  //       grid: {
  //         display: false,
  //       },
  //       border: {
  //         display: false,
  //       },
  //     },
  //     y: {
  //       stacked: true,
  //       ticks: {
  //         beginAtZero: true,
  //       },
  //       grid: {
  //         color: "#D2D1D4",
  //       },
  //       border: {
  //         display: false,
  //         dash: [4, 4],
  //       },
  //     },
  //   },
  //   responsive: true,
  //   maintainAspectRatio: false,
  //   width: 300,
  //   height: 300,
  //   aspectRatio: 1,
  // };

 

  return {
    mainData,
    // performanceOptions,
    isSelectedDays,
    isSelectedHours,
    setIsSelectedDays,
    setIsSelectedHours
  };
};

export default useBarChart;