import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { updateConfig } from "../../../store/slices/SubAdmin/subadminSlice";
import { toast } from "react-toastify";
import { emailRegExp, phoneRegExp } from "../../../utils/functions/table";
import { getDataById, createNew, subAdminDataList } from "./api";
import { useUpdateSubadminListDataMutation } from "../../../store/queries/subAdmin";
import { useSelector } from "react-redux";

const useUpdateSubAdmin = ({ activeId, refetch, isEditData }) => {
  const dispatch = useDispatch();
  const { currentFormData } = useSelector((state) => state.subadmin);
  const [updateSubadmin] = useUpdateSubadminListDataMutation();
  const nameRegExp = /^[^\s].*$/;

  const [errors, setErrors] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [subAdminList, setSubAdminList] = useState([]);

  useEffect(() => {
    if (activeId) {
      getDataById(activeId).then((response) => {
        formik.setErrors("");
        setErrors("");

        if (response?.data?.status_code === 200) {
          dispatch(
            updateConfig((state) => {
              state.currentFormData = response?.data?.data?.data;
            })
          );
        } else if (response?.error_code === 403) {
          formik.setErrors(response?.data?.errors);
        }
      });
    }
  }, []);

  let subadminVal = Yup.object({
    name: Yup.string()
      .required("Name is Required")
      .matches(nameRegExp, "Leading space is not allowed"),
    email: Yup.string()
      .required("Email is Required")
      .matches(emailRegExp, "Enter a valid Email"),
    password: Yup.string()
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{7,14}$/,
        "Password must contain at least one uppercase, one lowercase, one special character, and one number with a length between 7 and 14 characters"
      )
      .required("Password is required"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), ""], "Passwords must match")
      .required("Confirm password is required"),
    mobile: Yup.string()
      .matches(phoneRegExp, "Enter a valid phone number")
      .min(5, "Mobile number must be greater than 5 digits "),
    // country_code: Yup.string().required("Country Code is Required"),
    role_id: Yup.mixed().required("Role is Required"),
  });

  let subadminUpdateVal = Yup.object({
    name: Yup.string()
      .required("Name is Required")
      .matches(nameRegExp, "Leading space is not allowed"),
    email: Yup.string()
      .required("Email is Required")
      .matches(emailRegExp, "Enter a valid Email"),
    // country_code: Yup.string().required("Country Code is Required"),
    mobile: Yup.string()
      .matches(phoneRegExp, "Enter a valid phone number")
      .min(5, "Mobile number must be greater than 5 digits "),
    role_id: Yup.mixed().required("Role is Required"),
  });

  const formik = useFormik({
    initialValues: {
      _id: activeId,
      name: "",
      email: "",
      password: "",
      confirm_password: "",
      country_code: "",
      mobile: "",
      role_id: "",
    },
    validationSchema: isEditData ? subadminUpdateVal : subadminVal,
    enableReinitialize: true,
    // validate: (values) => {
    //   let errors = {};
    //   if (values.mobile === "" && (values?.role_id?._id !== "66210f8b57c09de39d06a6d2")) {
    //     errors.mobile = "Phone Number is required";
    //   }
    //   if (values.country_code === "" && (values?.role_id?._id !== "66210f8b57c09de39d06a6d2")) {
    //     errors.mobile = "Country Code is Required";
    //   }
      
    //   return errors;
    // },
    onSubmit: (values, { resetForm }) => {
      setIsLoading(true);
      try {
        let obj = {
          name: values.name,
          // password: values.password,
          // confirm_password: values.confirm_password,
          // country_code: values.country_code,
          // mobile: values.mobile,
          email: values.email,
          role_id: values.role_id?._id,
          status: +Boolean(values.status),
        };
        if (values.country_code !== "") {
          obj.country_code = values.country_code;
        }
        if (values.mobile !== "") {
          obj.mobile = values.mobile;
        }
        if (values.password !== "") {
          obj.password = values.password;
        }
        if (values.confirm_password !== "") {
          obj.confirm_password = values.confirm_password;
        }
        if (isEditData) {
          obj._id = values._id;
        }

        let formData = new FormData();

        Object.keys(obj).forEach((key) => {
          return formData.append(key, obj[key]);
        });
        if (isEditData === true) {
          formData.append("_method", "PUT");
          updateSubadmin(formData).then((response) => {
            formik.setErrors("");
            setErrors("");

            if (response?.data?.status_code === 200) {
              setIsLoading(false);
              refetch();
              resetForm();
              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                })
              );
              dispatch(
                updateConfig((state) => {
                  state.clearSelection = true;
                })
              );
              toast.success("Successfully udpated");
            } else if (response?.data?.error_code === 403) {
              setIsLoading(false);
              formik.setErrors(response?.data?.errors);
            } else if (response?.status_code === 500) {
              setIsLoading(false);
              toast.error("Something Went Wrong");
            } else {
              setIsLoading(false);
            }
          });
        } else {
          createNew(formData).then((response) => {
            formik.setErrors("");
            setErrors("");

            if (response?.data?.status_code === 200) {
              setIsLoading(false);
              refetch();
              resetForm();
              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                })
              );
              toast.success("Successfully Added");
            } else if (response?.error_code === 403) {
              setIsLoading(false);
              formik.setErrors(response?.errors);
            } else if (response?.status_code === 500) {
              setIsLoading(false);
              toast.error("Something Went Wrong");
            } else {
              setIsLoading(false);
            }
          });
        }
      } catch (error) {
        setErrors("Something went wrong");
      }
    },
  });

  const handleCloseModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };

  useEffect(() => {
    subAdminDataList().then((response) => {
      setSubAdminList(response?.data?.data?.roles);
    });
  }, []);

  useEffect(() => {
    if (isEditData && currentFormData) {
      Object.keys(currentFormData || {}).forEach((key) => {
        formik.setFieldValue(key, currentFormData?.[key]);
      });
    }
    // eslint-disable-next-line
  }, [currentFormData, isEditData]);

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  return {
    formik,
    errors,
    isLoading,
    currentFormData,
    getFieldError,
    handleCloseModal,
    subAdminList,
  };
};

export default useUpdateSubAdmin;
