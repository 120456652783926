import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getMenuPermissions,
  updateConfig as updateGlobalConfig,
} from "../../../store/slices/Global";
import {
  getAllPermissions,
  getPermission,
  updateConfig,
} from "../../../store/slices/Role/roleSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AddRole, updateRole } from "../api";

const useAddRole = () => {
  const [params] = useSearchParams();
  const role = params.get("role");
  const update = params.get("update");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.role);

  const [permissionModules, setPermissionModules] = useState({});
  const [singlePermissionModules, setSinglePermissionModules] = useState([]);

  useEffect(() => {
    if (state.allPermissionsStatus === "idle") {
      dispatch(getAllPermissions());
    }
    if (role) {
      dispatch(getPermission({ id: role }))
        .unwrap()
        .then((response) => {
          if (update === "true") {
            const menuPermissions = response?.data?.data?.sub_menu_permissions;
            const permissionModules = {};
            const singlePermissionModules = [];
            menuPermissions.forEach((permission) => {
              if (permission.sub_menu_permissions) {
                permissionModules[permission.menu_id] =
                  permission.sub_menu_permissions.map((subMenu) => {
                    return { sub_menu_id: subMenu.sub_menu_id };
                  });
              } else {
                singlePermissionModules.push(permission.menu_id);
              }
            });
            setPermissionModules(permissionModules);
            setSinglePermissionModules(singlePermissionModules);
            formik?.setFieldValue("name", response?.data?.data?.name);
          }
        });
    }
    return () => {};
    // eslint-disable-next-line
  }, [state?.allPermissions]);

  const validation = Yup.object({
    name: Yup.string().required("Enter Name").max(80, "Enter validName"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: validation,
    onSubmit: (values) => {
      // eslint-disable-next-line eqeqeq
      const commonModules = Object.keys(permissionModules).map((mId) => {
        return { menu_id: mId, sub_menu_permissions: permissionModules[mId] };
      });
      const filteredData = commonModules.filter(
        (obj) => obj?.sub_menu_permissions?.length > 0
      );
      const singleFinal = singlePermissionModules?.map((value) => {
        return {
          menu_id: value,
        };
      });

      if (!update) {
        let data = {
          name: values?.name,
          menu_permissions: [...filteredData, ...singleFinal],
        };
        AddRole(data)
          .then((response) => {
            if (response.status === 200) {
              navigate("/role");
              toast.success("Role created successfully", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            } else if (response?.status_code === 422) {
              if (response?.errors?.name) {
                formik?.setFieldError("name", "Name is Already taken");
              } else if (response?.errors?.departments) {
                formik?.setFieldError(
                  "departments",
                  "The departments field is required."
                );
              } else {
                toast.error("Select some Modules ");
              }
            } else {
              toast.error("Failed to Create Role");
            }
          })
          .catch(() => {
            toast.error("Failed to Create Role");
          });
      } else {
        let editData = {
          _id: role,
          name: values?.name,
          menu_permissions: [...filteredData, ...singleFinal],
        };
        updateRole(editData).then((response) => {
          if (response.status === 200) {
            dispatch(
              updateConfig((state) => {
                state.allPermissionsStatus = "idle";
                state.allPermissions = [];
              })
            );

            toast.success("Role updated successfully", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            dispatch(
              updateConfig((state) => {
                state.allPermissionsStatus = "idle";
                state.allPermissions = [];
                state.rolePermission = {};
                state.rolePermissionStatus = "idle";
              })
            );
            navigate("/role");
            dispatch(
              getMenuPermissions({
                token: localStorage.getItem("USER_ACCESS_TOKEN"),
              })
            );
          } else {
            toast.error("Failed to Update Role");
          }
        });
      }
    },
  });

  const handlePermissionChange = (item, moduleId) => {
    if (item.e.target.checked) {
      setPermissionModules((prev) => {
        return {
          ...prev,
          [moduleId]: prev?.[moduleId]
            ? [...prev[moduleId], { sub_menu_id: item.sub_menu_id }]
            : [{ sub_menu_id: item.sub_menu_id }],
        };
      });
    } else {
      setPermissionModules((prev) => {
        return {
          ...prev,
          [moduleId]: prev[moduleId]?.filter(
            (permissions) => permissions.sub_menu_id !== item.sub_menu_id
          ),
        };
      });
    }
  };
  const handleSingleMenuChange = (item, moduleId) => {
    if (item.e.target.checked) {
      setSinglePermissionModules([...singlePermissionModules, moduleId]);
    } else {
      setSinglePermissionModules(
        singlePermissionModules?.filter((itemId) => itemId !== moduleId)
      );
    }
  };

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  return {
    formik,
    state,
    role,
    update,
    getFieldError,
    handlePermissionChange,
    handleSingleMenuChange,
  };
};

export default useAddRole;
