import { Auth } from "@wac-ui-dashboard/wac_component_library";
import { useOutletContext } from "react-router-dom";
import { CountryFlag } from "../../../CountryFlag";
import Select from "react-select";
import useEmailOrPhone from "./useEmailOrPhone";

const EmailOrPhone = () => {
  const { emailRef,countryCodeRef, otpExpiryRef } = useOutletContext();
  const {
    code,
    inputvalue,
    errors,
    status,
    handleSubmit,
    handleChange,
    handleChangeOne,
    handleBlur,
    handleLoginClick,
    flag,
  } = useEmailOrPhone(emailRef,countryCodeRef,otpExpiryRef);

  return (
    <Auth
      title={"Forgot Password"}
      buttonText={"Submit"}
      handleClick={handleSubmit}
      actionText={"Back to login"}
      handleActionClick={handleLoginClick}
      loading={status === "pending"}
    >
      <div className="input-wrap pro-mb-5">
      <div className="input-country-wrap">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Enter Your Email/Phone
        </label>
        <div className={`pro-w-100 ${flag ? 'input-main-wrap' : ''}`}>
        {flag ? (
          // <select value={code} onChange={handleChangeOne}>
          //   <option>Select</option>
          //   {CountryFlag.map((value, index) => (
          //     <option key={index} value={value.dial_code}>
          //       {value.dial_code}
          //     </option>
          //   ))}
          // </select>
          <div className="code-wrap pro-mb-4 ">
            <Select
              id="country_code"
              options={CountryFlag}
              getOptionLabel={(option) => `${option.dial_code}`}
              getOptionValue={(option) => `${option.dial_code}`}
              className={`country-select`}
              // value={CountryFlag?.filter(
              //   (p) => p.dial_code?.toString() === code?.toString()
              // )}
              value={
                 code !== "" // Check if code is defined and not empty
                  ? CountryFlag?.filter((p) => p.dial_code?.toString() === code?.toString())
                  : CountryFlag[100] // Set the default country flag to the first element of CountryFlag array
              }
              classNamePrefix="pro-input"
              onChange={handleChangeOne}
            ></Select>
          </div>
        ) : (
          false
        )}
        <input
          value={inputvalue}
          onChange={handleChange}
          onBlur={handleBlur}
          type="text"
          className={`pro-input lg ${errors && "error"}`}
        />
        </div>
        {errors && <span className="error-text">{errors}</span>}
      </div>
      </div>
    </Auth>
  );
};

export default EmailOrPhone;
