import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 10,
  startDate: null,
  endDate: null,
  sortBy: "",
  sortOrder: "",
  search: "",
  showCreateModal: false,
  activeId: null,
  currentFormData: [null],
  primary_radio_file: "",
  completedTabs: {
    Details: false,
    Uploads: false, 
  },
  CompletedPercentage: {
    Details: 0,
    Uploads: 0,
  },
  isEditData: false, 
  status : "",
  start_date:"",
  end_date:"",
  clearSelection: false,
  selectedUpdateRows: [],
  currentFormData: [null]
};

const campaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    setStoreFormValues: (state, action) => {
      state.status= action.payload.status;
      state.start_date= action.payload.start_date;
      state.end_date= action.payload.end_date;
    },
    resetExtraFilters: (state, action) => {
      state.status= '';
      state.start_date= '';
      state.end_date= '';
    },
  },
});

export const { updateConfig, setStoreFormValues,resetExtraFilters } = campaignSlice.actions;

export default campaignSlice.reducer;
