import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useDeleteClientDataMutation,
  useGetClientListDataQuery,
  useStatusChangeMutation,
} from "../../../store/queries/client";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateConfig } from "../../../store/slices/Clients/clientSlice";
import { toast } from "react-toastify";
import { current } from "@reduxjs/toolkit";
import { client as clientQuery } from "../../../store/queries/client";

const useClientList = () => {
  const dispatch = useDispatch();
  const clientState = useSelector((state) => state.client);
  const { showCreateModal, isEditData, activeId, selectedUpdateRows } =
    useSelector((state) => state.client);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const [clientDelete] = useDeleteClientDataMutation();
  const [statusChange] = useStatusChangeMutation();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [filterShow, setFilterShow] = useState(false);

  let paginationOptions = [
    { label: "10 per page", value: 10 },
    { label: "20 per page", value: 20 },
    { label: "30 per page", value: 30 },
  ];

  const {
    data: client = {},
    isFetching,
    isLoading,
    isSuccess,
    refetch,
  } = useGetClientListDataQuery({
    length: clientState.currentPageSize,
    page: clientState.currentPage,
    search: clientState.search,
    sort_by: clientState.sortBy,
    sort_order: clientState.sortOrder,
    status: clientState.status,
  });

  // const { data: formData, isSuccess: isUpdateDataSuccess } =
  //   useGetClientListDataQuery({ skip: isEditData === false });

  const hasCreatePermission = useMemo(() => {
    let permission = client?.data?.original?.permissions?.create_permission;
    return permission ?? false;
  }, [client]);

  const hasEditPermission = useMemo(() => {
    let permission = client?.data?.original?.permissions?.edit_permission;
    return permission ?? false;
  }, [client]);
  const hasViewPermission = useMemo(() => {
    let permission = client?.data?.original?.permissions?.list_permission;
    return permission ?? false;
  }, [client]);
  const hasDeletePermission = useMemo(() => {
    let permission = client?.data?.original?.permissions?.delete_permission;
    return permission ?? false;
  }, [client]);

  const tableHeading = {
    name: { label: "Name", sortable: true },
    contact_person: { label: "Contact Person", sortable: true },
    email: { label: "Email", sortable: true },
    country_code: { label: "Country Code", sortable: true },
    mobile: { label: "Mobile", sortable: true },
    status_label: { label: "Status", sortable: true },
  };

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = tableHeading;
      })
    );
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   refetch();
  // }, [clientState.status]);

  const deleteClientDataClick = (item) => {
    setShowDeleteModal(true);
    dispatch(
      updateConfig((state) => {
        state.selectedUpdateRows = item;
      })
    );
  };

  useEffect(() => {
    if (clientState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [clientState.clearSelection]);

  const deleteClient = () => {
    clientDelete({
      _id: selectedUpdateRows,
    }).then((response) => {
      if (response?.data?.status_code === 200) {
        refetch();
        dispatch(
          updateConfig((state) => {
            state.clearSelection = true;
          })
        );
        setShowDeleteModal(false);
        toast.success("Successfully Deleted");
      } else if (response?.error?.status === 422) {
        toast.success("Something went wrong");
      }
    });
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  // useEffect(() => {
  //   if (isEditData && isUpdateDataSuccess) {
  //     dispatch(
  //       updateConfig((state) => {
  //         state.currentFormData = {
  //           ...formData?.data?.data,
  //         };
  //       })
  //     );
  //   }
  //   // eslint-disable-next-line
  // }, [isEditData, isUpdateDataSuccess, formData]);

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentFilter = null;
        state.currentPageSize = 10;
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
        state.showCreateModal = false;
      })
    );
  };

  const handleSort = (label) => {
    if (clientState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = clientState.sortOrder === "0" ? "1" : "0";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "0";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.activeId = ""
      })
    );
    if (isEditData) {
      dispatch(
        updateConfig((state) => {
          state.currentFormData = {};
          state.isEditData = false;
        })
      );
    }
  };
  const handleEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const handleClientDataEditClick = (id) => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.activeId = id[0];
      })
    );
    dispatch(
      updateConfig((state) => {
        state.currentFormData = client?.data?.original?.data?.id;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.isEditData = true;
        state.activeId = id?.[0];
      })
    );
  };

  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
    if (isEditData) {
      dispatch(
        updateConfig((state) => {
          state.currentFormData = {};
          state.isEditData = false;
        })
      );
    }
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };

  const handleToggle = (e, data) => {
    let params = {
      _id: data?._id,
    };
    statusChange(params);
    dispatch(
      clientQuery.util.updateQueryData(
        "getClientListData",
        {
          length: clientState.currentPageSize,
          page: clientState.currentPage,
          search: clientState.search,
          sort_by: clientState.sortBy,
          sort_order: clientState.sortOrder,
          status: clientState.status,
        },
        (cachedData) => {
          let updatedCachedData = { ...current(cachedData) };
          let newResult = updatedCachedData?.data?.original?.data?.map((res) =>
            res?._id === data?._id
              ? {
                  ...res,
                  status: data?.status === 0 ? 1 : 0,
                }
              : res
          );
          updatedCachedData = {
            ...updatedCachedData,
            data: {
              ...updatedCachedData.data,
              original: {
                ...updatedCachedData.data.original,
                data: newResult,
              },
            },
          };
          return updatedCachedData;
        }
      )
    );
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => (
        <p className="pro-mb-0">{data[field] ? `${data[field]}` : "-"}</p>
      ),
      status_label: (field, data) => {
        const isChecked = data?.status === 1;
        return (
          <>
            {hasEditPermission ? (
              <div className="form-check form-switch ">
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckDefault"
                  checked={isChecked}
                  onChange={(e) => handleToggle(e, data)}
                />
              </div>
            ) : (
              data?.status_label?.name
            )}
          </>
        );
      },
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  return {
    client,
    activeId,
    clientState,
    currentPage: clientState.currentPage,
    activeFilter: clientState.currentFilter,
    showCreateModal,
    showEditModal,
    paginationOptions,
    isFetching,
    isLoading,
    isSuccess,
    totalRecords: clientState.recordsTotal,
    showDeleteModal,
    // isUpdateDataSuccess,
    isEditData,
    filterShow,
    setFilterShow,
    handleClearClick,
    handleEditModal,
    handleCreateClick,
    deleteClientDataClick,
    closeDeleteModal,
    handlePagination,
    handlePageSize,
    handleSort,
    handleSearch,
    handleClientDataEditClick,
    closeEditModal,
    closeModal,
    getRow,
    deleteClient,
    refetch,
    tableFields,
    hasCreatePermission,
    hasEditPermission,
    hasViewPermission,
    hasDeletePermission,
  };
};

export default useClientList;
