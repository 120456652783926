import React from "react";
import ReactDOM from "react-dom/client";

import { configureStore } from "@reduxjs/toolkit";
import App from "./components/App";
import { Provider } from "react-redux";
import appReducer from "./store";
import { users } from "./store/queries/users";
import { rj } from "./store/queries/rj";
import { podcast } from "./store/queries/podcast";
import { mediavideo } from "./store/queries/mediavideo";
import { mediashorts } from "./store/queries/mediashorts";
import { medianews } from "./store/queries/medianews";
import { advertisement } from "./store/queries/advertisement";
import { programme } from "./store/queries/programme";
import { category } from "./store/queries/category";
import { newscategory } from "./store/queries/newscategory";
import { client } from "./store/queries/client";
import { campaign } from "./store/queries/campaign";
import { subadmin } from "./store/queries/subAdmin";
import { schedule } from "./store/queries/schedule"
import { role } from "./store/queries/role";
import { dashboard } from "./store/queries/dashboard";
import { privacy } from "./store/queries/privacy";
import { banner } from "./store/queries/banner";
import { homeDashboard } from "./store/queries/homeDashboard";
import { topPrograms } from "./store/queries/topPrograms";
import { topGenres } from "./store/queries/topGenres";

const store = configureStore({
  reducer: appReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      users.middleware,
      rj.middleware,
      podcast.middleware,
      mediavideo.middleware,
      mediashorts.middleware,
      medianews.middleware,
      advertisement.middleware,
      programme.middleware,
      category.middleware,
      newscategory.middleware,
      client.middleware,
      campaign.middleware,
      subadmin.middleware,
      schedule.middleware,
      role.middleware,
      dashboard.middleware,
      privacy.middleware,
      banner.middleware,
      homeDashboard.middleware,
      topPrograms.middleware,
      topGenres.middleware
    ),
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    {/* <React.StrictMode> */}
    <App />
    {/* </React.StrictMode> */}
  </Provider>
);
