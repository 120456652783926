import React, { useMemo } from "react";
import useRouteUtils from "../../utils/hooks/useRouteUtils";
import { useSelector } from "react-redux";

const useMediaVideo = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const globalState = useSelector((state) => state.global);

  const drawerMenu = [
    {
      title: "Podcast",
      link: "/media",
      icon: <span className="material-symbols-outlined x4"> podcasts </span>,
      active: checkIfActiveRoute("/media", true),
    },
    {
      title: "Video",
      link: "/media/video",
      icon: <span className="material-symbols-outlined x4"> videocam </span>,
      active: checkIfActiveRoute("/media/video", true),
    },
    {
      title: "Shorts",
      link: "/media/shorts",
      icon: <span className="material-symbols-outlined x4"> cinematic_blur </span>,
      active: checkIfActiveRoute("/media/shorts", true),
    },
    {
      title: "News",
      link: "/media/news",
      icon: <span className="material-symbols-outlined x4"> feed </span>,
      active: checkIfActiveRoute("/media/news", true),
    },
  ];

  const drawerMenuPermission = useMemo(() => {
    let menus = globalState.dashboard_permissions?.menu_permissions?.flatMap?.(
      (menu) => {
        let permission = menu.sub_menu_permissions?.map(
          (side_menu) => side_menu.sub_menu_name
        );
        return [
          ...(permission ? permission : []),
          menu.menu_name,
        ];
      }
    );
  
    return drawerMenu
      .filter((menu) =>  menus?.includes?.(menu?.title))
      .map((menu) => {
        return {
          ...menu,
        };
      });
    //eslint-disable-next-line
  }, [globalState.dashboard_permissions, location.pathname]);

  const combinedMenuPermissions = [
    ...drawerMenuPermission,
  ];

  return {
    drawerMenu: combinedMenuPermissions,
  };
};

export default useMediaVideo;
